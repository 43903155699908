define("adept-iq/pods/components/side-drawer/cancel-trip-form/component", ["exports", "adept-iq/pods/components/side-drawer/cancel-trip-form/config", "ember-concurrency", "ember-changeset", "ember-changeset-validations", "adept-iq/utils/unwrapProxy", "adept-iq/mixins/async-schedule-operation", "adept-iq/mixins/async-booking-operation", "adept-iq/pods/components/side-drawer/cancel-trip-form/validations", "lodash"], function (_exports, _config, _emberConcurrency, _emberChangeset, _emberChangesetValidations, _unwrapProxy, _asyncScheduleOperation, _asyncBookingOperation, _validations, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CANCELABLE_TRIP_STATUSES = ['Illegal', 'Waitlisted', 'Scheduled', 'Dispatched', 'Callback', 'En route'];

  var _default = Ember.Component.extend(_asyncScheduleOperation.default, _asyncBookingOperation.default, {
    editableSections: null,
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    session: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    ajax: Ember.inject.service(),
    routeexecevent: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    bookingService: Ember.inject.service('booking'),
    apiContext: Ember.inject.service(),
    errors: null,
    currentState: null,
    onShrinkClick: null,
    trip: Ember.computed.alias('stackFrame.options.trip'),
    tripId: Ember.computed.alias('stackFrame.options.tripId'),
    segment: Ember.computed.readOnly('stackFrame.options.segment'),
    booking: Ember.computed.readOnly('stackFrame.options.booking'),
    title: Ember.computed.readOnly('stackFrame.options.displayName'),
    lateCancel: Ember.computed.readOnly('stackFrame.options.lateCancel'),
    componentName: 'cancelTrip',
    isAllChecked: false,
    relatedTrips: null,

    setDefaultProperties() {
      let record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      const pendingCancellationRecord = record;

      if (pendingCancellationRecord.get('isNew')) {
        pendingCancellationRecord.setProperties({
          cancelType: null,
          notes: null
        });
      }

      return new _emberChangeset.default(pendingCancellationRecord, (0, _emberChangesetValidations.default)(_validations.default), _validations.default);
    },

    async init() {
      this._super(...arguments);

      const segment = this.get('segment');
      const sectionId = this.get('sectionId');

      const editableSections = _config.default.editableSections.filter(section => sectionId === section.id && section.id !== 'readOnly');

      segment.set('currentTrip', this.get('trip')); // regarding NYAAR-16095

      this.set('segment.tripId', this.get('tripId'));
      this.set('editableSections', editableSections);
      this.set('segmentChangeSet', this.setDefaultProperties(segment));
      await this.get('store').findRecord('rider', segment.get('leg.rider.id')); // regarding NYAAR-20171

      await this.setRelatedTrips();
    },

    didInsertElement() {
      this.loadCurrentState();
    },

    willDestroyElement() {
      this._super(...arguments);

      const currentState = this.get('currentState');

      if (!currentState) {
        const relatedTrips = _lodash.default.defaultTo(this.get('relatedTrips'), []);

        relatedTrips.setEach('isChecked', false);
      }
    },

    relatedTripsExist: Ember.computed('relatedTrips.@each.id', function () {
      const relatedTripsLength = this.get('relatedTrips.length');
      return relatedTripsLength > 0;
    }),

    loadCurrentState() {
      const currentState = this.get('currentState');

      if (currentState) {
        const cancelType = currentState.segment.get('cancelType'); //NYAAR-20226-if the cancel type is undefined we dont need to set that

        if (cancelType) {
          this.set('segmentChangeSet.cancelType', cancelType);
        }

        this.set('segmentChangeSet.cancelNotes', currentState.segment.get('cancelNotes'));
        this.set('errors', currentState.segment.get('errors'));
        this.set('isAllChecked', currentState.isAllChecked);
      }

      this.set('currentState', null);
    },

    saveCancelRecordFunction(records) {
      const notifications = this.get('notifications');
      const topActiveContext = this.get('activeContext.topActiveContext');
      const trip = this.get('trip');
      const routeId = trip.get('route.id');
      return new Promise((resolve, reject) => {
        if (!Ember.isEmpty(records)) {
          records.forEach(record => {
            return this.callCancelTripApi(record).then(async () => {
              record.set('isForceDirty', false); //refreshing booking and trips table

              if (this.get('workspace.isBookingDashboard')) {
                const [bookings, trips] = await this.get('apiContext').loadBookingsForSelectedExternalRiders();
                this.set('bookingService.currentBookings', bookings);
                this.set('bookingService.currentTrips', trips);
              } else {
                topActiveContext.get('refreshTableContent').perform(['route']); //NYAAR-19441:To check whether the trip has routeId(Trips with callback,illegal and waitlist status doesn't have routeId)

                if (routeId) {
                  await this.get('bookingService').routeTripQuery(routeId);
                }
              }

              notifications.success('Trip successfully canceled');
              resolve();
            }).catch(function (error) {
              notifications.warning('Failed to cancel Trip');
              reject(error);
            });
          });
        }
      });
    },

    callCancelTripApi(record) {
      return this.performTripCancellation.perform(record);
    },

    performTripCancellation: (0, _emberConcurrency.task)(function* (record) {
      const routeExecEvent = this.get('routeexecevent');
      const tripIds = this.getSelectedTripIds();
      const booking = record.get('leg.booking');
      const cancelData = routeExecEvent.cancelData();
      const operationData = routeExecEvent.prepareCancelDataForTrips(cancelData, record, this.get('lateCancel'), tripIds);

      try {
        const options = {
          operationData,
          outputResult: true
        };
        const {
          isJobSuccess,
          results
        } = yield this.createBookingAsyncOperation.perform(booking, 'bookingCancellation', options);

        if (!isJobSuccess) {
          throw new Error(results);
        }
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          throw e;
        }
      }

      return Promise.resolve();
    }).drop(),

    onSaveClick() {
      const saveCancelRecordFunction = this.get('saveCancelRecordFunction').bind(this);
      const tooltip = this.get('tooltip');
      const segment = (0, _unwrapProxy.unwrapProxy)(this.get('segmentChangeSet'));
      const tripIds = this.getSelectedTripIds();
      const onCloseClick = this.onCloseClick;

      if (this.validateRecords([segment])) {
        tooltip.pushConfirmation({
          tip: `Are you sure you want to cancel trip(s) ${tripIds.join(', ')}?`,
          title: 'Cancel Trip',
          hasOverlay: true,
          primaryActionText: 'Confirm',
          primaryAction: () => {
            return saveCancelRecordFunction([segment]).then(() => {
              onCloseClick();
            }).catch(e => {
              tooltip.reset();
              console.warn(e); //eslint-disable-line
            });
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => {
            tooltip.reset();
          }
        });
      }
    },

    validateRecords(records) {
      let valid = true;
      this.set('errors', []);
      records.forEach(record => {
        record.validate();

        if (record.get('isInvalid')) {
          valid = false;
          const errors = record.get('errors');
          this.set('errors', errors);
        }
      });
      return valid;
    },

    onUndoClick(records) {
      records.forEach(record => {
        record.rollback();
      });
      this.set('errors', null);
    },

    //set the related trips using selected trip's booking
    async setRelatedTrips() {
      const tripId = this.get('trip.id');
      const schedule = this.get('trip.schedule');
      const riderId = this.get('segment.leg.rider.id');
      const allTrips = await this.get('apiContext').getTripsForRider(riderId, CANCELABLE_TRIP_STATUSES, schedule);
      const relatedTrips = allTrips.filter(trip => trip.get('id') !== tripId);
      this.set('relatedTrips', relatedTrips);
    },

    //get the checked(selected) trip id's for setting the cancel trip payload
    getSelectedTripIds() {
      const tripId = this.get('trip.id');
      const relatedTrips = this.get('relatedTrips');
      const relatedTripIds = relatedTrips ? relatedTrips.filter(trip => trip.get('isChecked')).map(trip => trip.get('id')) : [];

      const selectedTripIds = _lodash.default.union([tripId], relatedTripIds);

      return selectedTripIds;
    },

    actions: {
      onCloseClick() {
        this.onCloseClick();
      },

      onUndoClick() {
        const segmentChangeSet = Ember.makeArray(this.get('segmentChangeSet'));
        this.onUndoClick(segmentChangeSet);
      },

      onValueChange(valuePath, options) {
        const segmentChangeSet = this.get('segmentChangeSet');
        const value = options;
        segmentChangeSet.set(valuePath, value);
      },

      onShrinkClick() {
        let currentState = this.get('currentState');
        const segment = (0, _unwrapProxy.unwrapProxy)(this.get('segmentChangeSet'));
        const isAllChecked = this.get('isAllChecked'); // save current manual settings

        currentState = {
          segment,
          isAllChecked: isAllChecked
        };
        this.set('currentState', currentState);
        this.onShrinkClick();
      },

      onSaveClick() {
        return this.onSaveClick();
      }

    }
  });

  _exports.default = _default;
});