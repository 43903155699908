define("adept-iq/serializers/booking", ["exports", "adept-iq/serializers/-bs-bookingService"], function (_exports, _bsBookingService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable no-unused-vars */
  var _default = _bsBookingService.default.extend({
    keyForAttribute: function (attr) {
      return attr;
    },
    attrs: {
      loggingMeta: {
        serialize: false
      }
    },

    serialize() {
      const json = this._super(...arguments);

      if (json.data && json.data.relationships) {
        const providerRelationship = json.data.relationships.provider;
        const subscription = json.data.relationships.subscription;

        if (!Ember.isNone(providerRelationship) && !Ember.isNone(providerRelationship.data)) {
          providerRelationship.data.type = 'providerName';
          json.data.relationships.providerName = json.data.relationships.provider;
          delete json.data.relationships.provider;
        }

        if (Ember.isPresent(subscription)) {
          delete json.data.relationships.subscription;
        }
      }

      return json;
    },

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (requestType === 'createRecord' && Ember.typeOf(payload.data) === 'array') {
        if (payload.data.length === 1) {
          payload.data = payload.data[0];
        }
      }

      return this._super(...arguments);
    },

    normalize(modelClass, resourceHash) {
      if (!Ember.isNone(resourceHash.attributes) && !Ember.isNone(resourceHash.attributes.providerName)) {
        const relationships = resourceHash.relationships ? resourceHash.relationships : {};
        relationships.provider = {
          data: {
            type: 'provider',
            id: resourceHash.attributes.providerName
          }
        };
        resourceHash.relationships = relationships;
        delete resourceHash.attributes.providerName;
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    },

    normalizeArrayResponse(store, primaryModelClass, payload) {
      if (!Ember.isNone(payload.included) && !Ember.isNone(payload.included.filterBy('type', 'segmentStop'))) {
        const segmentStops = payload.included.filterBy('type', 'segmentStop');

        for (let count = 0; count < segmentStops.length; count++) {
          const {
            place
          } = segmentStops[count].relationships;

          if (place) {
            store.push(place);
          }
        }
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});