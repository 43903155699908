define("adept-iq/pods/components/rider-management-widget/rider-contact-information/component", ["exports", "adept-iq/utils/unwrapProxy", "ember-concurrency", "lodash", "adept-iq/utils/rql-generators", "adept-iq/utils/validator"], function (_exports, _unwrapProxy, _emberConcurrency, _lodash, _rqlGenerators, _validator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NON_DELETABLE_PHONETYPES = ['main'];
  const NON_DELETABLE_ADDRESSTYPE = 'primary';

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    workspace: Ember.inject.service(),
    rider: Ember.inject.service(),
    isImageEditing: false,
    isPhoneNUmberEdit: false,
    isContactInfoEdit: false,
    canShowRiderPhoneNumberModal: false,
    isCreateNewPhoneNumber: true,
    canShowNewAddressModal: false,
    isCreatingNewAddress: false,
    canShowRiderContactFormModal: false,
    imageURL: null,
    riderProfileRecord: null,
    thumbnailData: null,
    tableRiderPhoneRef: null,
    tableRiderAddressRef: null,
    tableRiderContactRef: null,
    maxDateOfBirth: null,
    minDateOfBirth: null,
    riderPhoneNumbers: Ember.computed('riderChangeSet.{riderPhoneNumbers,riderPhoneNumbers.[]}', function () {
      return this.get('riderChangeSet.riderPhoneNumbers').toArray();
    }),
    riderPlaces: Ember.computed('riderChangeSet.{riderPlaces,riderPlaces.[]}', function () {
      return this.get('riderChangeSet.riderPlaces').toArray();
    }),
    riderContacts: Ember.computed('riderChangeSet.{riderContacts,riderContacts.[]}', function () {
      return this.get('riderChangeSet.riderContacts').toArray();
    }),
    isNewOrEditing: Ember.computed('editAPI.{isNewBtnDisabled,isEditBtnDisabled}', function () {
      return this.get('editAPI.isNewBtnDisabled') || this.get('editAPI.isEditBtnDisabled');
    }),

    async init() {
      this._super(...arguments);

      this.onRiderPhoneNumberSave = this.onRiderPhoneNumberSave.bind(this);
      this.createNewPhoneNumberRecord = this.createNewPhoneNumberRecord.bind(this);
      this.deletePhoneRecord = this.deletePhoneRecord.bind(this);
      this.editPhoneRecord = this.editPhoneRecord.bind(this);
      this.createNewRiderPlaceRecord = this.createNewRiderPlaceRecord.bind(this);
      this.createRiderContactsRecord = this.createRiderContactsRecord.bind(this);
      this.onSaveNewPlace = this.onSaveNewPlace.bind(this);
      this.onEditRiderPlace = this.onEditRiderPlace.bind(this);
      this.onDeleteRiderPlace = this.onDeleteRiderPlace.bind(this);
      this.onEditRiderContact = this.onEditRiderContact.bind(this);
      this.onDeleteRiderContact = this.onDeleteRiderContact.bind(this);
      this.savePlaceAddressAndLocation = this.savePlaceAddressAndLocation.bind(this);
      this.onRiderContactsSave = this.onRiderContactsSave.bind(this);
      this.onNewRiderRecord = this.onNewRiderRecord.bind(this);
      this.onEditRiderRecord = this.onEditRiderRecord.bind(this);
      this.onUndoRiderRecord = this.onUndoRiderRecord.bind(this);
      this.previewImage = this.previewImage.bind(this);
      await this.get('rider').getConfigRiderPhoneTypes(); // get phone types

      const providerType = await this.get('rider').getConfigProviderTypes();
      const riderAgeLimit = await this.get('rider').getConfigRiderAgeLimit();
      const riderAgeMinMaxValue = riderAgeLimit.map(ageLimit => ageLimit.get('value'));
      const providerTypeOption = providerType.map(provider => {
        try {
          const valueString = provider.get('valueString');
          const valueJson = JSON.parse(valueString);
          const isActive = valueJson.status === 'Active';

          if (isActive) {
            return provider.get('name');
          }
        } catch {
          return null;
        }

        return null;
      }).compact();
      this.setProperties({
        providerTypeOption,
        riderAgeMinMaxValue
      });
      const editAPI = this.get('editAPI'); // ember concurrency tasks

      const onSaveRiderRecord = this.get('onSaveRiderRecord');
      const onNewRiderRecord = this.get('onNewRiderRecord');
      const onEditRiderRecord = this.get('onEditRiderRecord');
      const onUndoRiderRecord = this.get('onUndoRiderRecord');
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveRiderRecord,
          newAction: onNewRiderRecord,
          deleteAction: null,
          editAction: onEditRiderRecord,
          undoAction: onUndoRiderRecord,
          isNewBtnDisabled: false,
          isEditBtnDisabled: false,
          isDeleteBtnDisabled: false,
          isEditing: false
        });
      });
      this.updateThumbnailImage(this.get('riderChangeSet.thumbnailImage'));
    },

    onRiderPhoneNumberSave() {
      const riderChangeSet = this.get('riderChangeSet');
      const riderPhoneNumberRecord = this.get('riderPhoneRecord');
      riderChangeSet.get('riderPhoneNumbers').pushObject(riderPhoneNumberRecord);
    },

    createNewPhoneNumberRecord() {
      this.set('canShowRiderPhoneNumberModal', true);
      const phoneNumberRecord = this.get('store').createRecord('rider-phone-number', {
        type: ''
      });
      this.set('riderPhoneRecord', phoneNumberRecord);
      this.set('isPhoneNumberEdit', false);
      this.set('isCreateNewPhoneNumber', true);
    },

    confirmPhoneNumberDeletion(tip, phoneRecord) {
      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        title: 'Rider Management',
        tip: tip,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          const notifications = this.get('notifications');
          notifications.success('Rider phone number successfully deleted');
          phoneRecord.set('isSoftDeleted', true);
          this.get('tableRiderPhoneRef').refreshData(); //to refresh table data

          return tooltip.reset();
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        }
      });
    },

    confirmRiderPlaceDeletion(tip, riderPlaceRecord) {
      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        title: 'Rider Management',
        tip: tip,
        primaryActionText: 'Confirm',
        primaryAction: async () => {
          const notifications = this.get('notifications');
          notifications.success('RIDER ADDRESS SUCCESSFULLY DELETED');
          riderPlaceRecord.set('isSoftDeleted', true);
          tooltip.reset();
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          tooltip.reset();
        }
      });
    },

    primaryAddressDeletionWarning(tip) {
      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        title: 'Rider Management',
        tip: tip,
        primaryActionText: 'Ok',
        primaryAction: () => {
          return tooltip.reset();
        }
      });
    },

    showMainPhoneDeleteWarning(tip) {
      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        title: 'Rider Management',
        tip: tip,
        primaryActionText: 'Ok',
        primaryAction: () => {
          return tooltip.reset();
        }
      });
    },

    editPhoneRecord(recordToEdit) {
      if (recordToEdit) {
        this.set('canShowRiderPhoneNumberModal', true);
        this.set('isPhoneNumberEdit', true);
        this.set('isCreateNewPhoneNumber', false);
        this.set('riderPhoneRecord', recordToEdit);
      }
    },

    deletePhoneRecord(recordToDelete) {
      const cannotDelete = NON_DELETABLE_PHONETYPES.includes(recordToDelete.get('type'));

      if (cannotDelete) {
        this.showMainPhoneDeleteWarning('Main Phone Number cannot be deleted.');
      } else {
        this.confirmPhoneNumberDeletion('Are you sure you want to delete this phone number?', recordToDelete);
      }
    },

    createNewRiderPlaceRecord() {
      const rider = this.get('riderChangeSet').get('_internalModel');
      const riderPlaceRecord = this.get('store').createRecord('rider-place');
      const placeRecord = this.get('store').createRecord('place');
      const addressRecord = this.get('store').createRecord('address');
      const locationRecord = this.get('store').createRecord('location');
      const placeCategoryType = this.get('store').peekRecord('place-category-type', 'passenger');
      const riderPlaceType = this.get('store').peekRecord('rider-place-type', 'primary');
      placeRecord.set('location', locationRecord);
      placeRecord.set('address', addressRecord);
      placeRecord.set('placeCategoryType', placeCategoryType);
      riderPlaceRecord.set('place', placeRecord);
      riderPlaceRecord.set('riderPlaceType', riderPlaceType);
      this.set('riderPlaceRecord', riderPlaceRecord);
      riderPlaceRecord.set('rider', rider);
    },

    async savePlaceAddressAndLocation() {
      const riderPlaceRecord = this.get('riderPlaceRecord');
      const placeRecord = riderPlaceRecord.get('place');
      const addressRecord = placeRecord.get('address');
      const locationRecord = placeRecord.get('location');
      this.get('workspace').set('isGlobalSpinnerVisible', true);
      await (0, _unwrapProxy.unwrapProxy)(addressRecord).save();
      await (0, _unwrapProxy.unwrapProxy)(locationRecord).save();
      await (0, _unwrapProxy.unwrapProxy)(placeRecord).save();
      this.get('workspace').set('isGlobalSpinnerVisible', false);
    },

    async updatePlaceAddressAndLocation() {
      const riderPlaceRecord = this.get('riderPlaceRecord');
      const placeRecord = (0, _unwrapProxy.unwrapProxy)(riderPlaceRecord.get('place'));
      const addressRecord = (0, _unwrapProxy.unwrapProxy)(placeRecord.get('address'));
      const locationRecord = (0, _unwrapProxy.unwrapProxy)(placeRecord.get('location'));
      this.get('workspace').set('isGlobalSpinnerVisible', true);
      await addressRecord.save();
      await locationRecord.save();
      await placeRecord.save();
      await riderPlaceRecord.save();
      this.get('workspace').set('isGlobalSpinnerVisible', false);
    },

    async onSaveNewPlace() {
      const riderChangeSet = this.get('riderChangeSet');
      const riderPlaceRecord = this.get('riderPlaceRecord');

      if (Ember.isBlank(riderPlaceRecord.id)) {
        await this.get('savePlaceAddressAndLocation')();
        riderChangeSet.get('riderPlaces').pushObject(riderPlaceRecord);
      } else {
        await this.get('updatePlaceAddressAndLocation').bind(this)();
      }
    },

    onEditRiderPlace(riderPlaceRecord) {
      if (riderPlaceRecord) {
        this.set('isCreatingNewAddress', false);
        this.set('canShowNewAddressModal', true);
        const rider = riderPlaceRecord.get('rider');
        const placeCategoryType = this.get('store').peekRecord('place-category-type', 'passenger');
        const placeJSON = (0, _unwrapProxy.unwrapProxy)(riderPlaceRecord.get('place')).toJSON();
        const addressJSON = (0, _unwrapProxy.unwrapProxy)(riderPlaceRecord.get('place.address')).toJSON();
        const locationJSON = (0, _unwrapProxy.unwrapProxy)(riderPlaceRecord.get('place.location')).toJSON();
        const placeRecord = this.get('store').createRecord('place', placeJSON);
        const addressRecord = this.get('store').createRecord('address', addressJSON);
        const locationRecord = this.get('store').createRecord('location', locationJSON);
        placeRecord.set('location', locationRecord);
        placeRecord.set('address', addressRecord);
        placeRecord.set('placeCategoryType', placeCategoryType);
        riderPlaceRecord.set('place', placeRecord);
        this.set('riderPlaceRecord', riderPlaceRecord);
        riderPlaceRecord.set('rider', rider);
      }
    },

    onDeleteRiderPlace(riderPlaceRecord) {
      if (riderPlaceRecord.riderPlaceTypeName === NON_DELETABLE_ADDRESSTYPE) {
        this.primaryAddressDeletionWarning('Primary Address cannot be deleted.');
      } else {
        const tip = 'Are you sure you want to delete this address?';
        this.confirmRiderPlaceDeletion(tip, riderPlaceRecord);
      }
    },

    saveRiderPhoneNumbers(riderRecord) {
      return new Promise((resolve, reject) => {
        const riderPhoneNumbers = this.get('riderChangeSet.riderPhoneNumbers');
        riderPhoneNumbers.forEach(rph => rph.set('rider', riderRecord));
        const riderPhoneNumberPromises = riderPhoneNumbers.map(riderPhoneNumber => {
          if (riderPhoneNumber.get('isSoftDeleted')) {
            return riderPhoneNumber.destroyRecord();
          }

          return riderPhoneNumber.save();
        });
        Promise.all(riderPhoneNumberPromises).then(() => {
          resolve();
        }).catch(e => reject(e));
      });
    },

    saveRiderPlaces(savedRider) {
      const ridePlaces = this.get('riderChangeSet.riderPlaces');
      ridePlaces.forEach(rph => rph.set('rider', savedRider));
      const riderPlacesPromises = ridePlaces.map(async rph => {
        if (rph.get('isSoftDeleted')) {
          return rph.destroyRecord();
        }

        const placeRecord = rph.get('place');
        const addressRecord = placeRecord.get('address');
        const locationRecord = placeRecord.get('location');
        await (0, _unwrapProxy.unwrapProxy)(addressRecord).save();
        await (0, _unwrapProxy.unwrapProxy)(locationRecord).save();
        await (0, _unwrapProxy.unwrapProxy)(placeRecord).save();
        return rph.save();
      });
      return Promise.all(riderPlacesPromises);
    },

    async saveRiderDefaultTravelNeed(savedRider) {
      const riderDefaultTravelNeedRecord = this.get('store').createRecord('rider-travel-need', {
        count: 1,
        passengerType: this.store.peekRecord('passenger-type', 'client')
      });
      const defaultTravelNeedName = 'ambulatory';
      const travelNeedTypeRecord = this.get('store').peekRecord('travel-need-type', defaultTravelNeedName);
      riderDefaultTravelNeedRecord.set('travelNeedType', travelNeedTypeRecord);
      riderDefaultTravelNeedRecord.set('loadTime', travelNeedTypeRecord.get('loadTime'));
      riderDefaultTravelNeedRecord.set('unloadTime', travelNeedTypeRecord.get('unloadTime'));
      riderDefaultTravelNeedRecord.set('rider', savedRider);
      return riderDefaultTravelNeedRecord.save();
    },

    onNewRiderRecord() {
      const riderChangeSet = this.get('riderChangeSet');
      this.get('editAPI').resetForm();
      riderChangeSet.set('status', 'temporary');
      this.set('editAPI.isNewBtnDisabled', true);
      this.set('editAPI.isEditing', true);
      this.updateThumbnailImage('');
    },

    onEditRiderRecord() {
      this.set('editAPI.isNewBtnDisabled', true);
      this.set('editAPI.isEditBtnDisabled', true);
      this.set('editAPI.isDeleteBtnDisabled', true);
      this.set('editAPI.isEditing', true);
      this.set('isImageEditing', true);
    },

    createRiderContactsRecord() {
      const riderContactRecord = this.get('store').createRecord('rider-contact-info');
      this.set('riderContactRecord', riderContactRecord);
      this.set('isContactInfoEdit', false);
    },

    onRiderContactsSave() {
      const riderChangeSet = this.get('riderChangeSet');
      const riderContactRecord = this.get('riderContactRecord');
      riderChangeSet.get('riderContacts').pushObject(riderContactRecord);
    },

    onEditRiderContact(riderContactRecord) {
      if (riderContactRecord) {
        this.set('canShowRiderContactFormModal', true);
        this.set('isContactInfoEdit', true);
        this.set('riderContactRecord', riderContactRecord);
      }
    },

    onDeleteRiderContact(riderContactRecord) {
      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        title: 'Rider Management',
        tip: 'Are you sure you want to delete this contact?',
        primaryActionText: 'Confirm',
        primaryAction: async () => {
          riderContactRecord.set('isSoftDeleted', true);
          tooltip.reset();
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          tooltip.reset();
        }
      });
    },

    saveRiderContacts(savedRider) {
      return new Promise((resolve, reject) => {
        const riderContacts = this.get('riderChangeSet.riderContacts');
        riderContacts.forEach(rph => rph.set('rider', savedRider));
        const riderContactsPromises = riderContacts.map(riderContact => {
          if (riderContact.get('isSoftDeleted')) {
            riderContact.destroyRecord();
          } else {
            riderContact.save();
          }
        });
        Promise.all(riderContactsPromises).then(() => {
          resolve();
        }).catch(e => reject(e));
      });
    },

    async isExitingRider() {
      let riders;
      const query = this.buildExistingQuery();
      const store = this.get('store');
      const existingRider = await store.query('rider', {
        filter: query
      }); // eslint-disable-next-line prefer-const

      riders = existingRider.toArray();

      if (riders.length) {
        return true;
      }

      return false;
    },

    async saveRiderAndNotify() {
      const notifications = this.get('notifications');
      const riderChangeSet = await this.get('riderChangeSet');
      const isNew = riderChangeSet.get('isNew');
      const tooltip = this.get('tooltip');

      try {
        if (riderChangeSet.get('isValid')) {
          const savedRider = await riderChangeSet.save();
          await this.saveRiderContacts(savedRider);
          await this.saveRiderPhoneNumbers(savedRider);
          await this.saveRiderPlaces(savedRider);

          if (isNew) {
            await this.saveRiderDefaultTravelNeed(savedRider);
          } // activity log for editing contact information screen


          const riderId = savedRider.get('id');
          const riderExternalId = savedRider.get('riderId');
          const activityLogs = [];

          if (this.get('editAPI.isEditing') && !isNew) {
            activityLogs.push({
              content: {
                userId: this.session.data.authenticated.userId,
                riderId: riderId,
                actualTime: moment().toDate(),
                activity: 'Edit Rider Record',
                eventType: 'UE',
                actionType: 'genericLogging',
                loggingType: 'user',
                details: `Edited information for rider ${riderExternalId}.`
              }
            });
          }

          if (isNew) {
            activityLogs.push({
              content: {
                userId: this.session.data.authenticated.userId,
                riderId: riderId,
                actualTime: moment().toDate(),
                activity: 'Edit Rider Record',
                eventType: 'UE',
                actionType: 'genericLogging',
                loggingType: 'user',
                ddetails: `Created rider record ${riderExternalId}.`
              }
            });
          }

          this.get('rider').createEditRiderActivityLog(activityLogs);
        }

        if (this.get('editAPI.isEditing')) {
          let notificationMsg = 'Rider contact information successfully edited.';

          if (isNew) {
            notificationMsg = 'Rider record successfully added';
            tooltip.pushConfirmation({
              title: 'Rider Management',
              tip: 'The rider currently has no eligibility, so no trips can be booked for this rider.',
              primaryActionText: 'Ok',
              primaryAction: () => {
                return tooltip.reset();
              }
            });
          } else {
            tooltip.reset();
          }

          notifications.success(notificationMsg);
          this.set('editAPI.isEditing', false);
          this.set('editAPI.isNewBtnDisabled', false);
          this.set('editAPI.isEditBtnDisabled', false);
          this.set('editAPI.isDeleteBtnDisabled', false);
        } else {
          notifications.success('Rider record successfully saved');
          this.set('editAPI.isNewBtnDisabled', false);
          this.set('editAPI.isEditBtnDisabled', false);
          this.set('editAPI.isDeleteBtnDisabled', false);
        }

        this.set('isImageEditing', false);
        const rider = await riderChangeSet.get('data');

        if (isNew) {
          this.get('setSelectedRider')(rider);
        }
      } catch (e) {
        notifications.warning('Rider record failed to save');
        tooltip.reset();
        throw e;
      }
    },

    showRiderSaveToolTip(message) {
      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        title: 'Rider Management',
        tip: message,
        primaryActionText: 'Confirm',
        primaryAction: this.saveRiderAndNotify.bind(this),
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          tooltip.reset();
        }
      });
    },

    onSaveRiderRecord: (0, _emberConcurrency.task)(function* () {
      const notifications = this.get('notifications');
      const riderChangeSet = this.get('riderChangeSet');
      const riderFirstName = riderChangeSet.get('firstName') || '';
      const riderLastName = riderChangeSet.get('lastName') || '';
      riderChangeSet.set('firstName', riderFirstName.trim());
      riderChangeSet.set('lastName', riderLastName.trim()); // validation

      const isValidRider = yield this.validateRider.perform();
      const isNewRecord = this.get('riderChangeSet.isNew');
      const isExitingRiderRecord = isNewRecord ? yield this.isExitingRider() : true;
      let saveTip = 'Are you sure you want to save these changes?'; // Only for new created contact info

      if (isExitingRiderRecord && isNewRecord) {
        saveTip = 'A record with the same name already exists. Are you sure you want to proceed with creating this record?';
      }

      if (isValidRider) {
        try {
          this.showRiderSaveToolTip(saveTip);
        } catch (e) {
          notifications.warning('Rider record failed to save');
          console.warn(e); // eslint-disable-line
        }
      }
    }),

    updateThumbnailImage(thumbnailImage) {
      if (!Ember.isNone(thumbnailImage)) {
        Ember.$('#thumbnailPreview').attr('src', thumbnailImage);
      }
    },

    async onUndoRiderRecord() {
      const riderId = this.get('rider').getLastAccessedRiderId();
      const riderChangeSet = this.get('riderChangeSet');
      const riderPhoneNumbers = riderChangeSet.get('riderPhoneNumbers').toArray();
      const riderPlaces = riderChangeSet.get('riderPlaces').toArray();
      const riderContacts = riderChangeSet.get('riderContacts').toArray();
      riderPhoneNumbers.forEach(async riderPhoneNumber => {
        if (Ember.isBlank(riderPhoneNumber.id)) {
          await riderPhoneNumber.destroyRecord();
        } else {
          riderPhoneNumber.rollbackAttributes();
        }
      });
      this.get('tableRiderPhoneRef').refreshData(); //to refresh table data

      riderPlaces.forEach(async riderPlace => {
        if (Ember.isBlank(riderPlace.id)) {
          await riderPlace.destroyRecord();
        } else {
          const placeRecord = riderPlace.get('place');
          const addressRecord = placeRecord.get('address');
          const locationRecord = placeRecord.get('location');
          riderPlace.rollbackAttributes();
          (0, _unwrapProxy.unwrapProxy)(addressRecord).rollbackAttributes();
          (0, _unwrapProxy.unwrapProxy)(locationRecord).rollbackAttributes();
          (0, _unwrapProxy.unwrapProxy)(placeRecord).rollbackAttributes();
        }
      });
      riderContacts.forEach(async riderContact => {
        if (Ember.isBlank(riderContact.id)) {
          await riderContact.destroyRecord();
        } else {
          riderContact.rollbackAttributes();
        }
      }); // after an api call failure, the whole form needs to be reset

      if (riderChangeSet.get('isNew')) {
        this.get('editAPI').resetForm();
        this.set('editAPI.isNewBtnDisabled', true);
        this.set('editAPI.isEditing', true);
      }

      riderChangeSet.rollback();

      if (!Ember.isNone(riderId)) {
        this.get('setLastSelectedRider')(riderId);
      }

      this.set('editAPI.isNewBtnDisabled', false);
      this.set('editAPI.isEditBtnDisabled', false);
      this.set('editAPI.isDeleteBtnDisabled', false);
      this.set('editAPI.isEditing', false);
      this.set('isImageEditing', false);
    },

    validateChangeSet() {
      const riderChangeSet = this.get('riderChangeSet');
      const errors = [];
      const mainPhoneNumber = riderChangeSet.get('mainPhoneNumber');
      const primaryAddresses = riderChangeSet.get('primaryAddresses');
      const mainEmail = riderChangeSet.get('email');

      if (Ember.isBlank(mainPhoneNumber)) {
        errors.push({
          key: 'mainPhoneNumber',
          validation: ['Main Phone Number is required']
        });
      }

      if (!primaryAddresses.length) {
        errors.push({
          key: 'primaryAddresses',
          validation: ['Primary Address is required']
        });
      }

      if (mainEmail && !(0, _validator.isEmailValid)(mainEmail)) {
        errors.push({
          key: 'email',
          validation: ['Email is invalid']
        });
      }

      return errors;
    },

    calculateAge(dob) {
      if (Ember.isBlank(dob)) return null;
      const ageDifMs = Date.now() - dob.getTime();
      const ageDate = new Date(ageDifMs); // miliseconds from epoch

      if (ageDifMs < 0) return 0;
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },

    validateRider: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const riderChangeSet = this.get('riderChangeSet');
      yield riderChangeSet.validate();
      const riderValid = riderChangeSet.errors;
      const validateChangeSetErrors = this.validateChangeSet();
      const errors = (0, _lodash.compact)([].concat(riderValid).concat(validateChangeSetErrors));

      if (errors.length > 0) {
        tooltip.pushConfirmation({
          title: 'Rider Management',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            tooltip.popConfirmation();
          }

        });
        return false;
      }

      return true;
    }),

    buildExistingQuery() {
      const riderChangeSet = this.get('riderChangeSet');
      const riderFirstName = riderChangeSet.get('firstName');
      const riderLastName = riderChangeSet.get('lastName');
      const searchTerms = [];

      if (!Ember.isBlank(riderFirstName)) {
        const rqlQuery = (0, _rqlGenerators.stringIeqRQL)('firstName', [riderFirstName]);
        searchTerms.push(rqlQuery);
      }

      if (!Ember.isBlank(riderLastName)) {
        const rqlQuery = (0, _rqlGenerators.stringIeqRQL)('lastName', [riderLastName]);
        searchTerms.push(rqlQuery);
      }

      const queryString = searchTerms.reduce((prev, current) => {
        return (0, _rqlGenerators.andRQL)(current, prev);
      });
      return queryString;
    },

    previewImage(input) {
      const file = input.target.files[0];
      const fr = new FileReader();
      const riderChangeSet = this.get('riderChangeSet');
      const riderId = riderChangeSet.get('id');

      fr.onload = readerEvent => {
        if (file.size > 100000) {
          const image = new Image();

          image.onload = imageEvent => {
            // Resize the image
            const canvas = document.createElement('canvas');
            const maxSize = 544;
            let width = image.width;
            let height = image.height;

            if (width > height) {
              if (width > maxSize) {
                height *= maxSize / width;
                width = maxSize;
              }
            } else if (height > maxSize) {
              width *= maxSize / height;
              height = maxSize;
            }

            canvas.width = width;
            canvas.height = height;
            canvas.getContext('2d').drawImage(image, 0, 0, width, height);
            const dataUrl = canvas.toDataURL('image/jpeg');
            Ember.$('#uploadPreview').attr('src', readerEvent.target.result);
            const imageData = dataUrl;
            const thumbnailData = {
              riderId: riderId,
              image: dataUrl
            };
            this.set('thumbnailData', thumbnailData);
          };

          image.src = readerEvent.target.result;
        } else {
          Ember.$('#uploadPreview').attr('src', readerEvent.target.result);
          const imageData = fr.result;
          const thumbnailData = {
            riderId: riderId,
            image: imageData
          };
          this.set('thumbnailData', thumbnailData);
        }
      };

      fr.readAsDataURL(file);
      this.set('canShowNewImageModal', true);
      this.set('isThumbnailEditing', false);
      input.target.value = '';
    },

    actions: {
      onDatePickrOpen(record, valuePath, value) {
        const riderAgeLimit = this.get('riderAgeMinMaxValue');
        const minimumAge = riderAgeLimit[0].minimumAge || '2';
        const maximumAge = riderAgeLimit[0].maximumAge || '150';
        const maxDateYearRollback = parseInt(minimumAge, 10);
        const minDateYearRollback = parseInt(maximumAge, 10);
        this.set('maxDateOfBirth', moment().subtract(maxDateYearRollback, 'years').format('MM-DD-YYYY'));
        this.set('minDateOfBirth', moment().subtract(minDateYearRollback, 'years').format('MM-DD-YYYY'));
      },

      onDateValueChange(record, valuePath, value) {
        record.set(valuePath, value);
      },

      onPowerSelectChange(valuePath, selected) {
        this.set(valuePath, selected);
      },

      onRiderImageUpload(event) {
        const isImageEditing = this.get('isImageEditing');

        if (isImageEditing) {
          this.get('previewImage')(event);
        }
      }

    }
  });

  _exports.default = _default;
});