define("adept-iq/pods/components/iq-widgets/reconcile-routes-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/reconcile-routes-widget/config", "lodash", "adept-iq/config/mapped-permIds", "moment", "adept-iq/utils/filters", "adept-iq/utils/sorts", "adept-iq/config/environment", "adept-iq/utils/unwrapProxy"], function (_exports, _component, _config, _lodash, _mappedPermIds, _moment, _filters, _sorts, _environment, _unwrapProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable camelcase */
  const INCLUDES = [];

  var _default = _component.default.extend({
    classNames: ['users-widget'],
    editModal: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    store: Ember.inject.service(),
    dwhService: Ember.inject.service(),
    editComponent: 'iq-widgets/users-form-widget',
    config: _config.default,
    defaultIncludes: INCLUDES,

    // @TODO: saveRecordFunction shall be moved to a service later on
    // This save is specific to saving drivers and all related models.
    // eslint-disable-next-line no-unused-vars
    saveRecordFunction(records) {},

    didInsertElement() {
      this._super(...arguments);

      this.set('tableActions', []);
      this.set('singleActions', [{
        id: 'activityLog',
        permId: _mappedPermIds.default.accessReconcileWorkspace,
        name: 'Activity Log',
        action: model => {
          const routeName = model.get('routeName');
          this.get('workspace').pushState('routeActivityLog', {
            displayName: `Route ${routeName} Activity Log`,
            route: (0, _unwrapProxy.unwrapProxy)(model)
          });
        },
        disableOnLocked: false,
        disableOnLockedRoute: false
      }]);
      this.set('bulkActions', []);
    },

    buildDataFilter() {
      const selectedDate = this.get('dwhService.reconcileDate');
      if (!selectedDate) return null;
      const date = (0, _moment.default)(selectedDate).format(_environment.default.dateTimeFormat.dwhDate); // Missing follow fields from API
      //   attributes.driverFirstName,
      //   attributes.driverLastName,
      //   attributes.driverPhoneNumber,
      //   attributes.odoStart,
      //   attributes.odoEnd

      const fields = `
      id,
      provider,
      attributes.routeStatus,
      attributes.routeName,
      attributes.tenantId,
      attributes.vehicleName,
      attributes.driverId,
      attributes.type,
      attributes.plannedStartTime,
      attributes.plannedEndTime,
      attributes.actualstarttime,
      attributes.actualendtime`.replace(/(\r\n|\n|\r|\s)/gm, '');
      const data = {
        data: {
          schedulename_str: JSON.stringify([date]),
          data_source_str: JSON.stringify(['DB']),
          usage_str: JSON.stringify(['165']),
          fields
        }
      };
      return data;
    },

    fetchDataQuery() {
      const params = {
        sorts: []
      };

      try {
        // this only applies for server-side pagination
        if (this.get('paginationEnabled')) {
          params.page = {
            offset: this.get('records.length'),
            limit: this.get('limit')
          };
        }

        const sort = this.buildSortParam();

        if (sort) {
          params.sorts.push(sort);
        }

        const includes = this.get('defaultIncludes');

        if (!Ember.isEmpty(includes)) {
          params.includes = includes;
        }

        const filters = this.setupFilterType();
        params.filter = (0, _filters.buildCompoundFilterNode)('and', filters);
        const modelName = this.get('config.modelName');
        const data = this.buildDataFilter();

        if (data) {
          params.data = data;
          return this.query(modelName, params);
        }

        return Promise.resolve([]);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Workspace view is not refreshed on selecting or deselecting' + error);
      }
    },

    async query(modelName) {
      let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const store = this.get('store');
      const compareFn = (0, _sorts.buildCompareFunction)(params.sorts);
      const filterFn = (0, _filters.buildFilterFunction)(params.filter);

      if (!this.get('activeContext.topActiveContext.unloadTask.isRunning')) {
        const responseData = (await store.query(modelName, params)) || [];
        const storeData = store.peekAll(modelName);

        _lodash.default.differenceBy(storeData.toArray(), responseData.toArray(), i => {
          return i.get('id');
        }).forEach(record => {
          record.transitionTo('deleted.saved');
          record.unloadRecord();
        });
      }

      const activeData = this.get('activeContext.topActiveContext').getActiveData();
      const filteredData = activeData[modelName].filter(filterFn).sort(compareFn);
      return filteredData;
    }

  });

  _exports.default = _default;
});