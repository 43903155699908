define("adept-iq/pods/components/iq-widgets/new-booking-form/component", ["exports", "moment", "adept-iq/config/mapped-permIds", "adept-iq/pods/components/generic-widgets/base-widget/component", "lodash", "adept-iq/utils/unwrapProxy", "adept-iq/classes/rules/eligibility-rules/eligibility-rule-builder", "adept-iq/classes/rules/eligibility-rules/eligibility-rule-context", "adept-iq/classes/rules/itinerary-type", "adept-iq/pods/components/iq-widgets/map-widget/config/polyline", "adept-iq/config/api-urls", "adept-iq/config/environment", "booleanPointInPolygon", "turfHelpers", "adept-iq/utils/geolib"], function (_exports, _moment, _mappedPermIds, _component, _lodash, _unwrapProxy, _eligibilityRuleBuilder, _eligibilityRuleContext, _itineraryType, _polyline, _apiUrls, _environment, _booleanPointInPolygon, _turfHelpers, _geolib) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-line
  const {
    bemap
  } = window;
  const PICK = 'pick';
  const PHONE_NUMBER_LENGTH = 10;
  const SCHEDULE_TYPE = 'primary';
  const IQ_BOOKING_MESSAGE = 'IQ Booking';
  const BLOCKOUT_REGION_GROUP_TYPE = 'blockout-region-group';
  const BLOCKOUT_ALTERNATIVE_ADDRESS_TYPE = 'alternative-address';
  let CUT_OFF_TIME_FOR_SAMEDAY = 0;
  let FUTURE_BOOKING_TIMELINE = 0;

  var _default = _component.default.extend({
    classNames: ['new-booking-form-side-drawer-parent'],
    editModal: Ember.inject.service(),
    geocode: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    apiContext: Ember.inject.service(),
    booking: Ember.inject.service(),
    session: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    systemConfig: Ember.inject.service(),
    fareCalc: Ember.inject.service(),
    ajax: Ember.inject.service(),
    isProgressShown: false,
    riderToBook: null,
    serviceNeeds: null,
    scheduleErrorMessage: 'Request time falls at a time where two schedules overlap. Please select which schedule the trip should be placed onto.',
    isLoadAndUnLoadTimeValid: true,
    isLoadAndUnLoadTimeNumber: false,
    // Checking the leg date eligibility
    selectedLegDate: null,
    isEligiblitiyCheck: false,
    showBookingSummary: false,
    onConditionCancelClick: null,
    onConditionConfirmClick: null,
    returnTripCollapseState: 'collapsed',
    preventReturnTripExpansion: false,
    travelNeedCollapseState: 'expanded',
    passengerCollapseState: 'collapsed',
    serviceNeedCollapseState: 'collapsed',
    // conditional Eligibility
    showEligibilityRestrictions: false,
    showOnlyEligibilityOkButton: true,
    riderIdConditionsMap: null,
    isOpenErrorModal: Ember.computed.alias('booking.isOpenErrorModal'),
    errorMessage: Ember.computed.alias('booking.errorMessage'),
    canSave: Ember.computed.alias('booking.canSave'),
    isEditMode: false,
    passengerInfo: null,
    legTravelNeeds: null,
    legServiceNeeds: null,
    dateFormat: 'MM/DD/YYYY',
    legsInBooking: Ember.computed.alias('activeLegsInBooking'),
    defaultFare: 0,
    companionFare: 0,
    purpose: null,
    temporaryBookingResults: null,
    pickAddressText: null,
    dropAddressText: null,
    selectedRiders: null,
    disablePhoneNumber: null,
    overrideEligibilityConditionsGranted: false,
    isOverrideEligible: false,
    riderFareCategories: null,
    isMaxRiderTimePopUp: false,
    blockoutRegionSetMessage: null,
    showADAEligibility: false,
    arrayADABoundaries: null,
    riderADAEligibility: null,
    passedADACheck: false,
    isStopOutisdeADABoundary: false,
    adaOverride: false,
    isComputingDistance: false,
    distancePt1: null,
    distancePt2: null,
    saveInProgress: false,
    dateTimeFormat: _environment.default.dateTimeFormat,
    // eslint-disable-next-line ember/use-brace-expansion
    riderFullNames: Ember.computed('booking.selectedRiders.[]', function () {
      return this.get('booking.selectedRiders').map(rider => rider.get('fullName')).join(',');
    }),
    noOfActiveRiders: Ember.computed('booking.selectedRiders.[]', function () {
      return this.get('booking.selectedRiders').length;
    }),
    allRiderAllowedServiceModes: Ember.computed('booking.selectedRiders.[]', function () {
      if (this.get('booking.selectedRiders').length === 0) {
        return [];
      }

      if (this.get('booking.selectedRiders').length === 1) {
        const riderRecord = this.get('booking.selectedRiders')[0];
        return riderRecord.get('eligibility.allowedServiceModes');
      }

      const commonServiceModes = this.get('booking.selectedRiders').reduce((result, rider) => {
        if (!result.length) {
          return rider.get('eligibility.allowedServiceModes');
        }

        return (0, _lodash.intersection)(result, rider.get('eligibility.allowedServiceModes'));
      }, []);
      return commonServiceModes;
    }),
    passengerListTitle: Ember.computed('noOfActiveRiders', function () {
      return `Passenger List (${this.get('noOfActiveRiders')})`;
    }),
    bookingPanelTitle: Ember.computed('isEditMode', function () {
      return this.get('isEditMode') ? `Edit Booking ${this.get('booking.activeBooking.id')}` : 'Create Booking';
    }),
    warningModelTitle: Ember.computed('isEditMode', 'isMaxRiderTimePopUp', function () {
      if (this.get('isMaxRiderTimePopUp')) return IQ_BOOKING_MESSAGE;
      return this.get('isEditMode') ? 'Edit Booking' : 'Create Booking';
    }),
    activeLegsInBooking: Ember.computed('booking.activeBooking.legs.[]', function () {
      return this.get('booking.activeBooking.legs');
    }),
    activeLegsInBookingForMapObserver: Ember.observer('booking.activeBooking.legs.[]', function () {
      this.refreshBlockoutRegionsPolygons();
      this.booking.setActiveLegsInBooking(this.get('activeLegsInBooking'));
    }),
    // eslint-disable-next-line ember/use-brace-expansion
    tripLegsInBooking: Ember.computed('booking.activeBooking.legs.[]', 'booking.activeBooking.legs', function () {
      const tripLegs = this.get('booking.activeBooking.legs').filterBy('purpose', 'trip');
      return tripLegs.sortBy('legOrdinal');
    }),
    // eslint-disable-next-line ember/use-brace-expansion
    returnTripLegsInBooking: Ember.computed('booking.activeBooking.legs.[]', 'booking.activeBooking.legs', function () {
      const returnTripLegs = this.get('booking.activeBooking.legs').filterBy('purpose', 'return');
      return returnTripLegs.sortBy('legOrdinal');
    }),
    addressesOfRiderToBook: Ember.computed('riderToBook', function () {
      return this.get('riderToBook.places').map(place => {
        if (Ember.typeOf(place) === 'object') {
          return this.store.createRecord('address', {
            freeformAddress: '',
            // we give this empty because nyct does give this field,full address in address model checks for this
            streetNumber: place.address.streetNumber,
            streetAddress: place.address.streetAddress,
            notes: place.address.notes,
            premise: place.address.premise,
            region: place.address.region,
            locality: place.address.locality,
            subLocality: place.address.locality,
            postalCode: place.address.postalCode,
            lat: place.address.lat,
            lng: place.address.lng,
            zName: place.address.zName
          });
        }

        return this.store.createRecord('address', {
          freeformAddress: place.get('address.freeformAddress') || '',
          streetNumber: place.get('address.streetNumber'),
          streetAddress: place.get('address.streetAddress'),
          notes: place.get('address.notes'),
          premise: place.get('address.premise'),
          region: place.get('address.region'),
          locality: place.get('address.locality'),
          subLocality: place.get('address.subLocality'),
          postalCode: place.get('address.postalCode'),
          lat: place.get('location.lat'),
          lng: place.get('location.lng'),
          zName: place.get('address.zName')
        });
      });
    }),

    init() {
      this._super(...arguments);

      this.configureAddTripPermission();
      const records = this.get('editableRecords');
      this.set('isEditMode', records[0]);
      this.activeLegsInBookingForMapObserver();
      this.set('booking.isEditingUnscheduledBooking', false);

      if (this.get('isEditMode')) {
        this.initEditMode();
      } else {
        this.initAddMode();
      }

      this.onAddNewTripLegClick = this.onAddNewTripLegClick.bind(this);
      this.onAddNewReturnLegClick = this.onAddNewReturnLegClick.bind(this);
      this.onLegReturnRemoveClick = this.onLegReturnRemoveClick.bind(this);
      this.onLegTripRemoveClick = this.onLegTripRemoveClick.bind(this);
      this.onReturnTripPanelHeaderClick = this.onReturnTripPanelHeaderClick.bind(this);
      this.syncPickAndDrop = this.syncPickAndDrop.bind(this);
      this.onFirstLegDateChange = this.onFirstLegDateChange.bind(this);
      this.updateFare = this.updateFare.bind(this);
      this.syncAllLegsTravelNeeds = this.syncAllLegsTravelNeeds.bind(this);
      this.clearPickAndDropAddressText = this.clearPickAndDropAddressText.bind(this);
      this.showSameDayNotificationByPermission = this.showSameDayNotificationByPermission.bind(this);
      this.validateBlockoutAddress = this.validateBlockoutAddress.bind(this);
      this.showAltAddressMarker = this.showAltAddressMarker.bind(this);
      this.cancelAlternativeAddressOption = this.cancelAlternativeAddressOption.bind(this);
      this.updateAlternativeAddressSelection = this.updateAlternativeAddressSelection.bind(this);
      const cutOffTime4SameDay = this.store.peekRecord('cs-config-item', 'config-Book_Trips/cutOffTime4SameDay');
      const futureBookingTimeline = this.store.peekRecord('cs-config-item', 'config-Book_Trips/Future_Booking_Timeline');
      const maximumCompanionConfig = this.store.peekRecord('cs-config-item', 'config-Book_Trips/maximum_companions');
      const maximumCompanionValue = maximumCompanionConfig ? parseInt(maximumCompanionConfig.get('value'), 10) : 1;
      this.set('maxCompanionCount', maximumCompanionValue); // converting minutes to hour
      // eslint-disable-next-line radix

      CUT_OFF_TIME_FOR_SAMEDAY = parseInt(cutOffTime4SameDay.get('value')) / 60; // eslint-disable-next-line radix

      FUTURE_BOOKING_TIMELINE = parseInt(futureBookingTimeline.get('value'));
      this.set('maxDate', FUTURE_BOOKING_TIMELINE ? (0, _moment.default)().endOf('day').add(FUTURE_BOOKING_TIMELINE, 'days').toDate() : (0, _moment.default)().endOf('day').toDate());
      this.combineServiceNeedsForGroupBooking();
      this.set('companionFare', this.get('booking').getSysConfigCompanionFare());
      this.setDefaultFare();
      this.set('riderFareCategories', []);
      this.setRiderFareCategories();
      this.set('legTravelModeMap', this.get('booking').getActiveBookingTravelModeMap());
      this.set('selectedRiders', this.get('booking.selectedRiders'));
      this.set('overrideEligibilityConditionsGranted', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.overrideEligibilityConditions));
      this.set('passedADACheck', false);
      this.set('isStopOutisdeADABoundary', false);
      this.set('adaOverride', false);
      this.set('booking.tripOverlap');
      const ridersFullNamesOrNull = this.get('booking.selectedRiders').map(externalRider => {
        const rider = this.get('store').peekRecord('rider', externalRider.id);

        if (rider.get('expiringNotRenewedEligibilityTypes.length') > 0) {
          return rider.fullName;
        }

        return null;
      });

      const ridersFullNames = _lodash.default.compact(ridersFullNamesOrNull);

      if (ridersFullNames.length > 0) {
        this.get('notifications').warning(`${ridersFullNames.join(', ')} ELIGIBILITY IS SET TO EXPIRE SOON.`);
      }
    },

    setDefaultBookingData() {
      const isEditMode = this.get('isEditMode');
      const store = this.get('store');
      const records = this.get('editableRecords');
      const companionMapper = {};
      const loadUnloadMapper = {};
      const travelNeedMapper = {};
      let formattedPhoneNumber;
      const selectedRidersLength = this.get('booking.noOfSelectedRiders');
      const riderTravelNeeds = selectedRidersLength > 1 ? this.get('booking').reGroupLegtravelneedsForGroupBooking() : this.get('booking.selectedRiders.firstObject.travelNeeds');
      const legTravelNeedsClass = this.get('booking').createLegTravelNeeds(riderTravelNeeds);
      const legTravelNeeds = isEditMode ? this.get('legTravelNeeds') : legTravelNeedsClass.toArray();
      const bookingRecord = (0, _lodash.clone)(records[2]);
      const loadTime = records[1].loadTime;
      const unLoadTime = records[1].unloadTime;
      this.set('riderTravelNeeds', riderTravelNeeds.map(riderTravelNeed => {
        const travelNeedType = store.peekAll('travel-need-type').find(tntype => {
          return tntype.get('id') === riderTravelNeed.type;
        });
        return travelNeedType;
      }));

      if (records[1].notificationPhoneNumbers.length > 1) {
        const areaCode = records[1].notificationPhoneNumbers[0].areaCode;
        const phoneNumber = records[1].notificationPhoneNumbers[0].phoneNumber;
        formattedPhoneNumber = `${areaCode}-${phoneNumber}`;
      }

      const rider = this.get('selectedRiders');
      const riderPhoneNumbers = rider.map(riderData => riderData.formattedMainPhoneNumber);
      const riderNotes = rider.map(riderData => riderData.notes);
      const legArray = bookingRecord.get('legs');
      legArray.forEach((leg, index) => {
        const companion = this.get('booking').getTravelNeedsCompanion(legTravelNeeds).map(legTravelNeed => {
          return {
            count: legTravelNeed.get('count'),
            travelNeedTypeNameUppercase: legTravelNeed.get('travelNeedTypeNameUppercase')
          };
        });
        const loadUnload = {
          loadTime: leg.get('loadTime'),
          unloadTime: leg.get('unloadTime')
        };
        const legTravelNeed = leg.get('legTravelNeeds').toArray();
        companionMapper[index] = companion;
        loadUnloadMapper[index] = loadUnload;
        travelNeedMapper[index] = legTravelNeed; // initialize computed property

        leg.get('companionTravelNeeds');
      });
      const record = Ember.Object.create({
        legs: [].concat(bookingRecord.get('legs').toArray()),
        booking: bookingRecord,
        legTravelNeeds: legTravelNeeds.toArray(),
        loadTime: loadTime,
        unLoadTime: unLoadTime,
        formattedPhoneNumber: formattedPhoneNumber,
        riderPhoneNumbers: riderPhoneNumbers,
        rider: rider,
        riderNotes: riderNotes
      });
      record.set('legSegments', this.createClonedLegSegments(record.get('legs')));
      record.set('travelNeeds', this.createClonedTravelNeeds(record.get('legTravelNeeds')));
      record.set('companionCountMapper', companionMapper);
      record.set('loadUnloadMapper', loadUnloadMapper);
      record.set('travelNeedMapper', travelNeedMapper);
      record.set('selectedAllLeg', this.get('booking.isAllLegs'));
      this.set('defaultBookingData', record);
      this.updateFare();
    },

    refreshBlockoutRegionsPolygons() {
      if (Ember.isNone(this.get('booking.bookingMap.native'))) return;
      const legsInBooking = this.get('legsInBooking');
      const startRequestTime = (0, _moment.default)(legsInBooking.get('firstObject.requestTime'));
      const endRequestTime = (0, _moment.default)(legsInBooking.get('lastObject.requestTime'));
      const blockoutRegionGroups = this.getBlockoutRegionGroups();
      this.clearDrawnItems(BLOCKOUT_REGION_GROUP_TYPE);
      blockoutRegionGroups.forEach(blockoutRegion => {
        const startTime = blockoutRegion.get('value.start') && (0, _moment.default)(blockoutRegion.get('value.start'));
        const endTime = blockoutRegion.get('value.end') && (0, _moment.default)(blockoutRegion.get('value.end'));
        const isStartRequestTimeInsideBlockoutTime = startRequestTime.isBetween(startTime, endTime, 'days', []);
        const isEndRequestTimeInsideBlockoutTime = endRequestTime.isBetween(startTime, endTime, 'days', []);

        if (isStartRequestTimeInsideBlockoutTime || isEndRequestTimeInsideBlockoutTime) {
          const regions = blockoutRegion.get('value.regions') || [];
          this.updateBlockoutRegionGroupPolygons(regions);
        }
      });
    },

    updateBlockoutAlternativeAddress(address) {
      const map = this.get('booking.bookingMap.native');
      const lat = address.lat;
      const lng = address.lng;
      const fullAddress = address.fullAddress;
      this.clearDrawnItems(BLOCKOUT_ALTERNATIVE_ADDRESS_TYPE);
      const marker = this.createMarker(lat, lng, fullAddress);
      marker.addTo(map);
    },

    updateBlockoutRegionGroupPolygons(regions) {
      const map = this.get('booking.bookingMap.native');
      regions.forEach(region => {
        const polygon = region.polygon;

        if (!Ember.isEmpty(polygon)) {
          const polygons = this.createPolygon([polygon], 'blue', region.name, BLOCKOUT_REGION_GROUP_TYPE);
          polygons.addTo(map);
        }
      });
    },

    clearDrawnItems(type) {
      const map = this.get('booking.bookingMap.native');

      if (type === BLOCKOUT_REGION_GROUP_TYPE) {
        map.eachLayer(layer => {
          const currLayerType = layer?.options?.properties?.type;

          if (currLayerType === BLOCKOUT_REGION_GROUP_TYPE) {
            map.removeLayer(layer);
          }
        });
      } else if (BLOCKOUT_ALTERNATIVE_ADDRESS_TYPE) {
        map.eachLayer(layer => {
          const currentType = layer?.options?.properties?.type;

          if (currentType === type) {
            map.removeLayer(layer);
          }
        });
      }
    },

    clonePlaceRecord(place) {
      const store = this.get('store');
      const address = (0, _unwrapProxy.unwrapProxy)(place.get('address'));
      const addressAttr = address.toJSON();
      addressAttr.zoneName = (0, _unwrapProxy.unwrapProxy)(place.get('address.zoneName'));
      const placeAttr = place.toJSON();
      const clonedAddress = store.createRecord('address', addressAttr);
      const clonePlace = store.createRecord('place', placeAttr);
      clonePlace.set('address', clonedAddress); // if clone the location it will update the location in map

      clonePlace.set('location', place.get('location'));
      return clonePlace;
    },

    createClonedLegSegments(legs) {
      const legSegments = legs.map(leg => {
        let pickPhoneNumberAttr = '';
        let dropPhoneNumberAttr = '';
        const pickPlace = this.clonePlaceRecord((0, _unwrapProxy.unwrapProxy)(leg.get('segments.firstObject.pick.place')));
        const dropPlace = this.clonePlaceRecord((0, _unwrapProxy.unwrapProxy)(leg.get('segments.lastObject.drop.place')));
        const fare = leg.get('segments.firstObject.fare');
        const pickNotes = leg.get('segments.firstObject.pick.notes');
        const dropNotes = leg.get('segments.lastObject.drop.notes');
        const pickPhoneNumbers = [...leg.get('segments.firstObject.pick.phoneNumbers').toArray()];
        const dropPhoneNumbers = [...leg.get('segments.firstObject.drop.phoneNumbers').toArray()]; //set the previous segment stop pick phone number attributes for future use(undo,etc)

        if (pickPhoneNumbers.length) {
          const previousPickPhoneNumberRecord = pickPhoneNumbers.get('firstObject');
          pickPhoneNumberAttr = previousPickPhoneNumberRecord.toJSON();
        } //set the previous segment stop drop phone number attributes for future use(undo,etc)


        if (dropPhoneNumbers.length) {
          const previousDropPhoneNumberRecord = dropPhoneNumbers.get('firstObject');
          dropPhoneNumberAttr = previousDropPhoneNumberRecord.toJSON();
        }

        const pickPlaceAddress = (0, _unwrapProxy.unwrapProxy)(pickPlace.get('address')).toJSON();
        const dropPlaceAddress = (0, _unwrapProxy.unwrapProxy)(dropPlace.get('address')).toJSON();
        const pickPlaceLocation = (0, _unwrapProxy.unwrapProxy)(pickPlace.get('location')).toJSON();
        const dropPlaceLocation = (0, _unwrapProxy.unwrapProxy)(dropPlace.get('location')).toJSON();
        const pickZoneName = leg.get('segments.firstObject.pick.place.address.zoneName.name');
        const dropZoneName = leg.get('segments.lastObject.drop.place.address.zoneName.name');
        return Ember.Object.create({
          pickPlace: pickPlace,
          dropPlace: dropPlace,
          pickPlaceAddress: pickPlaceAddress,
          dropPlaceAddress: dropPlaceAddress,
          pickPlaceLocation: pickPlaceLocation,
          dropPlaceLocation: dropPlaceLocation,
          fare: fare,
          pickNotes: pickNotes,
          dropNotes: dropNotes,
          pickPhoneNumbers: pickPhoneNumbers,
          dropPhoneNumbers: dropPhoneNumbers,
          serviceWindow: leg.get('serviceWindow'),
          fundingAgency: leg.get('fundingAgency'),
          noSharing: leg.get('segment.noSharing'),
          noSharingText: leg.get('segment.noSharingText'),
          loadTime: leg.get('loadTime'),
          unloadTime: leg.get('unloadTime'),
          anchor: leg.get('anchor'),
          requestTime: leg.get('requestTime'),
          pickPhoneNumberAttr: pickPhoneNumberAttr,
          dropPhoneNumberAttr: dropPhoneNumberAttr,
          pickZoneName: pickZoneName,
          dropZoneName: dropZoneName
        });
      });
      return legSegments;
    },

    createClonedTravelNeeds(legTravelNeed) {
      const legTravelNeeds = legTravelNeed.map(travelNeed => {
        return Ember.Object.create({
          count: travelNeed.get('count'),
          travelNeedType: travelNeed.get('travelNeedType'),
          passengerType: travelNeed.get('passengerType')
        });
      });
      return legTravelNeeds;
    },

    willDestroyElement() {
      this.set('booking.selectedCompanionCount', 0);
      this.set('booking.isOpenErrorModal', false);
      this.set('booking.travelNeedsOverride', false);
      this.set('booking.overrideEligibility', false);
      this.set('booking.overrideTravelNeedsDetails', []);
      this.set('booking.isRiderPhoneNumberEdited', false);
      this.set('booking.bookingMap', null);

      this._super(...arguments);
    },

    async willDestroy() {
      //set the copiedBookingRecord as null, if we close the copy booking screen
      if (this.booking.copiedBookingRecord) {
        this.booking.copiedBookingRecord = null;
      }

      this._super(...arguments);
    },

    initEditMode() {
      const record = this.get('editableRecords');
      const legTravelNeeds = this.duplicateWideSeats(record[2].legs.firstObject.legTravelNeeds);
      const hasReturnLeg = record[2].legs.toArray().find(ltn => ltn.get('purpose') === 'return');
      const legServiceNeeds = this.createAndGetLegServiceNeeds(record[2]);
      const status = this.get('booking.activeBooking.status') || '';
      const isEditingUnscheduledBooking = status.toLowerCase() === 'unscheduled';

      if (isEditingUnscheduledBooking) {
        this.set('booking.isEditingUnscheduledBooking', true);
        this.set('preventReturnTripExpansion', true);
      }

      if (hasReturnLeg) {
        this.set('returnTripCollapseState', 'expanded');
      }

      this.set('riderToBook', record[1]);
      this.set('legTravelNeeds', legTravelNeeds);
      this.set('legServiceNeeds', legServiceNeeds);
      const rider = this.store.peekRecord('rider', record[1].id);
      this.set('riderToBook.suspensions', rider.riderSuspensions.toArray());
      this.set('riderToBook.eligibilities', rider.riderEligibilities.toArray());
      this.setLegServiceNeedsInLeg(record[2]);
    },

    initAddMode() {
      const record = this.get('editableRecords');
      this.set('riderToBook', record[1]);
      this.set('legTravelNeeds', record[2].legs.firstObject.legTravelNeeds);
      this.set('legServiceNeeds', record[2].legs.firstObject.legServiceNeeds.toArray());
      const rider = this.store.peekRecord('rider', record[1].id); // these are manually added to riderToBook because of the differences between external rider payload and IQ rider payload

      this.set('riderToBook.suspensions', rider.riderSuspensions.toArray());
      this.set('riderToBook.eligibilities', rider.riderEligibilities.toArray());
      const legFareCategory = this.get('booking.activeBooking.firstLeg.segment.fareCategory');
      const fareCategoryToSet = _lodash.default.isNil(legFareCategory.get('id')) ? rider.get('fareCategory') : legFareCategory; // if fare category of the first leg is alredy set to valid value (ie.copy booking), use that value

      this.set('booking.activeBooking.firstLeg.segment.fareCategory', fareCategoryToSet);
      const providerOverride = Ember.isNone(rider.provider) ? null : rider.provider;
      this.set('riderToBook.provider', providerOverride);

      if (!Ember.isNone(rider.provider)) {
        const preferredProvider = this.store.peekRecord('provider', rider.provider);
        this.set('booking.activeBooking.provider', preferredProvider);
      } else {
        this.set('booking.activeBooking.provider', null);
      }
    },

    setCopiedBookingRecords() {
      const copiedBookingRecord = this.get('booking.copiedBookingRecord');
      const legs = copiedBookingRecord ? copiedBookingRecord.get('legs').toArray() : '';
      const totalLegsLength = legs.length - 1;

      if (copiedBookingRecord) {
        legs.forEach(async (leg, index) => {
          const legOrdinal = leg.get('legOrdinal');

          if (legOrdinal > 1) {
            const purpose = leg.get('purpose');
            const isReturn = purpose !== 'trip';
            const newLeg = await this.createLegSegmentRecord(isReturn);
            const copiedLeg = this.get('booking').setCopiedBookingLegs(leg, newLeg);
            const fare = leg.get('segments.firstObject.fare');

            if (totalLegsLength === index) {
              copiedLeg.set('segments.firstObject.fare', fare);
            }

            this.get('booking.activeBooking.legs').pushObject(copiedLeg);

            if (isReturn) {
              this.set('returnTripCollapseState', 'expanded'); //setting last return trip drop

              this.syncReturnDropWithTripPick();
            }

            this.syncPickAndDrop();
          }
        });
      }
    },

    duplicateWideSeats(legTravelNeeds) {
      const travelNeedTypes = this.store.peekAll('travel-need-type');
      const bigSeatsTN = travelNeedTypes.toArray().filter(tr => parseInt(tr.get('vehicleCapacityCount'), 10) === 2);
      const bigSeatTRs = bigSeatsTN.map(tr => tr.get('name').toUpperCase());
      const bigSeatTravelneeds = legTravelNeeds.filter(travelNeed => {
        return bigSeatTRs.includes(travelNeed.get('travelNeedTypeNameUppercase'));
      });
      bigSeatTravelneeds.forEach(tr => {
        const wideTravelNeed = bigSeatsTN.find(travelNeed => {
          return travelNeed.get('travelNeedTypeNameUppercase') === tr.get('name');
        });
        const configCount = parseInt(wideTravelNeed.get('vehicleCapacityCount'), 10);

        if (tr.count > configCount) {
          const count = tr.count / configCount;

          for (let i = 1; i < count; i++) {
            const newLegTravelNeed = this.store.createRecord('leg-travel-need', {
              count: configCount,
              travelNeedType: tr.get('travelNeedType'),
              passengerType: tr.get('passengerType')
            });
            legTravelNeeds.pushObject(newLegTravelNeed);
          }
        }
      });
      return legTravelNeeds;
    },

    onNoteChange(value) {
      this.set('riderToBook.notes', value);
    },

    checkIfFullAddressPresent(address) {
      return !Ember.isEmpty(address.get('fullAddress'));
    },

    /**
     * Method to set return trip dropoff address as first leg pic address
     */
    syncReturnDropWithTripPick() {
      const firstTripLeg = this.get('tripLegsInBooking.firstObject');
      const firstTripPickPlace = firstTripLeg.get('segments.firstObject.pick.place');
      const lastReturnLeg = this.get('returnTripLegsInBooking.lastObject');
      const lastLegDrop = lastReturnLeg.get('segments.lastObject.drop');
      const zoneType = this.store.createRecord('zone-type');
      const dropAddress = this.store.createRecord('address', {
        freeformAddress: firstTripPickPlace.get('address.freeformAddress') || '',
        streetNumber: firstTripPickPlace.get('address.streetNumber'),
        streetAddress: firstTripPickPlace.get('address.streetAddress'),
        notes: firstTripPickPlace.get('address.notes'),
        premise: firstTripPickPlace.get('address.premise'),
        region: firstTripPickPlace.get('address.region'),
        locality: firstTripPickPlace.get('address.locality'),
        subLocality: firstTripPickPlace.get('address.subLocality'),
        postalCode: firstTripPickPlace.get('address.postalCode'),
        zName: firstTripPickPlace.get('address.zName'),
        zoneName: zoneType
      });
      dropAddress.set('zoneName.name', firstTripPickPlace.get('address.zoneName.name'));
      dropAddress.set('zoneName.displayName', firstTripPickPlace.get('address.zoneName.displayName'));
      const dropLocation = this.store.createRecord('location', {
        lat: firstTripPickPlace.get('location.lat'),
        lng: firstTripPickPlace.get('location.lng'),
        geoNode: firstTripPickPlace.get('location.geoNode')
      });
      const dropPlace = this.store.createRecord('place', {
        address: dropAddress,
        location: dropLocation
      });
      lastLegDrop.set('place', dropPlace);
      const activelegsInBooking = this.get('activeLegsInBooking');
      this.booking.setActiveLegsInBooking(activelegsInBooking);
    },

    syncPickAndDrop() {
      const legsInBooking = this.get('tripLegsInBooking').toArray(); // last trip leg's drop should first return trip leg's pick

      if (this.get('returnTripLegsInBooking.length')) {
        const firstTripLeg = this.get('tripLegsInBooking.firstObject');
        const lastTripLeg = this.get('tripLegsInBooking.lastObject');
        const firstReturnLeg = this.get('returnTripLegsInBooking.firstObject');
        const prevDrop = lastTripLeg.get('segments.firstObject.drop.place');
        const currPick = firstReturnLeg.get('segments.firstObject.pick');
        const currDrop = firstReturnLeg.get('segments.firstObject.drop');
        const previousDrop = this.addressRecordCreation(prevDrop);
        currPick.set('notes', lastTripLeg.get('segments.firstObject.drop.notes'));
        currDrop.set('notes', firstTripLeg.get('segments.firstObject.pick.notes'));
        currPick.set('place', previousDrop);
      } else {
        this.syncLegsInBooking(legsInBooking);
      }

      const activelegsInBooking = this.get('activeLegsInBooking');
      this.booking.setActiveLegsInBooking(activelegsInBooking);
    },

    syncLegsInBooking(legsInBooking) {
      if (legsInBooking.length <= 1) {
        return;
      }

      for (let i = 1; i < legsInBooking.length; i++) {
        const previousLeg = legsInBooking[i - 1];
        const currentLeg = legsInBooking[i];
        const prevDrop = previousLeg.get('segments.firstObject.drop.place');
        const currPick = currentLeg.get('segments.firstObject.pick');
        const pickPlace = this.addressRecordCreation(prevDrop);
        currPick.set('notes', previousLeg.get('segments.firstObject.drop.notes'));
        currPick.set('place', pickPlace);
      }
    },

    /**
     * address record creation
     */
    addressRecordCreation(prevDrop) {
      const zoneType = this.store.createRecord('zone-type');
      const pickAddress = this.store.createRecord('address', {
        freeformAddress: prevDrop.get('address.freeformAddress') || '',
        streetNumber: prevDrop.get('address.streetNumber'),
        streetAddress: prevDrop.get('address.streetAddress'),
        notes: prevDrop.get('address.notes'),
        premise: prevDrop.get('address.premise'),
        region: prevDrop.get('address.region'),
        locality: prevDrop.get('address.locality'),
        subLocality: prevDrop.get('address.subLocality'),
        postalCode: prevDrop.get('address.postalCode'),
        zName: prevDrop.get('address.zName'),
        zoneName: zoneType
      });
      pickAddress.set('zoneName.name', prevDrop.get('address.zoneName.name'));
      pickAddress.set('zoneName.displayName', prevDrop.get('address.zoneName.displayName'));
      const pickLocation = this.store.createRecord('location', {
        lat: prevDrop.get('location.lat'),
        lng: prevDrop.get('location.lng'),
        geoNode: prevDrop.get('location.geoNode')
      });
      const pickPlace = this.store.createRecord('place', {
        address: pickAddress,
        location: pickLocation
      });
      return pickPlace;
    },

    didInsertElement() {
      this._super(...arguments);

      Ember.$('html,body,.tomtom-map').removeClass('custom-cursor');
      Ember.$('.edit-widget-modal').width('465');
      Ember.$('.booking-map-container').css({
        'width': 'calc(97% - ' + Ember.$('.edit-widget-modal').width() + 'px)'
      }).css({
        'height': 'calc(100% - 16%)',
        'left': Ember.$('.edit-widget-modal').width() + 'px)'
      });
      this.set('travelNeedCollapseState', 'collapsed');
      this.setCopiedBookingRecords();
      this.setDefaultBookingData();
      this.get('workspace').set('isGlobalSpinnerVisible', false);
      this.refreshBlockoutRegionsPolygons();
    },

    getNewLegDate(prevLeg) {
      const bufferMinutes = 10;
      const rTime = prevLeg.get('requestTime');
      const currentDateAndTime = (0, _moment.default)(rTime);
      const newMomentDate = (0, _moment.default)();
      const currentYear = currentDateAndTime.year();
      const currentMonth = currentDateAndTime.month();
      const currentDay = currentDateAndTime.date();
      const currentHour = currentDateAndTime.hour();
      const currentMinutes = currentDateAndTime.minutes();
      const currentSeconds = currentDateAndTime.seconds();
      newMomentDate.year(currentYear);
      newMomentDate.month(currentMonth);
      newMomentDate.date(currentDay);
      newMomentDate.hour(currentHour);
      newMomentDate.minutes(currentMinutes + bufferMinutes);
      newMomentDate.seconds(currentSeconds);
      const selectedDateObjectWithTime = newMomentDate.toDate();
      return selectedDateObjectWithTime;
    },

    configureAddTripPermission() {
      const record = this.get('editableRecords')[1];
      const sameDayTripPermGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.createSameDayBookings, null);
      const FutureDayTripPermGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.createFutureBookings, null);
      let maxDate = null;
      let minDate = null;

      if (sameDayTripPermGranted && !FutureDayTripPermGranted) {
        maxDate = (0, _moment.default)().endOf('day');
      }

      if (FutureDayTripPermGranted && !sameDayTripPermGranted) {
        minDate = (0, _moment.default)().startOf('day').add(1, 'day').toDate();
      }

      record.set('tripMaxDate', maxDate);
      record.set('tripMinDate', minDate);
    },

    async createLegSegmentRecord(isReturn) {
      const record = this.get('editableRecords');
      const store = this.get('store');
      const isGroupBooking = this.get('booking.noOfSelectedRiders') > 1;
      const serviceNeedType = this.get('tripLegsInBooking.firstObject.legServiceNeeds.firstObject.serviceNeedType');
      const rider = store.peekRecord('rider', record[1].id);
      let date;

      if (!isReturn || !this.get('returnTripLegsInBooking.lastObject.requestTime')) {
        const legToUse = this.get('tripLegsInBooking.lastObject');
        date = this.getNewLegDate(legToUse);
      } else {
        const legToUse = this.get('returnTripLegsInBooking.lastObject');
        date = this.getNewLegDate(legToUse);
      }

      const legs = isGroupBooking ? await this.get('booking').createLegSegmentRecordForGroupBooking(isReturn) : await this.get('booking').createLegSegmentRecordForSingleBooking(isReturn);
      const leg = legs.firstObject;
      const legFareCategory = this.get('booking.activeBooking.firstLeg.segment.fareCategory');
      const fareCategoryToSet = _lodash.default.isNil(legFareCategory.get('id')) ? rider.get('fareCategory') : legFareCategory; // if fare category of the first leg is alredy set to valid value (ie.copy booking), use that value

      leg.set('segment.fareCategory', fareCategoryToSet);
      leg.set('requestTime', date);
      const legServiceNeed = store.createRecord('leg-service-need', {
        leg: leg,
        serviceNeedType
      });
      leg.set('legServiceNeeds', [legServiceNeed]);
      return leg;
    },

    async onReturnTripPanelHeaderClick() {
      const bookingFieldType = this.getLegTypeField(true);

      if (this.get(`${bookingFieldType}.length`) === 0) {
        const newLeg = await this.createLegSegmentRecord(true);
        this.get('booking.activeBooking.legs').pushObject(newLeg);
        this.syncPickAndDrop(); //setting last return trip drop

        this.syncReturnDropWithTripPick();
      }
    },

    getLegTypeField(isReturn) {
      return isReturn ? 'returnTripLegsInBooking' : 'tripLegsInBooking';
    },

    async onAddNewTripLegClick() {
      await this._onAddNewLegClick(false);
    },

    async onAddNewReturnLegClick() {
      await this._onAddNewLegClick(true); //setting last return trip drop

      if (this.get('returnTripLegsInBooking.length')) {
        this.syncReturnDropWithTripPick();
      }
    },

    async _onAddNewLegClick(isReturn) {
      const newLeg = await this.createLegSegmentRecord(isReturn);
      this.get('booking.activeBooking.legs').pushObject(newLeg);
      this.syncPickAndDrop();
    },

    _onRemoveLeg(leg) {
      this.get('booking.activeBooking.legs').removeObject(leg);
      this.set('returnTripCollapseState', 'collapsed');
    },

    onLegReturnRemoveClick(leg) {
      this._onRemoveLeg(leg);
    },

    onLegTripRemoveClick(leg) {
      this._onRemoveLeg(leg);

      this.syncPickAndDrop();
    },

    // we are just checking using postalCode because both nyct and tomtom returns that consistently
    validateIfPickAndDropArePresentInAllLegs() {
      let allLegsHavePickAndDrop = true;
      this.get('legsInBooking').forEach(leg => {
        if (!leg.get('segments.firstObject.pick.place.address.postalCode') && !leg.get('segments.lastObject.drop.place.address.postalCode')) {
          allLegsHavePickAndDrop = false;
        }
      });
      return allLegsHavePickAndDrop;
    },

    validateIfPickPresentInAllLegs() {
      let allLegsHavePick = true;

      if (this.get('legsInBooking').length === 1) {
        return true;
      }

      this.get('legsInBooking').forEach((leg, index) => {
        if (!leg.get('segments.firstObject.pick.place.address.postalCode') && index > 0) {
          this.set('purpose', leg.get('purpose'));
          allLegsHavePick = false;
        }
      });
      return allLegsHavePick;
    },

    validateIfDropPresentInAllLegs() {
      let allLegsHaveDrop = true;

      if (this.get('legsInBooking').length === 1) {
        return true;
      }

      this.get('legsInBooking').forEach((leg, index) => {
        if (!leg.get('segments.lastObject.drop.place.address.postalCode') && index > 0) {
          this.set('purpose', leg.get('purpose'));
          allLegsHaveDrop = false;
        }
      });
      return allLegsHaveDrop;
    },

    validateCutOffInAllSubsequentLegs() {
      const legsInBooking = this.get('activeLegsInBooking');

      for (let i = 0; i < legsInBooking.length; i++) {
        const leg = this.get('legsInBooking').objectAt(i);

        if (!this.validateCutOff(leg)) {
          return true;
        }
      }
    },

    validateSeatAndPassengerCount() {
      const legIndexes = [];
      const isAllLegs = this.get('booking.isAllLegs');
      const travelNeedTypes = this.store.peekAll('travel-need-type');
      const bigSeatsTN = travelNeedTypes.toArray().filter(tr => parseInt(tr.get('vehicleCapacityCount'), 10) === 2);
      const bigSeatTRs = bigSeatsTN.map(tr => tr.get('name').toUpperCase());
      let activeLegsInBooking = this.get('activeLegsInBooking'); // Reg-NYAAR-18969 - if All Legs in Travel Need Section is checked , Validate Travel Need for first Object

      if (isAllLegs) {
        activeLegsInBooking = [activeLegsInBooking?.firstObject];
      }

      activeLegsInBooking.forEach((leg, index) => {
        const noOfRiders = this.get('booking').noOfSelectedRiders;
        const noOfPassengers = noOfRiders;
        const bigSeatTravelNeeds = leg.get('legTravelNeeds').filter(travelNeed => {
          return bigSeatTRs.includes(travelNeed.get('travelNeedTypeNameUppercase')) && !travelNeed.get('isCloned');
        });
        const wheelchairTravelNeed = leg.get('legTravelNeeds').find(travelNeed => {
          return travelNeed.get('travelNeedTypeNameUppercase') === 'WHEELCHAIR';
        });
        const wheelChairCount = wheelchairTravelNeed ? parseInt(wheelchairTravelNeed.count, 10) : 0;
        const ambulatoryCount = leg.get('ambulatorySeatCount') ? leg.get('ambulatorySeatCount') : 0;
        const noOfSeats = ambulatoryCount + wheelChairCount + bigSeatTravelNeeds.length;

        if (noOfPassengers < noOfSeats) {
          legIndexes.push(index);
        }
      });
      return legIndexes;
    },

    validateEmptyTravelNeeds() {
      const emptyTravelNeedsLegIndexes = [];
      this.get('activeLegsInBooking').forEach((leg, index) => {
        const isEmptyTravelNeedPresent = leg.get('legTravelNeeds').toArray().some(tr => !tr.get('travelNeedTypeNameUppercase'));

        if (isEmptyTravelNeedPresent) {
          emptyTravelNeedsLegIndexes.push(index);
        }
      });
      return emptyTravelNeedsLegIndexes;
    },

    checkEveryLegHasAnchor() {
      const legs = this.get('legsInBooking._objects');
      const filteredLegs = legs.filter(leg => {
        return leg.get('anchor');
      });

      if (filteredLegs.length === legs.length) {
        return true;
      }

      return false;
    },

    checkEveryLegHasRequestTime() {
      const legs = this.get('legsInBooking') ? this.get('legsInBooking').toArray() : [];
      const filteredLegs = legs.filter(leg => {
        return leg.get('requestTime') && !isNaN(leg.get('requestTime'));
      });

      if (filteredLegs.length === legs.length) {
        return true;
      }

      return false;
    },

    /**
     *  NYAAR-18964 The validation should be applied on the save tick icon and it shows the warning pop up less then 10 Digit phone number.
     *  it's for single booking and group booking
     * @returns {boolean}
     */
    validatePhoneNumber() {
      const validatePhoneNumber = this.get('booking.selectedRiders').every(rider => {
        return rider.get('formattedPhoneNumber').split('-').join('').length === PHONE_NUMBER_LENGTH;
      });
      return validatePhoneNumber;
    },

    /**
       * NYAAR-16659: Checking rider maximum time for the additional trip's request time must allow enough time for the additional trip to be completed before the end of the booking timeline.
       * @param {*Date} rTime
       * @param {*Object} currentLeg
       */
    async maxRiderTimeCheck(rTime, currentLeg) {
      const maxFutureBookingDate = this.get('maxDate');
      const formattedrTime = (0, _moment.default)(rTime).format('DD/MM/yyyy');
      const formattedMaxFutureBookingDate = (0, _moment.default)(maxFutureBookingDate).format('DD/MM/yyyy');
      const rider = this.get('booking.selectedRiders.firstObject');
      const riderModel = this.store.peekRecord('rider', rider.id);
      const session = this.get('session');
      const request = await this.get('booking').preparePayloadForDemandTrip(currentLeg, rider, riderModel);
      const byDistance = _environment.default.APP.byDistance || false;
      let maxRiderTimeResponse = [];
      let maxRideTime = null;
      let totalMinutes = null;
      let retMaxRideTime = null;

      if (formattedrTime === formattedMaxFutureBookingDate) {
        maxRiderTimeResponse = await this.get('ajax').post(_apiUrls.API.fixedRouteEngineService.host + `/scheduler/maximum-ride-time?method=formula&byDistance=${byDistance}`, {
          method: 'POST',
          contentType: 'application/json',
          headers: {
            'Authorization': `Bearer ${session.data.authenticated.token}`
          },
          data: [request]
        });
        maxRideTime = maxRiderTimeResponse[0].maximumRideTime;
        const max = maxRideTime.split(':');
        totalMinutes = Number(max[0] * 60) + Number(max[1]) + Number(max[2] / 60);
        retMaxRideTime = (0, _moment.default)(rTime).add(totalMinutes + 1, 'minutes');
      }

      return retMaxRideTime;
    },

    populateMaxRiderTimeCheck() {
      const currentLeg = this.get('leg');
      const requestTime = this.get('leg.requestTime');
      const pick = this.get('leg.segments.firstObject.pick.place.address.fullAddress');
      const drop = this.get('leg.segments.lastObject.drop.place.address.fullAddress');
      if (requestTime && !!pick && !!drop) this.maxRiderTimeCheck(requestTime, currentLeg);
    },

    async maxRiderTimeValidation() {
      const maxFutureBookingDate = this.get('maxDate');
      const lastLegIndex = this.get('legsInBooking.length');
      const legsInBooking = this.get('legsInBooking').toArray();
      let isRiderTimeNotExceed = true;
      if (lastLegIndex === 1) return true;

      for (const leg of legsInBooking) {
        const currentLegRequestTime = leg.requestTime;
        const maxRiderTimeRecord = await this.maxRiderTimeCheck(currentLegRequestTime, leg);

        if ((0, _moment.default)(maxRiderTimeRecord).isAfter(maxFutureBookingDate)) {
          isRiderTimeNotExceed = false;
          return false;
        }
      }

      return isRiderTimeNotExceed;
    },

    async validateRecords() {
      let checkOverlap = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      let valid = true;
      this.set('editModal.errors', []);
      const legsInBooking = this.get('legsInBooking');
      const firstLeg = this.get('legsInBooking.firstObject');
      const pickAddress = firstLeg.get('segments.firstObject.pick.place.address.postalCode');
      const dropAddress = firstLeg.get('segments.lastObject.drop.place.address.postalCode');
      const requestTimeAvailableLegs = legsInBooking.filter(leg => (0, _moment.default)(leg.get('requestTime')).isValid());
      const isValid = this.validatePhoneNumber();
      const isNotMaxRiderTime = await this.maxRiderTimeValidation();
      const selectedRider = this.get('selectedRiders.firstObject');
      const requestTimes = legsInBooking.map(leg => leg.get('requestTime'));
      const tripOverlap = checkOverlap ? await this.get('booking').overlapCheck(selectedRider.get('id'), requestTimes, []) : null;

      if (!this.get('isLoadAndUnLoadTimeValid')) {
        this.get('booking').showErrorMessage('Load/Unload time value must be 15 min or less.');
        valid = false;
      }

      if (!this.get('isLoadAndUnLoadTimeNumber')) {
        this.get('booking').showErrorMessage('Please enter valid numbers for load/unload time.');
        valid = false;
      }

      if (requestTimeAvailableLegs.length !== legsInBooking.length) {
        this.get('booking').showErrorMessage('Request time is missing. Please enter a valid time.');
        valid = false;
      }

      if (Ember.isEmpty(pickAddress) || Ember.isNone(pickAddress)) {
        this.get('booking').showErrorMessage('Unknown Address. Please enter a valid Pickup Leg address.');
        valid = false;
      }

      if (Ember.isEmpty(dropAddress) || Ember.isNone(dropAddress)) {
        this.get('booking').showErrorMessage('Unknown Address. Please enter a valid DropOff Leg address.');
        valid = false;
      }

      if (!this.validateIfPickPresentInAllLegs()) {
        const message = this.get('purpose') === 'trip' ? 'leg' : 'return';
        this.get('booking').showErrorMessage(`Unknown Address. Please enter a valid Pickup ${message} address.`);
        valid = false;
      }

      if (!this.validateIfDropPresentInAllLegs()) {
        const message = this.get('purpose') === 'trip' ? 'leg' : 'return';
        this.get('booking').showErrorMessage(`Unknown Address. Please enter a valid DropOff ${message} address.`);
        valid = false;
      }

      if (!this.validateLegRequestTimeAfterPrevLegRequestTime()) {
        this.get('booking').showErrorMessage("The additional trip's request time must come after the previous trip's request time.");
        valid = false;
      }

      if (this.validateCutOffInAllSubsequentLegs()) {
        this.get('booking').showErrorMessage(`Same day booking must be at least ${CUT_OFF_TIME_FOR_SAMEDAY * 60} mins in advance. Please select a different request time.`);
        valid = false;
      }

      if (!this.validateEligibility(firstLeg)) {
        this.set('selectedLegDate', (0, _moment.default)(firstLeg.get('requestTime')).format('M/D/Y'));
        this.get('booking').showErrorMessage(`${this.get('riderToBook.firstName')} ${this.get('riderToBook.lastName')}
      has no client eligibility records for the date \n  ${this.get('selectedLegDate')} . Please select another date.`);
        valid = false;
      }

      if (!this.checkEveryLegHasAnchor()) {
        this.get('booking').showErrorMessage('Please enter request type.');
        valid = false;
      }

      if (!this.checkEveryLegHasRequestTime()) {
        this.get('booking').showErrorMessage('Please enter date and time.');
        valid = false;
      }

      const emptyTravelNeedsLegIndexes = this.validateEmptyTravelNeeds();

      if (emptyTravelNeedsLegIndexes.length) {
        emptyTravelNeedsLegIndexes.forEach(index => {
          this.get('booking').showErrorMessage(`Please remove empty Travel needs in Leg Trip ${index + 1} to proceed.`);
        });
        valid = false;
      }

      if (!this.validateFutureBookingTimeline()) {
        this.get('booking').showErrorMessage('Trip date must be within the allowed booking timeframe.');
        valid = false;
      }

      if (!isValid) {
        this.get('booking').showErrorMessage('Please enter 10 digit in the Phone number field.');
        valid = false;
      }

      if (!isNotMaxRiderTime) {
        this.set('isMaxRiderTimePopUp', true);
        this.get('booking').showErrorMessage('Unable to book the additional leg/return trips. There is not enough time between the request time and the end of the booking timeline to complete the trip.');
        valid = false;
      }

      if (this.validatePickAndDropPhoneNumber()) {
        this.get('booking').showErrorMessage('Please enter 10 digit in the Phone number field.');
        valid = false;
      }

      if (Ember.isPresent(tripOverlap) && tripOverlap.overlaps.find(overlap => overlap.isOverlapping)) {
        valid = await this.get('booking').showOverlapWarningMessage(tripOverlap);
      }

      return valid;
    },

    // when the date of the first leg changes . all the date should change
    onFirstLegDateChange() {
      const rTime = this.get('legsInBooking.firstObject.requestTime');
      const firstLegScheduleName = this.get('legsInBooking.firstObject.scheduleName');
      const firstLegDateAndTime = (0, _moment.default)(rTime);
      const firstLegYear = firstLegDateAndTime.year();
      const firstLegMonth = firstLegDateAndTime.month();
      const firstLegDay = firstLegDateAndTime.date();

      for (let i = 1; i < this.get('legsInBooking').length; i++) {
        const leg = this.get('legsInBooking').objectAt(i);
        const legDateAndTime = (0, _moment.default)(leg.get('requestTime'));
        legDateAndTime.year(firstLegYear);
        legDateAndTime.month(firstLegMonth);
        legDateAndTime.date(firstLegDay);
        const legDateObjectWithTime = legDateAndTime.toDate();
        leg.set('requestTime', legDateObjectWithTime);

        if (Ember.isPresent(firstLegScheduleName)) {
          leg.set('scheduleName', firstLegScheduleName);
        }
      }
    },

    validateFutureBookingTimeline() {
      const legsInBooking = this.get('activeLegsInBooking');
      const currentDate = new Date();
      const maxDate = new Date();
      maxDate.setHours(24, 0, 0);
      const newDate = (0, _moment.default)(currentDate);
      const futureMaxDate = (0, _moment.default)(maxDate);

      for (let i = 0; i < legsInBooking.length; i++) {
        const previousLeg = legsInBooking.objectAt(i);
        const previousLegRequestTime = (0, _moment.default)(previousLeg.get('requestTime'));
        const futureBookingTimeline = futureMaxDate.clone().add(FUTURE_BOOKING_TIMELINE, 'days');
        const nextLeg = legsInBooking.objectAt(i + 1); //if the date field is empty it returns invalid date when we take moment value

        if (previousLegRequestTime.isValid() && !previousLegRequestTime.isBetween(newDate, futureBookingTimeline)) {
          return false;
        }

        if (nextLeg) {
          const nextLegRequestTime = (0, _moment.default)(nextLeg.get('requestTime')); //if the date field is empty it returns invalid date when we take moment value

          if (nextLegRequestTime.isValid() && !nextLegRequestTime.isBetween(newDate, futureBookingTimeline)) {
            return false;
          }
        }
      }

      return true;
    },

    validateEligibility(leg) {
      const requestTimeOfLeg = leg.get('requestTime');
      const momentedRequestTime = (0, _moment.default)(requestTimeOfLeg);
      const suspensions = this.get('riderToBook.suspensions');
      const eligibilities = this.get('riderToBook.eligibilities');
      let isSuspended = false;

      for (const suspension of suspensions) {
        isSuspended = momentedRequestTime.isBetween(suspension.startDate, suspension.endDate, 'day', '[]');

        if (isSuspended) {
          return false;
        }
      } // Checking the rider eligibility


      let hasValidEligibility = false;

      for (const eligibility of eligibilities) {
        const eligible = momentedRequestTime.isBetween(eligibility.from, eligibility.to, 'day', '[]');

        if (eligible) {
          hasValidEligibility = true;
        }
      }

      return hasValidEligibility;
    },

    validateCutOff(leg) {
      const requestTimeOfLeg = leg.get('requestTime');
      const momentedRequestTime = (0, _moment.default)(requestTimeOfLeg);
      const anchor = leg.get('anchor') ? leg.get('anchor').toLowerCase() : '';

      if (momentedRequestTime.isSame((0, _moment.default)(), 'day') && anchor === PICK) {
        const today = new Date();
        const todayWithOneHourExtended = new Date(today);
        todayWithOneHourExtended.setHours(todayWithOneHourExtended.getHours() + CUT_OFF_TIME_FOR_SAMEDAY);

        if (momentedRequestTime.isBefore(todayWithOneHourExtended) && CUT_OFF_TIME_FOR_SAMEDAY !== 0) {
          return false;
        }
      }

      return true;
    },

    validateLegRequestTimeAfterPrevLegRequestTime() {
      const legsInBooking = [...this.get('tripLegsInBooking').toArray(), ...this.get('returnTripLegsInBooking').toArray()];

      if (legsInBooking.length <= 1) {
        return true;
      }

      for (let i = 1; i < legsInBooking.length; i++) {
        const prevLeg = legsInBooking[i - 1];
        const prevlegRequestime = (0, _moment.default)(prevLeg.get('requestTime')).seconds(0).milliseconds(0);
        const curLeg = legsInBooking[i];
        const curLegRequestTime = (0, _moment.default)(curLeg.get('requestTime')).seconds(0).milliseconds(0); //Compares each leg requestTime with previous leg requestTime

        if (curLegRequestTime.isSameOrBefore(prevlegRequestime)) {
          return false;
        }
      }

      return true;
    },

    //segment stop phone number validation
    validatePickAndDropPhoneNumber() {
      const legsInBooking = [...this.get('tripLegsInBooking').toArray(), ...this.get('returnTripLegsInBooking').toArray()];
      const filteredLegs = legsInBooking.filter(leg => {
        const pickPhoneNumber = leg.get('segments.firstObject.pick.phoneNumbers.firstObject.fullPhoneNumber');
        const dropPhoneNumber = leg.get('segments.firstObject.drop.phoneNumbers.firstObject.fullPhoneNumber');
        const isValid = this.phoneNumberValidation(pickPhoneNumber) || this.phoneNumberValidation(dropPhoneNumber);
        return isValid;
      });
      return filteredLegs.length;
    },

    //check if the phone numbers have required length
    phoneNumberValidation(phoneNumber) {
      return phoneNumber && phoneNumber.length > 0 && phoneNumber.length < PHONE_NUMBER_LENGTH;
    },

    checkIfLegIsFirstLeg(leg) {
      const legsInBooking = this.get('activeLegsInBooking');
      const indexOfLeg = legsInBooking.indexOf(leg);

      if (indexOfLeg > 0) {
        return false;
      }

      return true;
    },

    combineServiceNeedsForGroupBooking() {
      const serviceNeeds = this.get('booking.selectedRiders').map(rider => rider.serviceNeeds);
      const uniqServiceNeeds = (0, _lodash.uniq)((0, _lodash.flattenDeep)(serviceNeeds));
      this.set('serviceNeeds', uniqServiceNeeds);
    },

    /**
     * when there is  no trip, this method will be called and close the spinner
     * @param ruleContext
     */
    noTripActionHandler(ruleContext) {
      console.log('noTripActionHandler : rule outcome map: ', ruleContext.outComeMap);
      this.get('workspace').set('isGlobalSpinnerVisible', false);
    },

    /**
     * when itp server api is failed, this method will be called and showed the warning message
     * @param ruleContext
     */
    itpRequestFailureHandler(ruleContext) {
      console.log('itpRequestFailureHandler : rule outcome map: ', ruleContext.outComeMap);
      this.get('notifications').warning('Failed to request ITP server');
      this.get('workspace').set('isGlobalSpinnerVisible', false);
    },

    eligibilityErrorHandler(ruleContext) {
      console.log('eligibilityErrorHandler : rule outcome map: ', ruleContext.outComeMap);
      const {
        errorInfo
      } = ruleContext;
      this.get('notifications').warning(errorInfo.message);
      this.get('workspace').set('isGlobalSpinnerVisible', false);
    },

    overridePermissionHandler(ruleContext) {
      console.log('overridePermissionHandler : rule outcome map: ', ruleContext.outComeMap);
      this.set('showOnlyEligibilityOkButton', true);
      this.set('riderIdConditionsMap', this.get('booking').getRiderIdToConditionsMap(ruleContext.riderConditionsResultMap, false));
      this.get('workspace').set('isGlobalSpinnerVisible', false);
      this.set('overrideEligibilityConditionsGranted', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.overrideEligibilityConditions));
    },

    /**
     * if user have  permission to override the service mode, show eligibility modal with 'yes' and 'no' button
     * @param ruleContext
     * @returns {Promise}
     */
    overrideConditionPreActionHandler(ruleContext) {
      return new Promise(resolve => {
        ruleContext.overrideCondition = true;
        this.set('booking.overrideEligibility', true);
        this.get('workspace').set('isGlobalSpinnerVisible', true);
        resolve();
      });
    },

    /**
     * if taxi not allowed ,  the method will be called
     * @param ruleContext
     */
    taxiRequestHandler(ruleContext) {
      this.setItpInfo(ruleContext);
      this.get('workspace').set('isGlobalSpinnerVisible', false);
      const {
        itpRequestResults
      } = ruleContext;
      const travelModeMapper = {};
      (0, _lodash.forEach)(itpRequestResults, (value, key) => {
        const travelModes = ['taxi']; // if the workflow resolves  itp  itinerary segments, show itp as default in service mode drop down

        if (this.hasValidItpResult(ruleContext, key)) {
          travelModes.unshift('itp');
        }

        travelModeMapper[key] = travelModes;
      });
      console.log('taxiRequestHandler : rule outcome map: ', ruleContext.outComeMap);
      this.set('travelModeMapper', travelModeMapper);
      this.fetchPromiseTime();
    },

    /**
     * if taxi not allowed ,  the method will be called
     * @param ruleContext
     */
    paraTransitHandler(ruleContext) {
      this.setItpInfo(ruleContext);
      this.get('workspace').set('isGlobalSpinnerVisible', false);
      const {
        itpRequestResults
      } = ruleContext;
      const travelModeMapper = {};
      (0, _lodash.forEach)(itpRequestResults, (value, key) => {
        const travelModes = ['paratransit', 'broker']; // if the workflow resolves  itp  itinerary segments, show itp as default in service mode drop down

        if (this.hasValidItpResult(ruleContext, key)) {
          travelModes.unshift('itp');
        }

        travelModeMapper[key] = travelModes;
      });
      this.set('travelModeMapper', travelModeMapper);
      this.fetchPromiseTime();
    },

    async itpRequestHandler(ruleContext) {
      this.setItpInfo(ruleContext);
      this.get('workspace').set('isGlobalSpinnerVisible', false);
      const {
        itpRequestResults
      } = ruleContext;
      const travelModeMapper = {};
      (0, _lodash.forEach)(itpRequestResults, (value, key) => {
        travelModeMapper[key] = ['itp'];
      });
      this.set('travelModeMapper', travelModeMapper);
      this.fetchPromiseTime();
    },

    hasValidItpResult(ruleContext, legIndex) {
      const {
        itpRequestResults,
        itpResultType
      } = ruleContext;
      return itpRequestResults.length && itpResultType[legIndex] !== _itineraryType.NO_SOLUTION;
    },

    updateFare() {
      const isAllLegs = this.get('booking.isAllLegs');
      this.get('booking.activeBooking.legs').forEach(async leg => {
        const legTravelNeeds = isAllLegs ? this.get('booking.activeBooking.legs.firstObject.legTravelNeeds') : leg.get('legTravelNeeds');
        const rider = this.get('booking.selectedRiders.firstObject');
        const segments = leg.get('segments');
        const fareCategoryName = Ember.isPresent(leg.get('segment.fareCategory.id')) ? leg.get('segment.fareCategory.id').capitalize() : null;
        const fareAmount = await this.get('fareCalc').calcFareByRiderIdAndTravelNeeds(rider, legTravelNeeds, true, fareCategoryName);
        segments.forEach(segment => {
          segment.set('fare', fareAmount);
        });
      });
    },

    syncAllLegsTravelNeeds() {
      const store = this.get('store');
      const isAllLegs = this.get('booking.isAllLegs');
      if (!isAllLegs) return;
      const allLegs = this.get('booking.activeBooking.legs').toArray();
      const srcLeg = allLegs[0];

      for (let i = 1; i < allLegs.length; i++) {
        const dstLeg = allLegs[i];
        const srcTravelNeeds = srcLeg.get('legTravelNeeds');
        const dstTravelNeeds = dstLeg.get('legTravelNeeds');

        for (let j = 0; j < srcTravelNeeds.length; j++) {
          const srcTravelNeed = srcTravelNeeds.objectAt(j);
          const dstTravelNeed = dstTravelNeeds.objectAt(j);

          if (dstTravelNeed) {
            dstTravelNeed.setProperties({
              count: srcTravelNeed.get('count'),
              isCountDisabled: srcTravelNeed.get('isCountDisabled'),
              travelNeedType: srcTravelNeed.get('travelNeedType')
            });
          } else {
            const newTravelNeed = store.createRecord('leg-travel-need', {
              count: srcTravelNeed.get('count'),
              isCountDisabled: srcTravelNeed.get('isCountDisabled'),
              travelNeedType: srcTravelNeed.get('travelNeedType'),
              passengerType: store.peekRecord('passenger-type', 'client')
            });
            dstTravelNeeds.pushObject(newTravelNeed);
          }
        }

        if (srcTravelNeeds.get('length') > 0 && dstTravelNeeds.get('length') > srcTravelNeeds.get('length')) {
          dstTravelNeeds.removeAt(srcTravelNeeds.get('length'), dstTravelNeeds.get('length') - srcTravelNeeds.get('length'));
        }
      }
    },

    executeEligibilityRules() {
      // clear itp info and selected travel mode in booking service, before executing rule
      this.get('booking').setItpInfo();
      this.get('booking').setSelectedTravelMode(); // create new   rule context

      const eligibilityRuleContext = new _eligibilityRuleContext.default(this.get('store'), this.get('booking'), this.get('tooltip'), this.get('systemConfig'), this.get('permissionLayer')); // build rule graph

      const eligibilityRuleBuilder = new _eligibilityRuleBuilder.default();
      const rootRule = eligibilityRuleBuilder.buildRuleGraph(this.noTripActionHandler.bind(this), this.itpRequestFailureHandler.bind(this), this.taxiRequestHandler.bind(this), this.paraTransitHandler.bind(this), this.overrideConditionPreActionHandler.bind(this), this.overridePermissionHandler.bind(this), this.itpRequestHandler.bind(this), this.eligibilityErrorHandler.bind(this)); // execute the rule, after execute the rule  one of the action handlers will be called by one of the rule

      this.get('workspace').set('isGlobalSpinnerVisible', true); // rules with async call  are executed

      eligibilityRuleBuilder.execute(rootRule, eligibilityRuleContext);
    },

    /**
     * create temp booking and fetch promise time after that, show booking details  screen
     * @returns {Promise<void>}
     */
    async fetchPromiseTime() {
      const isEditMode = this.get('isEditMode');
      const isCallBackBookingModeType = this.get('booking').isCallBackBookingModeType();

      try {
        if (this.get('booking.enableNewPromiseTimeApi')) {
          // TODO  to remove later after fixing backend api
          this.get('workspace').set('isGlobalSpinnerVisible', true); // regarding issue - NYAAR-17345

          if (!isEditMode) {
            this.set('booking.activeBooking.status', 'Unscheduled');
          } // update the active booking before fetching promise time


          if (isEditMode) {
            const curBookingStatus = this.get('booking.activeBooking.status');
            const defaultBookingData = this.get('defaultBookingData');
            await this.get('booking').saveBooking(isEditMode, defaultBookingData);

            if (curBookingStatus === 'failed' || isCallBackBookingModeType && curBookingStatus === 'Unscheduled' && this.get('booking.activeBooking.firstTrip.status') === 'callback') {
              const activeBookingID = this.get('booking.activeBooking.id');
              const tripCancelJobs = await this.get('booking').deleteBooking(activeBookingID, 'edit');
              const asynOperationInfoArray = tripCancelJobs ? tripCancelJobs.tripCancelJobs : [];

              for (const tripCancelInfo of asynOperationInfoArray) {
                if (tripCancelInfo) {
                  await this.get('booking').callScheduleAsyncOperation(tripCancelInfo);
                }
              }

              await this.get('booking').deleteTrips(activeBookingID);
            }
          } // clear temp booking info


          this.get('booking').setTemporaryBookingInfo();
          const bookingMode = this.get('booking').getBookingMode(); // create temp booking and call postAddBookingTripOperation  api to  get promise time for each legs

          const temporaryBookingResult = await this.get('booking').saveBookingWithPromiseTime(bookingMode);
          let bookingId = null;

          if (isCallBackBookingModeType) {
            // checking the result because temporaryBookingResult gets different format depending on same day or not
            if (temporaryBookingResult.constructor === Array) {
              bookingId = temporaryBookingResult.firstObject.relationships ? temporaryBookingResult.firstObject.relationships.booking.data.id : temporaryBookingResult.firstObject.booking;
            } else {
              bookingId = temporaryBookingResult.booking;
            }
          } else {
            bookingId = temporaryBookingResult.bookingId;
          }

          const bookingByID = `eq(id,(${bookingId}))&include=group,legs,legs.segments,legs.legTravelNeeds,legs.segments.pick,legs.segments.drop,subscription`;
          const booking = await this.get('store').query('booking', {
            filter: bookingByID
          }); // booking is an response from backend in the form of an array, so it's incorrect to call it booking

          const bookingSaved = booking.toArray()[0];
          const legsForBooking = bookingSaved.get('legs').toArray();
          const legsFromActiveBooking = this.get('booking.activeBooking.legs').toArray();

          for (let i = 0; i < legsForBooking.length; i++) {
            // multiple legs or a group booking
            const activeLeg = legsFromActiveBooking[i] ? legsFromActiveBooking[i] : legsFromActiveBooking[0];
            const legForBooking = legsForBooking[i];
            const segArrays = legForBooking.get('segments').toArray();

            const segments = _lodash.default.flatten(segArrays);

            const segment = segments[0]; // UI doesn't support 1 leg to multiple segments

            const selectedFareCategory = activeLeg.get('segment.fareCategory');
            segment.set('fareCategory', selectedFareCategory);
            segment.save();
          }

          if (this.get('passedADACheck')) {
            if (this.get('isStopOutisdeADABoundary')) {
              await this.createADAEligibilityActivity(bookingId, true);
            } else {
              await this.createADAEligibilityActivity(bookingId, false);
            }
          } //NYAAR-19192-for updating the certification location places


          await this.get('booking').updateCertificationLocationPlace(booking); //save segment stop phone number

          await this.get('booking').saveSegmentStopPhoneNumber(booking); // NYAAR-18800 If the Rider Does not have a service-need , we dont need to save the service-need

          const serviceNeedAvailableRider = this.get('booking.selectedRiders').filter(rider => !Ember.isEmpty(rider.serviceNeeds));

          if (serviceNeedAvailableRider.length) {
            await this.get('booking').saveLegServiceNeed(booking);
          }

          await this.get('booking').processPromiseTimeAsyncOperation(temporaryBookingResult, booking);
          await this.updateBookingTripGrid();
          this.toggleProperty('showBookingSummary');
        }
      } catch (err) {
        this.get('workspace').set('isGlobalSpinnerVisible', false);
        const message = isEditMode ? 'Failed to update Booking' : 'Failed to create Booking';
        this.get('notifications').warning(message);
      }
    },

    async createADAEligibilityActivity(bookingId, failCheck) {
      const adaOverride = this.get('adaOverride');
      const store = this.get('store');
      const adaEligibilityCat = store.peekRecord('eligibility-category', 'ada');
      const booking = store.peekRecord('booking', bookingId);
      const activityLogAdapter = store.adapterFor('activity-log');
      const context = {
        booking
      };
      let activity = failCheck ? 'Failed ADA Eligibility Check' : 'Passed ADA Eligibility Check';
      let details = failCheck ? 'Booking checked for ADA Eligibility and determined to be ineligible.' : 'Booking checked for ADA Eligibility and determined to be eligible.';
      let content = {
        userId: this.session.data.authenticated.userId,
        actualTime: (0, _moment.default)().toDate(),
        activity: activity,
        details: details
      };
      activityLogAdapter.createUserbookingActivity({
        content
      }, context, false);

      if (adaOverride) {
        activity = 'Override Eligibility';
        details = `Eligibility condition ${adaEligibilityCat.get('displayName')} override.`;
        content = {
          userId: this.session.data.authenticated.userId,
          actualTime: (0, _moment.default)().toDate(),
          activity: activity,
          details: details
        };
        activityLogAdapter.createUserbookingActivity({
          content
        }, context, false);
      }
    },

    setItpInfo(ruleContext) {
      const {
        itpRequestResults,
        itpResultType,
        hasOverrideITP
      } = ruleContext;
      this.get('booking').setItpInfo(itpRequestResults, itpResultType, hasOverrideITP);
    },

    resetFieldValues() {
      const defaultBookingData = this.get('defaultBookingData');
      const loadUnloadMapper = defaultBookingData.get('loadUnloadMapper');
      const selectedAllLeg = defaultBookingData.get('selectedAllLeg');
      const legTravelNeeds = defaultBookingData.get('legTravelNeeds').toArray();
      this.set('legTravelNeeds', legTravelNeeds);
      const legs = defaultBookingData.get('legs').toArray();
      const legSegments = defaultBookingData.get('legSegments').toArray();
      this.setIsAllLegState(selectedAllLeg);
      legs.forEach((leg, index) => {
        const legSegment = legSegments[index];
        const loadUnloadLeg = loadUnloadMapper[index];
        const clonedPickPlace = this.clonePlaceRecord((0, _unwrapProxy.unwrapProxy)(legSegment.get('pickPlace')));
        const clonedDropPlace = this.clonePlaceRecord((0, _unwrapProxy.unwrapProxy)(legSegment.get('dropPlace')));
        const pickPhoneNumbers = legSegment.get('pickPhoneNumbers');
        const dropPhoneNumbers = legSegment.get('dropPhoneNumbers'); //create pick segment-stop-phone-number model when undo the pick phone number

        if (pickPhoneNumbers.length) {
          const pickPhoneNumber = pickPhoneNumbers.firstObject; // Reg - NYAAR-20245

          pickPhoneNumber.rollbackAttributes();
        } //create drop segment-stop-phone-number model when undo the drop phone number


        if (dropPhoneNumbers.length) {
          const dropPhoneNumber = dropPhoneNumbers.firstObject; // Reg - NYAAR-20245

          dropPhoneNumber.rollbackAttributes();
        }

        leg.set('segments.firstObject.pick.place', clonedPickPlace);
        leg.set('segments.lastObject.drop.place', clonedDropPlace);
        leg.set('segments.firstObject.pick.phoneNumbers', pickPhoneNumbers);
        leg.set('segments.firstObject.drop.phoneNumbers', dropPhoneNumbers);
        leg.set('segments.lastObject.drop.notes', legSegment.get('dropNotes'));
        leg.set('segments.firstObject.pick.notes', legSegment.get('pickNotes'));
        leg.set('segments.firstObject.fare', legSegment.get('fare'));
        leg.set('serviceWindow', legSegment.get('serviceWindow'));
        leg.set('fundingAgency', legSegment.get('fundingAgency'));
        leg.set('segment.noSharing', legSegment.get('noSharing'));
        leg.set('anchor', legSegment.get('anchor'));
        leg.set('requestTime', legSegment.get('requestTime'));
        leg.set('loadTime', loadUnloadLeg.loadTime);
        leg.set('unloadTime', loadUnloadLeg.unloadTime);
        leg.set('legTravelNeeds', legTravelNeeds);
      });
      this.set('booking.activeBooking.legs', legs); // NYAAR-19387: Passenger List phoneNumber and notes are should not save when closing the create and edit booking screen

      const riderNotes = defaultBookingData.get('riderNotes');
      const rider = defaultBookingData.get('rider');
      const riderPhoneNumbers = defaultBookingData.get('riderPhoneNumbers');
      rider.forEach((riderData, index) => {
        const formattedPhoneNumber = riderPhoneNumbers[index];
        riderData.set('notes', riderNotes[index]);
        riderData.set('formattedPhoneNumber', formattedPhoneNumber);
      });
    },

    /**
    *Resetting field states(collapse) while clicking undo button
    */
    resetFieldStates() {
      const defaultBookingData = this.get('defaultBookingData');
      const legs = defaultBookingData.get('legs').toArray();
      const hasReturnLeg = legs.toArray().find(ltn => ltn.get('purpose') === 'return');
      this.set('pickAddressText', '');
      this.set('dropAddressText', '');

      if (!hasReturnLeg) {
        this.set('returnTripCollapseState', 'collapsed');
      } else {
        this.set('returnTripCollapseState', 'expanded');
      }

      if (this.get('travelNeedCollapseState') === 'expanded') this.set('travelNeedCollapseState', 'collapsed');
      if (this.get('passengerCollapseState') === 'expanded') this.set('passengerCollapseState', 'collapsed');
      if (this.get('serviceNeedCollapseState') === 'expanded') this.set('serviceNeedCollapseState', 'collapsed');
    },

    // NYAAR-13244 Booking - Upon Editing Fare field is not calculated correctly upon selecting the companions
    setDefaultFare() {
      const copiedBookingRecord = this.get('booking.copiedBookingRecord');
      const fare = this.get('legsInBooking.firstObject.segments.firstObject.fare');
      const regularFare = this.get('booking').getSysConfigRegularFare();
      const guestFare = this.get('booking').getSysConfigCompanionFare();
      const activeRiders = this.get('noOfActiveRiders'); // when REG or Guest fare is 0 return original fare

      if (regularFare === 0 || guestFare === 0) {
        if (activeRiders > 1) {
          this.set('defaultFare', regularFare * activeRiders);
          return;
        }

        this.set('defaultFare', regularFare);
        return;
      }

      if (copiedBookingRecord) {
        this.set('defaultFare', fare);
        return;
      }

      const companion = this.get('legTravelNeeds').find(travelNeed => {
        return travelNeed.get('travelNeedTypeNameUppercase') === 'COMPANION';
      });
      const companionCount = companion && companion.get('count') ? companion.get('count') : 0;
      const defaultFare = fare / (activeRiders + companionCount);

      if (activeRiders > 1) {
        this.set('defaultFare', defaultFare * activeRiders);
      } else {
        this.set('defaultFare', defaultFare);
      }
    },

    clearPickAndDropAddressText() {
      this.set('pickAddressText', '');
      this.set('dropAddressText', '');
    },

    /**
     * Reg - NYAAR-18838 show same day notification if user has booking permission
     * ( isSameDayBookingPermGranted , isFutureDayBookingPermGranted )
     * @param {Date} requestTime
     * @returns {boolean}
     */
    showSameDayNotificationByPermission(requestTime) {
      const userBookingPermissionDetails = this.get('booking').getUserBookingPermissionDetail();
      const isSameDayBookingPermGranted = userBookingPermissionDetails.isSameDayBookingPermGranted;
      const isFutureDayBookingPermGranted = userBookingPermissionDetails.isFutureDayBookingPermGranted;
      const currentDay = (0, _moment.default)().format('L');
      const futureDayBookingErrorMessage = "TODAY'S DATE IS SELECTED. PLEASE ENSURE THIS IS CORRECT."; // if user has both sameDayBooking and futureDayBooking Permission and choose a current date

      if (isSameDayBookingPermGranted && isFutureDayBookingPermGranted && (0, _moment.default)(requestTime.format('L')).isSame(currentDay)) {
        this.get('notifications').warning(futureDayBookingErrorMessage);
      }
    },

    /**
     *NYAAR-19105-UNDO button does not reset the Phone Field in the Trip details of Create booking and edit booking
     * @param {*} phoneNumberAttr(phone number model data)
     * @returns segment stop phone number model
     */
    createSegmentStopPhoneNumRecord(phoneNumberAttr) {
      const phoneNumberRecord = this.get('store').createRecord('segment-stop-phone-number', phoneNumberAttr);
      return [phoneNumberRecord];
    },

    /**
     * NYAAR-18789 :converts scheduleDate for overLaping schedule with curretLegDate
     * @param {*DateTimeFormat} selectedDate
     * @param {*DateTimeFormat} currentLegTime
     * @returns {*DateTimeFormat}
     */
    getOverLapDate(selectedDate, currentLegTime) {
      const currentDateAndTime = (0, _moment.default)(currentLegTime);
      selectedDate.hour(currentDateAndTime.hour());
      selectedDate.minutes(currentDateAndTime.minutes());
      selectedDate.seconds(currentDateAndTime.seconds());
      const selectedDateObjectWithTime = selectedDate.toDate();
      return selectedDateObjectWithTime;
    },

    /**
     * NYAAR-18789 : setting overlap scheduleDate to each trip-legs
     * @param {*} scheduleStart
     */
    overlapRequestTimeSet(scheduleStart) {
      const tripsInBooking = this.get('tripLegsInBooking');
      const ReturnTripsInBooking = this.get('returnTripLegsInBooking');
      const allTripsInBooking = [...tripsInBooking, ...ReturnTripsInBooking];
      allTripsInBooking.forEach(trip => {
        const currentLegRequestTime = trip.get('requestTime');
        const currentRequestDate = this.get('getOverLapDate')((0, _moment.default)(scheduleStart), currentLegRequestTime);
        trip.set('requestTime', currentRequestDate);
      });
    },

    /**
     * NYAAR-18789 : cleanning errorMessage for overLap warning-pop
     */
    overlapErrorMessageClean() {
      this.set('booking.isScheduleOverLap', false);
    },

    /**
     * NYAAR-18789 : reset overlap scheduleDate to each trip-legs(for cancle-button)
     */
    overlapScheduleLegReset() {
      const availableSchedules = this.get('booking.availableSchedules');

      if (availableSchedules.length > 1) {
        const previousLegTime = this.get('booking.firstLegRequestTime');
        this.overlapRequestTimeSet(previousLegTime);
      }
    },

    //all selected riders fare category as backup for future use
    setRiderFareCategories() {
      const riderFareCategories = this.get('riderFareCategories');
      const selectedRiders = this.get('booking.selectedRiders');
      selectedRiders.forEach(rider => {
        if (rider.fareCategory) {
          riderFareCategories.pushObject(rider.fareCategory);
        }
      });
    },

    isLocationInsidePolygons(location, blockoutRegionModel) {
      if (Ember.isEmpty(location.get('lng')) || Ember.isEmpty(location.get('lat'))) return false;

      const point = _turfHelpers.default.point([location.get('lng'), location.get('lat')]);

      const regions = blockoutRegionModel.get('value.regions') || [];
      let isInsidePolygons = false;
      regions.forEach(region => {
        if (region.polygon.length <= 0) return;
        const lnglat = region.polygon.map(latlng => {
          return [latlng[1], latlng[0]];
        });

        const polygon = _turfHelpers.default.polygon([lnglat]);

        if ((0, _booleanPointInPolygon.default)(point, polygon)) {
          isInsidePolygons = true;
        }
      });
      return isInsidePolygons;
    },

    drawMarkersAndPolylines() {
      if (!Ember.isPresent(this.get('map'))) {
        return;
      }

      this.get('layerGroup').clearLayers();
      const legsInBooking = this.booking.get('activeLegsInBooking') ? this.booking.get('activeLegsInBooking').toArray() : [];
      const allSegments = [];
      legsInBooking.forEach(leg => {
        const segments = leg.get('segments').toArray();
        const validSegments = segments.filter(segment => {
          return segment.get('pick.place.location.lat') && segment.get('drop.place.location.lat');
        });
        allSegments.push(...validSegments); //NYAAR-18714: while editing the itp booking, more than one segments are present and so map adds pin-points to all segments

        if (segments.length > 1 && this.get('isEditMode')) {
          const picksegment = segments.get('firstObject.pick.place.location');
          const dropsegment = segments.get('lastObject.drop.place.location');
          const legMarkers = [picksegment, dropsegment];
          const validMarkers = legMarkers.filter(marker => {
            return marker.get('lat') && marker.get('lng');
          });
          validMarkers.forEach(marker => {
            this.drawMarker(marker);
          });
          this.drawPolyline(segments.get('firstObject.pick'), segments.get('lastObject.drop'));
        } else {
          segments.forEach(segment => {
            const legMarkers = [segment.get('pick.place.location'), segment.get('drop.place.location')];
            const validMarkers = legMarkers.filter(marker => {
              return marker.get('lat') && marker.get('lng');
            });
            validMarkers.forEach(marker => {
              this.drawMarker(marker);
            });
          });
          this.drawPloylinePath(allSegments);
        }
      });
    },

    convertToTomTomAddress(address) {
      return {
        position: {
          lat: address.lat,
          lng: address.lng
        },
        address: {
          streetNumber: address.streetNumber,
          streetName: address.streetAddress,
          city: address.locality,
          state: address.region,
          postalCode: address.postalCode,
          freeformAddress: address.fullAddress
        }
      };
    },

    async updateAlternativeAddressSelection(address, placeModel) {
      const bookingService = this.get('booking');
      const addressSelectType = bookingService.get('addressSelectType');
      const tomtomAddress = this.convertToTomTomAddress(address);
      const beNomadAddress = placeModel.get('address.beNomadAddress');
      bookingService.setActiveDragPlace(placeModel);
      bookingService.setEmptyAddressLocation(placeModel);
      const addressModel = placeModel.get('address');
      const latlng = {
        lat: address.lat,
        lng: address.lng
      };
      const geoNode = await bookingService.getAddressGeoNode(latlng.lat, latlng.lng, beNomadAddress, addressSelectType);
      const zoneName = this.store.peekRecord('zone-type', geoNode.zone.zoneName);
      addressModel.set('zName', zoneName.get('displayName'));
      addressModel.set('zoneName', zoneName);
      const locationRecord = await this.store.createRecord('location', {
        lat: parseFloat(latlng.lat.toFixed(5)),
        lng: parseFloat(latlng.lng.toFixed(5))
      });
      locationRecord.set('geoNode', geoNode.geonode);
      await bookingService.updateAddress(addressModel, tomtomAddress, geoNode.zone);
      placeModel.set('location', locationRecord);
      bookingService.setActiveDragPlace(null);
      this.disableBookingSidePanel(false);
    },

    cancelAlternativeAddressOption(place) {
      if (Ember.isEmpty(place)) return;
      const address = place.get('address');
      const location = place.get('location');
      address.set('freeformAddress', '');
      address.set('streetNumber', '');
      address.set('streetAddress', '');
      address.set('locality', '');
      address.set('subLocality', '');
      address.set('zipCode', '');
      address.set('region', '');
      address.set('subRegion', '');
      address.set('postalCode', '');
      address.set('country', '');
      address.set('zName', '');
      address.set('zoneName', '');
      location.set('lat', 0);
      location.set('lng', 0);
      location.set('geoNode', '');
      this.booking.setActiveAddress(address);
      this.clearDrawnItems(BLOCKOUT_ALTERNATIVE_ADDRESS_TYPE);
      this.disableBookingSidePanel(false);
    },

    showAltAddressMarker(address) {
      this.updateBlockoutAlternativeAddress(address);
    },

    disableBookingSidePanel(disable) {
      const bookingSidePanel = Ember.$('.new-booking-form-side-drawer');

      if (disable) {
        bookingSidePanel.addClass('blockout-disabled');
      } else {
        bookingSidePanel.removeClass('blockout-disabled');
      }
    },

    validateBlockoutAddress(location, leg, place) {
      if (Ember.isEmpty(leg)) return;
      const blockoutRegionGroups = this.getBlockoutRegionGroups();
      const requestTime = leg.get('requestTime');
      const insideBlockoutRegion = this.checkAddressIsInsideBlockoutRegion(location, blockoutRegionGroups, requestTime);

      if (Ember.isPresent(insideBlockoutRegion)) {
        this.showAlternativeAddressesOptions(leg, place, insideBlockoutRegion);
        this.disableBookingSidePanel(true);
      }
    },

    checkEmptyLegTravelNeeds() {
      const legs = this.get('legsInBooking').toArray();
      const hasConsumableLegTravelNeeds = legs.every(leg => leg.get('legTravelNeeds').toArray().some(travelNeed => travelNeed.get('travelNeedType.isConsumable') && travelNeed.get('count') > 0));

      if (hasConsumableLegTravelNeeds) {
        return false;
      }

      return true;
    },

    checkPickOrDropAddressIsFallInsideBlockOutRegion() {
      const legs = this.get('legsInBooking').toArray();
      const blockoutRegionGroups = this.getBlockoutRegionGroups();
      let isPickOrDropAddressIsFallInsideBlockOutRegion = false;
      legs.forEach(leg => {
        const pickLocation = leg.get('segments.firstObject.pick.place.location');
        const dropLocation = leg.get('segments.lastObject.drop.place.location');
        const requestTime = leg.get('requestTime');
        const isPickAddressIsInsideBlockoutRegion = this.checkAddressIsInsideBlockoutRegion(pickLocation, blockoutRegionGroups, requestTime);
        const isdropAddressIsInsideBlockoutRegion = this.checkAddressIsInsideBlockoutRegion(dropLocation, blockoutRegionGroups, requestTime);

        if (Ember.isPresent(isPickAddressIsInsideBlockoutRegion) || Ember.isPresent(isdropAddressIsInsideBlockoutRegion)) {
          isPickOrDropAddressIsFallInsideBlockOutRegion = true;
          this.showBlockoutRegionFallsOutsideWarningMessage();
        }
      });
      return isPickOrDropAddressIsFallInsideBlockOutRegion;
    },

    checkADABoundaries() {
      const configADABoundaries = this.store.peekRecord('cs-config-item', 'config-Book_Trips/checkADABoundariesEligibleRiders');
      const checkADABoundaries = configADABoundaries ? this.store.peekRecord('cs-config-item', 'config-Book_Trips/checkADABoundariesEligibleRiders').value : false;
      const selectedRidersLength = this.get('booking.noOfSelectedRiders');
      if (!checkADABoundaries || selectedRidersLength > 1) return false;
      const selectedRider = this.get('selectedRiders.firstObject');
      const eligibilities = selectedRider.get('eligibilities');
      const activeLegs = this.get('booking.activeBooking.legs');
      const legsWithADAEligibility = []; // legs that have a requestTime in ADA eligibility category

      activeLegs.forEach(leg => {
        const requestTime = (0, _moment.default)(leg.get('requestTime'));
        const eligibility = eligibilities.find(elig => {
          const from = (0, _moment.default)(elig.from).startOf('day');
          const to = (0, _moment.default)(elig.to).endOf('day');
          return requestTime.isBetween(from, to) && elig.get('checkADABoundary');
        });
        const isADAEligibility = Ember.isPresent(eligibility) ? eligibility.get('checkADABoundary') : false;

        if (isADAEligibility) {
          this.set('riderADAEligibility', eligibility);
          legsWithADAEligibility.push(leg);
        }
      });
      if (legsWithADAEligibility.length <= 0) return false;
      this.set('passedADACheck', true);
      return true;
    },

    showAlternativeAddressesOptions(leg, place, blockoutRegion) {
      const blockoutRegionSetMessage = this.get('blockoutRegionSetMessage');
      const showAltAddressMarker = this.get('showAltAddressMarker');
      const cancelAlternativeAddressOption = this.get('cancelAlternativeAddressOption');
      const updateAlternativeAddressSelection = this.get('updateAlternativeAddressSelection');
      const message = `Trip can not be saved. The pickup and/or dropoff address is located in an area that is blocked out during the requested time. \n\n${blockoutRegionSetMessage} \n\nPlease select a different address.`;
      this.get('workspace').pushState('blockoutAlternativeAddresses', {
        message,
        blockoutRegion,
        showAltAddressMarker,
        cancelAlternativeAddressOption,
        leg,
        place,
        updateAlternativeAddressSelection
      });
    },

    showBlockoutRegionFallsOutsideWarningMessage() {
      const tooltip = this.get('tooltip');
      const blockoutRegionSetMessage = this.get('blockoutRegionSetMessage');
      const message = `Trip can not be saved. The pickup and/or dropoff address is located in an area that is blocked out during the requested time. ${blockoutRegionSetMessage}. Please select a different address.`;
      tooltip.pushConfirmation({
        title: 'Warning',
        hasOverlay: true,
        tip: message,
        primaryActionText: 'OK',
        primaryAction: () => {
          tooltip.reset();
        }
      });
    },

    showEmptyConsumableTravelNeedsWarningMessage() {
      const tooltip = this.get('tooltip');
      const message = ' Trip must have at least one consumable travel need, for example Ambulatory or Wheelchair.';
      tooltip.pushConfirmation({
        title: 'Booking',
        hasOverlay: true,
        tip: message,
        primaryActionText: 'OK',
        primaryAction: () => {
          tooltip.reset();
        }
      });
    },

    getBlockoutRegionGroups() {
      const blockoutRegionGroups = this.store.peekAll('cs-config-item').filter(config => {
        return config.category === 'config-System_Configuration-speed_region_groups';
      }).filter(blockoutRegion => {
        return blockoutRegion.get('value.blockout');
      });
      return blockoutRegionGroups;
    },

    checkAddressIsInsideBlockoutRegion(location, blockoutRegionGroups, requestTime) {
      const insideBlockoutRegion = blockoutRegionGroups.find(blockoutRegion => {
        const startTime = blockoutRegion.get('value.start') && (0, _moment.default)(blockoutRegion.get('value.start'));
        const endTime = blockoutRegion.get('value.end') && (0, _moment.default)(blockoutRegion.get('value.end'));
        const isRequestTimeIsInsideBlockoutRequestTime = (0, _moment.default)(requestTime).isBetween(startTime, endTime, 'days', []);
        const message = blockoutRegion.get('value.message') || '';

        if (isRequestTimeIsInsideBlockoutRequestTime) {
          const fallsInsideRegion = this.isLocationInsidePolygons(location, blockoutRegion);

          if (fallsInsideRegion) {
            this.set('blockoutRegionSetMessage', message);
            return true;
          }
        }

        return false;
      });
      return insideBlockoutRegion;
    },

    createPolygon(latlngs, color, blockoutRegionName, type) {
      const polygon = window.L.polygon([latlngs], {
        color: color,
        properties: {
          blockoutRegionName: blockoutRegionName,
          type: type
        }
      });

      if (type === BLOCKOUT_REGION_GROUP_TYPE) {
        polygon.pm.setOptions({
          allowEditing: false,
          allowRemoval: false,
          allowCutting: false,
          allowRotation: false,
          draggable: false
        });
        polygon.bindTooltip(blockoutRegionName, {
          permanent: true,
          direction: 'center',
          className: 'polygon-labels'
        });
      }

      return polygon;
    },

    createMarker(lat, lng, fullAddress) {
      let addressMarker = null;
      addressMarker = window.L.AwesomeMarkers.icon({
        icon: 'plus',
        prefix: 'fa',
        markerColor: 'green'
      });
      const marker = window.L.marker([lat, lng], {
        pmIgnore: true,
        properties: {
          type: BLOCKOUT_ALTERNATIVE_ADDRESS_TYPE
        },
        icon: addressMarker
      });
      marker.bindTooltip(fullAddress, {
        permanent: false,
        direction: 'auto',
        className: 'marker-labels'
      });
      return marker;
    },

    getPrimarySchedulesBySorting(sortQuery) {
      const schedules = this.get('store').peekAll('schedule').sortBy(sortQuery);
      const primarySchedules = schedules.filter(availabelSchedule => availabelSchedule.get('type') === SCHEDULE_TYPE);
      return primarySchedules;
    },

    setIsAllLegState(isChecked) {
      this.set('booking.isAllLegs', isChecked);
    },

    /**
     * Some of the booking leg will not have legServiceNeeds so create LegServiceNeed Model only in Edit Mode
     * @param record - Booking
     * @returns {{length}|*|[]}
     */
    createAndGetLegServiceNeeds(record) {
      const createdLegs = record.legs.toArray();
      const createdLegServiceNeeds = createdLegs.firstObject.legServiceNeeds.toArray();

      if (!createdLegServiceNeeds.length) {
        console.log('createdLegServiceNeeds'.toUpperCase());
        const booking = this.get('booking');
        const allServiceNeeds = booking.reGroupLegServiceNeedsForBooking();
        return booking.createLegServiceNeeds(allServiceNeeds);
      }

      return createdLegServiceNeeds;
    },

    /**
     * Set Created LegServiceNeed in leg only in Edit Mode
     * @param record - Booking
     */
    setLegServiceNeedsInLeg(record) {
      const createdLegs = record.legs.toArray();
      createdLegs.forEach(leg => {
        if (Ember.isEmpty(leg.get('legServiceNeeds'))) {
          const legServiceNeeds = this.get('legServiceNeeds');
          leg.set('legServiceNeeds', legServiceNeeds);
        }
      });
    },

    async prepareADAEligibilityPayload(legs) {
      const adaEligiblePayload = {
        'legs': legs.map(leg => {
          const segment = leg.get('segment');
          const pick = Ember.isPresent(segment) ? segment.get('pick') : null;
          const drop = Ember.isPresent(segment) ? segment.get('drop') : null;
          return {
            requestTime: (0, _moment.default)(leg.get('requestTime')).format(),
            pick: {
              lat: Ember.isPresent(pick) ? pick.get('lat') : null,
              lng: Ember.isPresent(pick) ? pick.get('lng') : null
            },
            drop: {
              lat: Ember.isPresent(drop) ? drop.get('lat') : null,
              lng: Ember.isPresent(drop) ? drop.get('lng') : null
            }
          };
        })
      };
      return adaEligiblePayload;
    },

    switchLatLonPositions(geoms) {
      const flattenGeoms = _lodash.default.flatten(_lodash.default.flatten(_lodash.default.flatten(geoms)));

      flattenGeoms.map(point => {
        const lon = point[0];
        const lat = point[1];
        point[0] = lat;
        point[1] = lon;
      });
    },

    async postADAEligibility(legs) {
      const adaEligibilityPayload = await this.prepareADAEligibilityPayload(legs);
      return this.get('ajax').request(_apiUrls.API.iqMap.host + '/ada-eligibility-query', {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${this.get('session.data.authenticated.token')}`
        },
        data: JSON.stringify(adaEligibilityPayload)
      }).then(result => {
        if (result.adaEligible) {
          return {
            legs: []
          };
        }

        this.switchLatLonPositions(result.geoms);
        return result;
      }).catch(() => {
        return {
          legs: []
        };
      });
    },

    setDistances(coord) {
      if (this.get('distancePt1') === null) {
        this.set('distancePt1', this.markerPinPoint(coord));
      } else if (this.get('distancePt2') === null) {
        this.set('distancePt2', this.markerPinPoint(coord));
        const map = this.get('booking.bookingMap');
        let distance = (0, _geolib.getDistance)(this.get('distancePt1.native._latlng'), coord); // eslint-disable-line
        //convert from metre -> feet -> mile

        distance = distance * 3.281 / 5280;
        this.set('computedDistance', `${distance.toFixed(3)} mi`);
        const polyLineOptions = _polyline.default['dottedDistance']; // eslint-disable-line

        const distancePoints = [{
          lat: this.get('distancePt1.native._latlng.lat'),
          lon: this.get('distancePt1.native._latlng.lng')
        }, {
          lat: this.get('distancePt2.native._latlng.lat'),
          lon: this.get('distancePt2.native._latlng.lng')
        }];
        const distancePolyline = new bemap.Polyline(distancePoints, {
          style: polyLineOptions
        });
        map.addPolyline(distancePolyline);
        this.set('distancePolyline', distancePolyline);
      } else {
        this.distanceReset();
      }
    },

    distanceReset() {
      const map = this.get('booking.bookingMap');
      const distancePt1 = this.get('distancePt1');
      const distancePt2 = this.get('distancePt2');
      const distancePolyline = this.get('distancePolyline');

      if (distancePt1) {
        map.removeMarker(distancePt1);
      }

      if (distancePt2) {
        map.removeMarker(distancePt2);
      }

      if (distancePolyline) {
        map.removePolyline(distancePolyline);
      }

      this.setProperties({
        distancePt1: null,
        distancePt2: null,
        computedDistance: null,
        distancePolyline: null
      });
    },

    markerPinPoint(coord) {
      const map = this.get('booking.bookingMap');
      if (!coord) return;
      const bemapCoords = new bemap.Coordinate(coord.lng, coord.lat);
      const pintPointIcon = {
        icon: {
          src: '/map-icons/icon-pinpoint.svg',
          anchorX: 0.50,
          anchorY: 1,
          height: 23,
          width: 23,
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          scale: 1
        }
      };
      const bemapIcon = new bemap.Icon(pintPointIcon.icon);
      const marker = new bemap.Marker(bemapCoords, {
        icon: bemapIcon
      });
      map.addMarker(marker);
      return marker;
    },

    async updateBookingTripGrid() {
      const bookingService = this.get('booking');
      const [bookings, trips] = await this.get('apiContext').loadBookingsForSelectedExternalRiders();
      bookingService.set('currentBookings', bookings || []);
      bookingService.set('currentTrips', trips || []);
    },

    actions: {
      onUndoClick() {
        this.resetFieldValues();
        this.resetFieldStates();
        this.setDefaultBookingData();
      },

      async onSaveClick() {
        const isEditingUnscheduledBooking = this.get('booking.isEditingUnscheduledBooking'); // disble check mark to prevent multiple clicks.

        this.set('saveInProgress', true);

        if (isEditingUnscheduledBooking) {
          this.send('onYesButtonClick');
        } else {
          this.set('canSave', true);
          this.set('booking.setMarkerPinOff', !this.get('booking.setMarkerPinOff'));

          if (await this.validateRecords(true)) {
            if (this.checkPickOrDropAddressIsFallInsideBlockOutRegion()) {
              this.showBlockoutRegionFallsOutsideWarningMessage();
              return;
            }

            if (this.checkEmptyLegTravelNeeds()) {
              this.showEmptyConsumableTravelNeedsWarningMessage();
              return;
            }

            this.set('passedADACheck', false);
            this.set('isStopOutisdeADABoundary', false);

            if (this.checkADABoundaries()) {
              const activeLegs = this.get('booking.activeBooking.legs');
              const adaEligibilityResponse = await this.postADAEligibility(activeLegs);
              let adaEligible = true;
              adaEligibilityResponse.legs.map((leg, index) => {
                const activeLeg = activeLegs.objectAt(index);

                if (Ember.isPresent(activeLeg)) {
                  leg.pick.address = activeLeg.get('segment.pick.place.address.fullAddress');
                  leg.drop.address = activeLeg.get('segment.drop.place.address.fullAddress');
                }

                if (!leg.pick.adaEligible) {
                  adaEligible = false;
                }

                if (!leg.drop.adaEligible) {
                  adaEligible = false;
                }
              });

              if (!adaEligible) {
                this.set('arrayADABoundaries', adaEligibilityResponse);
                this.set('showADAEligibility', true);
                this.set('adaOverride', false);
                this.set('isStopOutisdeADABoundary', true);
                return;
              }

              this.set('isStopOutisdeADABoundary', false);
            }

            this.executeEligibilityRules();
          }
        }

        this.set('saveInProgress', false);
      },

      onXButtonClick() {
        this.toggleProperty('isProgressShown');
      },

      onProgressNoClick() {
        this.toggleProperty('isProgressShown'); // NYAAR-19387 Reset should be work for both create and edit booking

        this.resetFieldValues();
        this.updateBookingTripGrid();
        this.get('editModal').close();
      },

      onCloseClick() {
        this.toggleProperty('isProgressShown');
      },

      async onApplyClick() {
        if (await this.validateRecords(false)) {
          Ember.$('html,body,.tomtom-map').removeClass('custom-cursor');
          this.get('geocode').activateGeocode(false);
          this.set('addressRecord', null);
          this.get('service').apply();
        }
      },

      validateRequestDateTime(leg) {
        this.set('canSave', true);
        this.set('errorMessage', '');

        if (isNaN(leg.get('requestTime'))) {
          return;
        }

        if (!this.validateEligibility(leg)) {
          this.set('selectedLegDate', (0, _moment.default)(leg.get('requestTime')).format('M/D/Y'));
          this.get('booking').showErrorMessage(`${this.get('riderToBook.firstName')} ${this.get('riderToBook.lastName')}
        has no client eligibility records for the date \n  ${this.get('selectedLegDate')} . Please select another date.`);
          return;
        }

        if (!this.validateLegRequestTimeAfterPrevLegRequestTime()) {
          this.get('booking').showErrorMessage("The additional trip's request time must come after the previous trip's request time.");
          return;
        }

        if (!this.validateCutOff(leg)) {
          this.get('booking').showErrorMessage(`Same day booking must be at least ${CUT_OFF_TIME_FOR_SAMEDAY * 60} mins in advance. Please select a different request time.`);
          return;
        }

        if (!this.validateFutureBookingTimeline()) {
          this.get('booking').showErrorMessage('Trip date must be within the allowed booking timeframe.');
          return;
        }

        this.set('booking.isOpenErrorModal', false);
        this.refreshBlockoutRegionsPolygons();
      },

      async onYesButtonClick(event) {
        let fromADADeny = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        const isEditMode = this.get('isEditMode');
        const defaultBookingData = this.get('defaultBookingData');
        const isEditingUnscheduledBooking = this.get('booking.isEditingUnscheduledBooking');

        if (await this.validateRecords(false)) {
          if (this.checkPickOrDropAddressIsFallInsideBlockOutRegion()) {
            this.showBlockoutRegionFallsOutsideWarningMessage();
            return;
          }

          this.get('workspace').set('isGlobalSpinnerVisible', true);

          if (!isEditingUnscheduledBooking) {
            this.set('booking.activeBooking.status', 'willCall');
          }

          if (fromADADeny) {
            this.set('booking.activeBooking.status', 'failed');
          }

          this.set('booking.activeBooking.legs', this.get('legsInBooking'));

          try {
            if (isEditMode) {
              const bookingID = this.get('booking.activeBooking.id');
              const action = isEditingUnscheduledBooking ? 'editUnscheduled' : 'refuse';
              const tripCancelJobs = await this.get('booking').deleteBooking(bookingID, action, isEditMode);
              const asynOperationInfoArray = tripCancelJobs ? tripCancelJobs.tripCancelJobs : [];

              for (const tripCancelInfo of asynOperationInfoArray) {
                if (tripCancelInfo) {
                  await this.get('booking').callScheduleAsyncOperation(tripCancelInfo);
                }
              } // user cannot modify data linked to scheduling domain when editing unscheduled booking, so do not delete trips when edit/save unscheduled booking


              if (!isEditingUnscheduledBooking) {
                // deletion of trips is primarily for keeping data in sync when SS related data is modified during edit booking. ie. addresses, travelneeds, etc.
                await this.get('booking').deleteTrips(bookingID);
              }
            }

            const bookingResult = await this.get('booking').saveBooking(isEditMode, defaultBookingData);
            const currBookingId = Ember.isPresent(bookingResult) ? bookingResult.data.id : this.get('booking.activeBooking.id');
            await this.get('booking').setSelectedRiders(isEditMode);
            await this.updateBookingTripGrid();
            this.setIsAllLegState(false);
            const bookingByID = `eq(id,(${currBookingId}))&include=group,legs,legs.segments,legs.legTravelNeeds,legs.segments.pick,legs.segments.drop,subscription`;
            const booking = await this.get('store').query('booking', {
              filter: bookingByID
            });
            const fareCategory = this.get('booking.activeBooking.firstLeg.segment.fareCategory');
            const segment = booking.get('firstObject').get('firstLeg.segment'); // we are doing a second save to segments for the fareCategory
            // We are not sending it with async saveBooking because of time constraint

            segment.set('fareCategory', fareCategory);
            const segmentModel = await segment.save();
            await this.get('store').findRecord('segment', segmentModel.id);

            if (this.get('passedADACheck')) {
              const bookingId = bookingResult.data.id;

              if (this.get('isStopOutisdeADABoundary')) {
                await this.createADAEligibilityActivity(bookingId, true);
              } else {
                await this.createADAEligibilityActivity(bookingId, false);
              }
            } //To check whether the user has permission to override trip eligibility conditions


            this.get('notifications').warning('Booking was saved but it will not be scheduled');
          } catch (err) {
            const message = isEditMode ? 'Failed to update Booking' : 'Failed to create Booking';
            this.get('notifications').warning(message);
          } finally {
            this.toggleProperty('isProgressShown');
            this.get('editModal').close();
            this.get('workspace').set('isGlobalSpinnerVisible', false);
          }
        }
      },

      async onClickClose(isModalDialogClose) {
        this.overlapScheduleLegReset();
        this.toggleProperty('showBookingSummary');
        this.set('saveInProgress', false); // onClickClose is used everywhere so When we click X on Booking Accept Screen don't set the isAllLeg as false

        if (!isModalDialogClose) {
          this.setIsAllLegState(false);
        }
      },

      async onClickXADA() {
        this.toggleProperty('showADAEligibility');
      },

      async onClickDenyADA() {
        this.toggleProperty('showADAEligibility');
        await this.send('onYesButtonClick', null, true);
      },

      async onClickOverrideADA() {
        this.toggleProperty('showADAEligibility');
        this.set('adaOverride', true);
        this.executeEligibilityRules();
      },

      onWarningModalClose() {
        this.set('isMaxRiderTimePopUp', false);
        this.set('isOpenErrorModal', false);
        this.set('disablePhoneNumber', false);
        this.set('disablePhoneNumber', false);
      },

      setLoadAndUnLoadTimeFlag(isLoadAndUnLoadTimeValid) {
        this.set('isLoadAndUnLoadTimeValid', isLoadAndUnLoadTimeValid);
      },

      setIsLoadAndUnLoadTimeNumberFlag(isLoadAndUnLoadTimeNumber) {
        this.set('isLoadAndUnLoadTimeNumber', isLoadAndUnLoadTimeNumber);
      },

      onEligibleWarningClose() {
        this.overlapScheduleLegReset();
        this.set('showEligibilityRestrictions', false);
      },

      onEligibleWarningOK() {
        this.set('showEligibilityRestrictions', false);
        this.get('editModal').close();
      },

      onSelectScheduleOption(option) {
        this.set('booking.selectedSchedule', option);
        this.set('booking.legSchedule.scheduleName', option.name);
      },

      onConfirmClick() {
        const firstLegRequestTime = this.get('tripLegsInBooking.firstObject.requestTime');
        this.set('booking.firstLegRequestTime', firstLegRequestTime);
        this.overlapErrorMessageClean();
      },

      onCancelClick() {
        this.overlapErrorMessageClean();
      },

      onCloseButtonClick() {
        this.overlapErrorMessageClean();
      },

      onDistanceButtonClick() {
        this._bodyClickFunction = event => {
          this.actions.onBodyClickFunction(event, this);
        };

        document.body.addEventListener('click', this._bodyClickFunction);
        this.set('isComputingDistance', true);
        this.get('booking.bookingMap').native.on('click', e => {
          this.setDistances(e.latlng);
        });
        Ember.$('.tomtom-map').addClass('pinpoint-cursor');
      },

      onBodyClickFunction(e, thisObject) {
        const node = e.target;
        let isInPage = true;

        if (document.getElementsByClassName('map-widget')[0]) {
          isInPage = document.getElementsByClassName('map-widget')[0].contains(node);
        }

        if (isInPage === false) {
          thisObject.distanceReset();
        }
      },

      onDistanceClose() {
        document.body.removeEventListener('click', this._bodyClickFunction);
        this.set('isComputingDistance', false);
        this.distanceReset();
        this.get('booking.bookingMap.native').off('click');
        Ember.$('.tomtom-map').removeClass('pinpoint-cursor');
      },

      onDistanceReset() {
        this.distanceReset();
      }

    }
  });

  _exports.default = _default;
});