define("adept-iq/pods/components/iq-widgets/reconcile-stops-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/reconcile-stops-widget',
    rowComponent: 'table/rows/action-row',
    modelName: 'dwh-stop-point',
    defaultSortId: 'actualarrivetime',
    defaultSortAsc: false,
    title: 'Stops',
    columns: [{
      id: 'id',
      type: 'uuid',
      label: 'ID',
      valuePath: 'id',
      hidden: true,
      searchable: true
    }, {
      id: 'stopPointId',
      type: 'uuid',
      label: 'ID',
      valuePath: 'iqid',
      hidden: true,
      searchable: true
    }, {
      id: 'tripId',
      type: 'text',
      label: 'Trip ID',
      valuePath: 'tripId',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'stoptype',
      type: 'text',
      label: 'Type',
      valuePath: 'stoptype',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'routeName',
      type: 'text',
      label: 'Route Name',
      valuePath: 'routename',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'passengerFirstName',
      type: 'text',
      label: 'Passenger First Name',
      valuePath: 'passengerfirstname',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'passengerLastName',
      type: 'text',
      label: 'Passenger Last Name',
      valuePath: 'passengerlastname',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'address',
      type: 'text',
      label: 'Address',
      valuePath: 'readableAddress',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'city',
      type: 'text',
      label: 'City',
      valuePath: 'locality',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'county',
      type: 'text',
      label: 'County',
      valuePath: 'subregion',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'state',
      type: 'text',
      label: 'State',
      valuePath: 'region',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'zip',
      type: 'text',
      label: 'Zip',
      valuePath: 'postalcode',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'odometer',
      type: 'number',
      label: 'Odometer on Arrival',
      valuePath: 'actualodometer',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'actualarrivetime',
      type: 'time',
      format: 'HH:MM',
      label: 'Actual Arrive Time',
      valuePath: 'actualarrivetime',
      searchable: true
    }, {
      id: 'actualdeparttime',
      type: 'time',
      format: 'HH:MM',
      label: 'Actual Depart Time',
      valuePath: 'actualdeparttime',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'stopOrdinal',
      type: 'number',
      label: 'Stop Ordinal',
      valuePath: 'routeordinal',
      defaultWidth: 70,
      searchable: true
    }]
  };
  _exports.default = _default;
});