define("adept-iq/adapters/route-vehicle-driver", ["exports", "adept-iq/adapters/-dispatch", "adept-iq/config/api-urls", "moment", "lodash"], function (_exports, _dispatch, _apiUrls, _moment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dispatch.default.extend({
    ajaxService: Ember.inject.service('ajax'),
    session: Ember.inject.service('session'),

    updateRecord(store, type, snapshot) {
      const data = {};
      const serializer = store.serializerFor(type.modelName);
      const id = snapshot.id;
      serializer.serializeIntoHash(data, type, snapshot, {
        includeId: true
      });
      let url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');

      if (snapshot.adapterOptions && snapshot.adapterOptions.ignoreCapacityViolations) {
        url += '?ignoreCapacityViolations=' + snapshot.adapterOptions.ignoreCapacityViolations;
      }

      return this.ajax(url, 'PATCH', {
        data: data
      });
    },

    createRouteVehicleDriver(dispatchRoutePayload, route, driver, canIgnoreCapacityViolation) {
      const ajax = this.get('ajaxService');
      const session = this.get('session');
      const {
        dispatchSchedule
      } = dispatchRoutePayload.data.relationships; //added for behavioral consistency with PATCH

      const url = _apiUrls.API.dispatchService.host + '/route-vehicle-driver' + (canIgnoreCapacityViolation ? '?ignoreCapacityViolations=true&newRoute=true' : '?newRoute=true');
      const json = {
        data: {
          type: 'routeVehicleDriver',
          attributes: {
            startTime: (0, _moment.default)(route.get('plannedStartTime')).format(),
            endTime: (0, _moment.default)(route.get('plannedEndTime')).format()
          },
          relationships: {
            dispatchRoute: {
              data: {
                id: dispatchRoutePayload.data.id,
                type: 'dispatchRoute'
              }
            },
            dispatchSchedule: {
              data: {
                id: dispatchSchedule.data.id,
                type: 'dispatchSchedule'
              }
            }
          }
        }
      };

      if (route.get('vehicle.isPlaceholder') === false) {
        json.data.relationships = { ...json.data.relationships,
          vehicle: {
            data: {
              id: route.get('vehicle.id'),
              type: 'vehicle'
            }
          }
        };
      }

      if (Ember.isPresent(driver) && Ember.isPresent(driver.get('id'))) {
        json.data.relationships.driver = {
          data: {
            id: driver.get('id'),
            type: 'driver'
          }
        };
      }

      return ajax.post(url, {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${session.data.authenticated.token}`
        },
        data: json
      });
    },

    updateRouteVehicleDriver(dispatchRoute, vehicle, driver, dispatchRouteVersion) {
      const session = this.get('session');
      const ajax = this.get('ajaxService');
      const route = dispatchRoute.get('route');
      const dispatchRouteId = dispatchRoute.get('id');
      const dispatchScheduleId = dispatchRoute.get('dispatchSchedule.id');
      const rvd = dispatchRoute.get('latestRouteVehicleDriver');
      const rvdId = rvd.get('id');
      const driverId = driver ? driver.get('id') : null;
      const vehicleId = vehicle.get('id');
      const json = {
        data: {
          id: rvdId,
          type: 'routeVehicleDriver',
          attributes: {
            startTime: (0, _moment.default)(route.get('plannedStartTime')).format(),
            endTime: (0, _moment.default)(route.get('plannedEndTime')).format()
          },
          relationships: {
            dispatchSchedule: {
              data: {
                id: dispatchScheduleId,
                type: 'dispatchSchedule'
              }
            },
            dispatchRoute: {
              data: {
                id: dispatchRouteId,
                type: 'dispatchRoute'
              }
            },
            vehicle: {
              data: {
                id: vehicleId,
                type: 'vehicle'
              }
            }
          }
        }
      };

      if (dispatchRouteVersion) {
        json.included = [{
          type: 'dispatchRoute',
          id: dispatchRoute.get('id'),
          attributes: {
            version: dispatchRouteVersion
          }
        }];
      }

      if (Ember.isPresent(driverId)) {
        json.data.relationships.driver = {
          data: {
            id: driverId,
            type: 'driver'
          }
        };
      } else {
        json.data.relationships.driver = {
          data: null
        };
      }

      return ajax.post(_apiUrls.API.dispatchService.host + `/route-vehicle-driver/${rvdId}`, {
        method: 'PATCH',
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${session.data.authenticated.token}`
        },
        data: json
      });
    },

    normalizeErrorResponse(status, headers, payload) {
      if (payload && typeof payload === 'object' && payload.errors) {
        return payload.errors; // To deal with the API's error messages that do not comply to JSONAPI Standard.
      } else if (Ember.isPresent(payload.message)) {
        const error = {
          status: `${status}`,
          title: 'The backend responded with an error',
          detail: payload.message
        };

        if (!_lodash.default.isNil(payload.message.status) && payload.message.status('409') < 0) {
          this.get('notifications').warning(payload.message);
        }

        return [error];
      }

      return [{
        status: `${status}`,
        title: 'The backend responded with an error',
        detail: `${payload}`
      }];
    }

  });

  _exports.default = _default;
});