define("adept-iq/pods/components/rider-management-widget/component", ["exports", "adept-iq/config/placeholders", "adept-iq/pods/components/rider-management-widget/config", "ember-changeset", "ember-changeset-validations", "adept-iq/utils/unwrapProxy", "adept-iq/pods/components/rider-management-widget/rider-contact-information/validation", "moment", "lodash"], function (_exports, _placeholders, _config, _emberChangeset, _emberChangesetValidations, _unwrapProxy, _validation, _moment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const NO_OP = () => {};

  var _default = Ember.Component.extend({
    classNames: ['rider-management-container'],
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    riderService: Ember.inject.service('rider'),
    isActionButtonsDisabled: false,
    isArchivedButtonsDisabled: false,
    isEligibilityScreenDisabled: false,
    isArchived: false,
    status: null,
    id: null,
    selectedRider: null,
    showRiderModal: false,
    editAPI: null,
    options: _config.riderScreenSelectOptions,
    selectedRiderScreen: _config.riderScreenSelectOptions.firstObject,
    US_DATE_PLACEHOLDER: _placeholders.US_DATE_PLACEHOLDER,
    noRiderSelected: Ember.computed('riderService.lastAccessedRiderId', function () {
      return !Ember.isPresent(this.get('riderService.lastAccessedRiderId'));
    }),
    riderServiceMode: Ember.computed('riderChangeSet.{riderServiceMode,riderServiceMode.[]}', function () {
      return this.get('riderChangeSet.riderServiceMode.lastObject');
    }),
    riderStatusOptions: Ember.computed('riderChangeSet.status', function () {
      const recordStatusOptions = ['Temporary', 'Approved', 'Archived', 'Denied'];
      const existingRiderRecordStatus = this.get('riderChangeSet.status');
      return recordStatusOptions.filter(status => {
        return status.toLowerCase() !== existingRiderRecordStatus;
      });
    }),

    async init() {
      const queryParams = this.get('_target.target.currentState.routerJsState.fullQueryParams');
      const riderId = queryParams.riderId;
      const viewPage = queryParams.viewPage;

      this._super(...arguments);

      this.setSelectedRider = this.setSelectedRider.bind(this);
      this.setLastSelectedRider = this.setLastSelectedRider.bind(this);
      this.resetForm = this.resetForm.bind(this);
      this.checkEligibility = this.checkEligibility.bind(this);
      this.showPopUpForDeniedAndArchived = this.showPopUpForDeniedAndArchived.bind(this);
      this.showPopUpForArchived = this.showPopUpForArchived.bind(this);
      const resetForm = this.get('resetForm');
      this.set('editAPI', Ember.Object.create({
        saveAction: NO_OP,
        newAction: NO_OP,
        deleteAction: NO_OP,
        editAction: NO_OP,
        undoAction: NO_OP,
        resetForm: resetForm,
        isNewBtnDisabled: false,
        isEditBtnDisabled: false,
        isDeleteBtnDisabled: false,
        isEditing: false
      }) // the real actions are attached in the child components
      );
      await this.get('riderService').getConfigRiderActivityLogTypes();
      this.setupPageFromParams(riderId, viewPage);
    },

    async setupPageFromParams(riderId, viewPage) {
      const store = this.get('store');
      if (Ember.isEmpty(riderId)) return;
      const riderRecord = await store.findRecord('rider', riderId).catch(() => {
        return;
      });
      if (Ember.isEmpty(riderRecord)) return;
      await this.setSelectedRider(riderRecord);
      if (Ember.isEmpty(viewPage)) return;
      this.switchViewPage(viewPage);
    },

    riderChangeSet: Ember.computed('selectedRider', function () {
      const rider = (0, _unwrapProxy.unwrapProxy)(this.get('selectedRider'));
      return this.setRiderChangeSet(rider);
    }),

    setRiderChangeSet() {
      let record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      const store = this.get('store');
      let riderRecord;

      if (record) {
        riderRecord = record;

        if (riderRecord.status === 'Archived') {
          this.set('isArchived', true);
        } else {
          this.set('isArchived', false);
        }
      } else if (!riderRecord) {
        riderRecord = store.createRecord('rider');
        riderRecord.set('dateOfBirth', null);
        this.set('rider', riderRecord);
      }

      return new _emberChangeset.default(riderRecord, (0, _emberChangesetValidations.default)(_validation.RIDER_VALIDATION), _validation.RIDER_VALIDATION);
    },

    updateThumbnailImage(thumbnailImage) {
      Ember.$('#thumbnailPreview').attr('src', thumbnailImage);
    },

    async setSelectedRider(selectedRider) {
      const riderId = selectedRider.get('id');
      const thumbnailImage = selectedRider.get('thumbnailImage');
      this.get('riderService').setlastAccessedRiderId(riderId);
      await this.setLastSelectedRider(riderId);
      this.updateThumbnailImage(thumbnailImage);
    },

    resetForm() {
      this.set('selectedRider', null);
    },

    async setLastSelectedRider(riderId) {
      const store = this.get('store');
      let zoneTypes = store.peekAll('zone-type');

      if (zoneTypes.length === 0) {
        zoneTypes = await this.get('store').findAll('zone');
      }

      const riderResponse = await this.get('riderService').fetchRiderInfoById(riderId); // sometimes do not have included so make sure we won't generate an error below

      riderResponse.included = riderResponse.included || [];
      (0, _lodash.remove)(riderResponse.included, function (ele) {
        return ele.type === 'service-need-type';
      }); // temporary. we will get rid of rider-with-subentity
      // endpoint does not return a proper json object

      const places = riderResponse.included.filter(obj => obj.type === 'place');
      const addresses = [];
      const locations = [];
      places.forEach(place => {
        const address = place.address;
        const location = place.location;
        const zoneName = Ember.isPresent(address) ? address.zoneName : null;
        const zoneType = Ember.isPresent(zoneName) ? zoneTypes.find(zone => zone.id === zoneName.id) : zoneTypes.get('firstObject');
        place.relationships = {};

        if (Ember.isPresent(address)) {
          place.relationships.address = {
            data: {
              id: address.id,
              type: 'address'
            }
          };
          addresses.push({
            id: address.id,
            type: 'address',
            attributes: {
              alias: address.alias,
              locality: address.locality,
              postalCode: address.postalCode,
              region: address.region,
              streetAddress: address.streetAddress,
              streetNumber: address.streetNumber,
              premise: address.premise,
              notes: address.notes,
              subLocality: address.subLocality,
              zName: zoneType.id
            },
            relationships: {
              place: {
                data: {
                  id: place.id,
                  type: 'place'
                }
              },
              zoneName: {
                data: {
                  id: zoneType.id,
                  type: 'zone-type'
                }
              }
            }
          });
        }

        if (Ember.isPresent(location)) {
          place.relationships.location = {
            data: {
              id: location.id,
              type: 'location'
            }
          };
          locations.push({
            id: location.id,
            type: 'location',
            attributes: {
              geoNode: location.geoNode,
              lat: location.lat,
              lng: location.lng
            },
            relationships: {
              place: {
                data: {
                  id: place.id,
                  type: 'place'
                }
              }
            }
          });
        }
      }); // override rider-eligibilities

      const riderEligibilities = riderResponse.included.filter(obj => obj.type === 'rider-eligibility');
      riderEligibilities.forEach(riderEligibility => {
        riderEligibility.attributes.origEligCateName = Ember.isPresent(riderEligibility.relationships.eligibilityCategoryName) ? riderEligibility.relationships.eligibilityCategoryName.data.id : null;
        riderEligibility.attributes.origEligCondName = Ember.isPresent(riderEligibility.relationships.eligibilityConditionName) ? riderEligibility.relationships.eligibilityConditionName.data.id : null;
      });
      riderResponse.included.push(...addresses);
      riderResponse.included.push(...locations);
      store.pushPayload(riderResponse);
      const riderRecord = store.peekRecord('rider', riderId);
      const thumbnailImage = riderRecord.get('thumbnailImage');
      this.updateThumbnailImage(thumbnailImage);
      this.set('selectedRider', riderRecord);
    },

    checkEligibility(riderEligibilities, tripDate) {
      const formattedTripDate = (0, _moment.default)(tripDate).format('YYYY/MM/DD');
      return riderEligibilities.some(riderEligibility => {
        const formattedFromDate = (0, _moment.default)(riderEligibility.from).format('YYYY/MM/DD');
        const formattedToDate = (0, _moment.default)(riderEligibility.to).format('YYYY/MM/DD');
        const eligibilityEndsAfterTripDate = (0, _moment.default)(formattedToDate).isAfter(formattedTripDate);
        const eligibilityEndOnSameDay = (0, _moment.default)(formattedToDate).isSame(formattedTripDate);
        const eligibilityEndsNextDay = (0, _moment.default)(formattedToDate).diff(formattedTripDate, 'days') === 1;
        const eligibilityStartsAfterCurrentDate = (0, _moment.default)(formattedFromDate).diff(formattedTripDate, 'days') > 1;
        return eligibilityEndsAfterTripDate || eligibilityEndOnSameDay || eligibilityEndsNextDay || eligibilityStartsAfterCurrentDate;
      });
    },

    showPopUpForDeniedAndArchived() {
      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        title: 'Rider Management',
        tip: 'Unable to change record status. Rider has eligibility that is currently active or eligibility that will be active in the future.',
        primaryActionText: 'OK',

        primaryAction() {
          tooltip.reset();
        }

      });
    },

    showPopUpForArchived(record, valuePath, value) {
      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        title: 'Rider Management',
        tip: 'Are you sure you want to set the record\'s status to Archived? This will prevent any trips from being booked for the rider and set the record to view-only.',
        primaryActionText: 'Confirm',
        primaryAction: () => {
          record.set(valuePath, value);
          this.set('isArchived', true);
          tooltip.reset();
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          tooltip.reset();
        }
      });
    },

    switchViewPage(page) {
      const tooltip = this.get('tooltip');
      const riderId = this.get('riderService').getLastAccessedRiderId();

      if (!this.get('editAPI.isNewBtnDisabled') && !this.get('editAPI.isEditing') && !riderId) {
        tooltip.pushConfirmation({
          title: 'Rider Management',
          tip: 'Please select a valid Rider.',
          primaryActionText: 'OK',

          primaryAction() {
            tooltip.reset();
          }

        });
        return false;
      } else if (this.get('editAPI.isNewBtnDisabled') && page === 'Notifications' || this.get('editAPI.isEditing')) {
        tooltip.pushConfirmation({
          title: 'Rider Management',
          tip: 'You are currently editing data. Please save or undo before proceeding.',
          primaryActionText: 'OK',

          primaryAction() {
            tooltip.reset();
          }

        });
        return false;
      }

      this.set('selectedRiderScreen', page);
      const selectedRiderScreen = this.get('selectedRiderScreen');

      if (selectedRiderScreen === 'Activity Log') {
        this.set('isActionButtonsDisabled', true);
      } else {
        this.set('isActionButtonsDisabled', false);
      }

      if (selectedRiderScreen === 'Eligibility') {
        this.set('isEligibilityScreenDisabled', true);
      } else {
        this.set('isEligibilityScreenDisabled', false);
      }

      if (this.get('isArchived')) {
        if (selectedRiderScreen === 'Certification' || selectedRiderScreen === 'Travel Needs' || selectedRiderScreen === 'Suspension') {
          this.set('isArchivedButtonsDisabled', true);
        } else {
          this.set('isArchivedButtonsDisabled', false);
        }
      } else {
        this.set('isArchivedButtonsDisabled', false);
      }
    },

    actions: {
      onUndoClick() {
        const tooltip = this.get('tooltip');
        const undoAction = this.get('editAPI.undoAction');
        tooltip.pushConfirmation({
          title: 'Rider Management',
          tip: 'Are you sure you want to undo these changes?',
          primaryActionText: 'Confirm',
          primaryAction: () => {
            undoAction();
            tooltip.reset();
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => {
            tooltip.reset();
          }
        });
      },

      async onRiderSearch() {
        const tooltip = this.get('tooltip');

        if (this.get('editAPI.isNewBtnDisabled') && this.get('selectedRiderScreen') !== 'Notifications' || this.get('editAPI.isEditing')) {
          tooltip.pushConfirmation({
            title: 'Rider Management',
            tip: 'You are currently editing data. Please save or undo before proceeding.',
            primaryActionText: 'OK',

            primaryAction() {
              tooltip.reset();
            }

          });
          return false;
        }

        this.set('showRiderModal', true);
      },

      onInputValueChange(record, valuePath, value) {
        record.set(valuePath, value);
      },

      async onRiderStatusChange(record, valuePath, value) {
        const riderChangeSet = this.get('riderChangeSet');
        const riderEligibilities = riderChangeSet.get('riderEligibilities').toArray();

        if (value === 'Temporary' || value === 'Approved') {
          record.set(valuePath, value.toLowerCase());
        }

        if (value === 'Denied') {
          if (riderEligibilities.length) {
            const riderEligibility = this.get('checkEligibility')(riderEligibilities, new Date());

            if (!riderEligibility) {
              record.set(valuePath, value.toLowerCase());
            } else {
              this.get('showPopUpForDeniedAndArchived')();
            }
          } else {
            record.set(valuePath, value.toLowerCase());
          }
        }

        if (value === 'Archived') {
          if (riderEligibilities.length) {
            const riderEligibility = this.get('checkEligibility')(riderEligibilities, new Date());

            if (!riderEligibility) {
              this.get('showPopUpForArchived')(record, valuePath, value);
            } else {
              this.get('showPopUpForDeniedAndArchived')();
            }
          } else {
            this.get('showPopUpForArchived')(record, valuePath, value);
          }
        }
      },

      onNavigationChange(option) {
        this.switchViewPage(option);
      }

    }
  });

  _exports.default = _default;
});