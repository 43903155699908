define("adept-iq/pods/components/side-drawer/perform-stop/drop/container/component", ["exports", "moment", "ember-concurrency", "adept-iq/utils/unwrapProxy", "adept-iq/errors/version-error", "adept-iq/models/stop-point", "adept-iq/mixins/version-check"], function (_exports, _moment, _emberConcurrency, _unwrapProxy, _versionError, _stopPoint, _versionCheck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_versionCheck.default, {
    activeContext: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    // passed in by side-drawer-container component
    stopPoint: null,
    onShrinkClick: null,
    onCloseClick: null,
    initStatus: null,
    arrive: Ember.computed.equal('stopPoint.status', 'Arrived'),
    depart: false,
    shouldArrive: true,
    trip: Ember.computed.readOnly('stopPoint.trip'),
    dispatchRoute: Ember.computed.readOnly('stopPoint.dispatchRoute'),
    route: Ember.computed.readOnly('dispatchRoute.route'),
    rider: Ember.computed.readOnly('trip.rider'),
    address: Ember.computed.readOnly('stopPoint.tripStop.segmentStop.place.address'),
    routeVehicleDriver: Ember.computed.readOnly('dispatchRoute.latestRouteVehicleDriver'),
    isArrived: Ember.computed.equal('stopPoint.computedStatus', _stopPoint.ARRIVE_STOP_STATUS),
    isArriveDisabled: Ember.computed.or('depart', 'isArrived'),
    driver: Ember.computed.readOnly('routeVehicleDriver.driver'),
    defaultActualArriveTime: Ember.computed('stopPoint.actualArriveTime', function () {
      const arriveTime = this.get('stopPoint.actualArriveTime');
      const lastContainerDepartTime = this.get('lastContainerDepartTime');
      const curDate = new Date();

      if (Ember.isNone(arriveTime) && !Ember.isNone(this.get('currentState.actualArriveTime'))) {
        return this.get('currentState.actualArriveTime');
      }

      if (Ember.isNone(arriveTime) && !Ember.isNone(lastContainerDepartTime)) {
        return lastContainerDepartTime;
      }

      if (Ember.isNone(arriveTime)) {
        return (0, _moment.default)(curDate).startOf('minute').toISOString();
      }

      return arriveTime;
    }),
    defaultActualDepartTime: Ember.computed('stopPoint.{actualDepartTime,actualArriveTime}', 'actualArriveTime', function () {
      const departTime = this.get('stopPoint.actualDepartTime');
      const lastContainerDepartTime = this.get('lastContainerDepartTime');
      const curDate = new Date();

      if (Ember.isNone(departTime) && !Ember.isNone(this.get('currentState.actualDepartTime'))) {
        return this.get('currentState.actualDepartTime');
      }

      if (Ember.isNone(departTime) && !Ember.isNone(lastContainerDepartTime)) {
        return lastContainerDepartTime;
      }

      if (Ember.isNone(departTime)) {
        return curDate;
      }

      return departTime;
    }),

    init() {
      this._super(...arguments);

      this.set('actualArriveTime', this.get('defaultActualArriveTime'));
      this.set('actualDepartTime', this.get('defaultActualDepartTime'));
      this.set('initStatus', this.get('stopPoint.status'));
    },

    didInsertElement() {
      const setStopContainerReference = this.get('setStopContainerReference');

      if (setStopContainerReference) {
        setStopContainerReference(this);
      }
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {
      const stopPoint = yield this.get('stopPoint');
      stopPoint.rollback();
      const isArrived = this.get('isArrived');
      stopPoint.set('odometer');
      this.set('arrive', isArrived);
      this.set('depart', false);
      this.set('errorText', '');
      this.set('actualArriveTime', this.get('defaultActualArriveTime'));
      this.set('actualDepartTime', this.get('defaultActualDepartTime'));
    }).keepLatest(),
    saveTask: (0, _emberConcurrency.task)(function* () {
      const store = this.get('store');
      const routeExecEventAdapter = store.adapterFor('route-exec-event');
      const stopPoint = (0, _unwrapProxy.unwrapProxy)(this.get('stopPoint'));
      const isArrived = this.get('isArrived'); // eslint-disable-next-line no-unused-vars

      let avlmVersion = null;
      stopPoint.set('odometer', null);

      if (this.get('arrive') && !isArrived) {
        if (!Ember.isEmpty(this.get('actualArriveTime'))) {
          stopPoint.set('actualArriveTime', this.get('actualArriveTime'));
        }

        avlmVersion = yield routeExecEventAdapter.createStopArriveEvent(stopPoint, avlmVersion);
      }

      if (this.get('depart')) {
        if (!Ember.isEmpty(this.get('actualDepartTime'))) {
          stopPoint.set('actualDepartTime', this.get('actualDepartTime'));
        }

        avlmVersion = yield routeExecEventAdapter.createStopDepartEvent(stopPoint, avlmVersion);
      }

      yield this.get('versionUpdateOperation').perform();
    }).drop(),
    actions: {
      onRefreshButtonClick() {
        this.set('currentState', null);
        this.get('refreshTask').perform();
      },

      onCancelButtonClick() {
        this.onCloseClick();
      },

      async onPerformButtonClick() {
        const lockRouteAction = this.get('lockRouteAction').bind(this);
        const unlockRouteAction = this.get('unlockRouteAction').bind(this);

        try {
          const tooltip = this.get('tooltip');
          const route = this.get('route');
          const stopPoint = this.get('stopPoint');
          const arrive = this.get('arrive');
          const depart = this.get('depart');
          const isArrived = this.get('isArrived');
          const dispatchRoute = (0, _unwrapProxy.unwrapProxy)(stopPoint.get('dispatchRoute'));

          if (!dispatchRoute) {
            tooltip.pushConfirmation({
              tip: 'Perform stop failed, Because stop had been modified by another request.',
              title: 'StopPoint record changed',
              hasOverlay: true,
              primaryActionText: 'Confirm',
              primaryAction: async () => {
                const close = this.onCloseClick.bind(this);
                tooltip.reset();
                return close();
              }
            });
            return;
          }

          const routeEndTime = route.get('dispatchRoute.plannedEndTime');
          const actualStartTime = route.get('dispatchRoute.computedStartTime');
          const arriveTime = (0, _moment.default)(this.get('actualArriveTime'));
          const departTime = (0, _moment.default)(this.get('actualDepartTime'));
          const routeOrdinal = stopPoint.get('routeOrdinal');
          const routeStops = route.get('dispatchRoute.orderedStopsWithGaragesAndBreaks');
          const lastPerformedStop = routeStops.filter(stop => stop.isPerformed).get('lastObject');
          const currentDateTime = (0, _moment.default)();
          const title = 'Perform Stop';
          let messageText = '';
          let tooltipText = ''; // Reset error text

          this.set('errorText');

          if ((0, _moment.default)(departTime).isBefore(arriveTime, 'minutes')) {
            this.set('actualDepartTime', this.get('actualArriveTime'));
          }

          if (arrive && depart) {
            messageText = 'Performed drop stop';
            tooltipText = 'Perform drop stop?';
          } else if (arrive && !isArrived) {
            messageText = 'Arrived drop stop';
            tooltipText = 'Arrive drop stop?';
          } else {
            this.set('errorText', 'Please select at least one option of arrive or depart to perform the stop.');
            return false;
          }

          if (arrive) {
            if (Ember.isEmpty(arriveTime)) {
              this.set('errorText', 'Please fill in a time for arrive time');
              return false;
            }
          }

          if (depart) {
            if (Ember.isEmpty(departTime)) {
              this.set('errorText', 'Please fill in a time for depart time');
              return false;
            }
          }

          if (arriveTime.isBefore(actualStartTime) || depart && departTime.isBefore(actualStartTime)) {
            this.set('errorText', "Performed Drop Stop Failed. The stop cannot be performed before the route's start time");
            return false;
          }

          if (arriveTime.isAfter(routeEndTime) || depart && departTime.isAfter(routeEndTime)) {
            this.get('notifications').warning('Stop performed after planned route end time.');
          }

          if (arriveTime.isAfter(currentDateTime) || depart && departTime.isAfter(currentDateTime)) {
            this.set('errorText', 'Performed Drop Stop Failed. The stop cannot be performed after the current time.');
            return false;
          }

          if (routeOrdinal > 0 && lastPerformedStop && !lastPerformedStop.get('isNoShow')) {
            if (arriveTime.isBefore(lastPerformedStop.get('actualDepartTime')) || depart && departTime.isBefore(lastPerformedStop.get('actualDepartTime'))) {
              this.set('errorText', 'Performed Drop Stop Failed. The stop cannot be performed before the previous Actual drop time.');
              return false;
            }
          } // Check dispatchRoute version first


          await this.get('versionCheckOperation').perform(this.onCloseClick.bind(this));
          await lockRouteAction();
          tooltip.pushConfirmation({
            tip: tooltipText,
            title,
            hasOverlay: true,
            primaryActionText: 'Confirm',
            primaryAction: () => {
              return this.get('saveTask').perform().then(() => {
                this.get('activeContext.topActiveContext').get('refreshTableContent').perform(['route']);
                this.get('notifications').success(`${messageText} successful`);
                return this.onCloseClick();
              }).catch(response => {
                // clear odometer in stop point model
                stopPoint.set('odometer');
                const payloadMessage = Ember.get(response, 'payload.message') ? JSON.parse(Ember.get(response, 'payload.message')) : null;
                messageText = messageText + ' failed';

                if (response.status === 400 || response.status === 500) {
                  if (payloadMessage.errorDetail.includes('cannot be in state arrived unless all previous stops are performed') || payloadMessage.errorDetail.includes('with status arrived cannot come after')) {
                    messageText = 'Performed Drop Stop Failed. You cannot perform this stop until previous stops are performed';
                  }

                  if (payloadMessage.errorDetail.includes('Execution version is different')) {
                    messageText = `PERFORM STOP FAILED. THE ROUTE (${this.get('route.name')}) HAS ALREADY BEEN MODIFIED WITH ANOTHER REQUEST`;
                  }
                } else if (response instanceof _versionError.default) {
                  messageText = `PERFORM STOP FAILED. THE ROUTE (${this.get('route.name')}) HAS ALREADY BEEN MODIFIED WITH ANOTHER REQUEST`;
                }

                this.get('notifications').warning(`${messageText}`);
              }).finally(async () => {
                tooltip.reset();
                await unlockRouteAction(0);
                this.get('workspace').requestWootricSurvey('on_stopPerform');
                return this.onCloseClick();
              });
            },
            secondaryActionText: 'Cancel',
            secondaryAction: async () => {
              await unlockRouteAction(0);
              return tooltip.reset();
            },
            closeAction: async () => {
              await unlockRouteAction(0);
            }
          });
        } catch (e) {
          await unlockRouteAction();
        }
      }

    }
  });

  _exports.default = _default;
});