define("adept-iq/pods/components/iq-widgets/new-booking-form/booking-details/component", ["exports", "moment", "lodash", "ember-concurrency", "adept-iq/utils/unwrapProxy", "adept-iq/config/mapped-status", "adept-iq/models/trip", "adept-iq/config/environment"], function (_exports, _moment, _lodash, _emberConcurrency, _unwrapProxy, _mappedStatus, _trip, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const REFUSED_STATUS = 'refused';
  const PRIMARY_SCHEDULE_TYPE = 'primary';

  var _default = Ember.Component.extend({
    classNames: ['booking-details'],
    editModal: Ember.inject.service(),
    apiContext: Ember.inject.service(),
    booking: Ember.inject.service(),
    workspace: Ember.inject.service(),
    store: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    tripDetails: null,
    returnTripDetails: null,
    returnTripDetailsVisible: false,
    returnTripIndex: null,
    isEveryLegHasPromiseTime: true,
    locationAddressMapper: null,
    isNotPromiseTime: Ember.computed('booking.isNotPromiseTime', function () {
      const isNotPromiseTime = this.get('booking.isNotPromiseTime');
      return isNotPromiseTime;
    }),

    async init() {
      this._super(...arguments);

      this.set('locationAddressMapper', {});
      this.onSelectedTravelModeChange = this.onSelectedTravelModeChange.bind(this);
      this.closeBookingAcceptScreen = this.closeBookingAcceptScreen.bind(this);
      await this.buildTripAndReturnTripDetails.perform();

      if (!Ember.isEmpty(this.get('returnTripBooking'))) {
        this.set('returnTripDetailsVisible', true);
      }

      this.get('workspace').set('isGlobalSpinnerVisible', false);
    },

    willDestroyElement() {
      this.set('booking.tempBookingInfo', {});
      this.set('booking.tripDetails', []);

      this._super(...arguments);
    },

    async saveBooking(status) {
      const isEditMode = this.get('isEditMode');
      this.get('workspace').set('isGlobalSpinnerVisible', true);

      try {
        const tempBookingId = this.get('booking.tempBookingInfo.tempBookingId');
        const riderId = this.get('riderRecord.id');

        if (status === REFUSED_STATUS) {
          const tripCancelJobs = await this.get('booking').deleteBooking(tempBookingId, 'refuse', isEditMode, riderId);
          const asynOperationInfoArray = tripCancelJobs ? tripCancelJobs.tripCancelJobs : [];

          for (const tripCancelInfo of asynOperationInfoArray) {
            if (tripCancelInfo) {
              await this.get('booking').callScheduleAsyncOperation(tripCancelInfo);
            }
          }
        } // if edit booking delete original booking trips


        if (isEditMode) {
          const bookingID = this.get('booking.activeBooking.id');
          const tripCancelJobs = await this.get('booking').deleteBooking(bookingID, 'refuse', isEditMode, riderId);
          const asynOperationInfoArray = tripCancelJobs ? tripCancelJobs.tripCancelJobs : [];

          for (const tripCancelInfo of asynOperationInfoArray) {
            if (tripCancelInfo) {
              await this.get('booking').callScheduleAsyncOperation(tripCancelInfo);
            }
          }

          await this.get('booking').deleteTrips(bookingID);
          await this.get('booking').saveBooking(isEditMode);
        } else {
          let createRefusedBooking = false;

          if (status === REFUSED_STATUS) {
            createRefusedBooking = true;
          }

          await this.get('booking').saveBookingStatus(tempBookingId, createRefusedBooking);
        }

        await this.get('booking').setSelectedRiders(isEditMode);
        await this.loadBookingsForSelectedExternalRiders();
        this.get('onClose')();
        this.editModal.close();
        let message = isEditMode ? 'Booking updated Successfully' : 'New Booking successfully saved!';

        if (status === REFUSED_STATUS) {
          message = 'Booking was saved but it will not be scheduled.';
          this.get('notifications').warning(message);
        } else {
          this.get('notifications').success(message);
          this.set('booking.activeBooking.status', 'unscheduled');
        }
      } catch (err) {
        const bookingFailedMessage = isEditMode ? 'Failed to update Booking' : 'Failed to create Booking';
        const errorMsg = err.isLoadTripError ? err.message : bookingFailedMessage;
        this.get('notifications').warning(errorMsg);
        this.get('onClose')();
        this.editModal.close();
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      }
    },

    /**
     * update temp booking with  status 'scheduled' and  update  legs and segments..
     * then call schedule service to create trips
     * @returns {Promise<void>}
     */
    async acceptBooking() {
      const isEditMode = this.get('isEditMode');

      try {
        const {
          promiseTimeAsyncOperationInfoArray,
          booking
        } = await this.get('booking').acceptBooking(isEditMode);
        const isItpBooking = this.get('itpRequestResults').length;
        const bookingId = booking.data.id;

        if (isItpBooking) {
          const bookingByID = `eq(id,(${bookingId}))&include=group,legs,legs.segments,legs.legTravelNeeds,legs.segments.pick,legs.segments.drop,subscription`;
          const bookingRecord = await this.get('store').query('booking', {
            filter: bookingByID
          });
          await this.get('booking').saveSegmentStopPhoneNumber(bookingRecord);
          await this.get('booking').adjustItpBookingLegOrdinals();
        } // There is a little delay in creating trips in scheduling service.. So we need to call schedule service to wait until trips are created..
        // After this call, we should  call loadBookingsForSelectedExternalRiders api to get trips for newly created scheduled  booking


        if (promiseTimeAsyncOperationInfoArray) {
          for (const promiseTimeAsyncOperationInfo of promiseTimeAsyncOperationInfoArray) {
            try {
              // Primary-booking patch doesn't use the async operation and Primary-booking is created the trip in Primary-booking patch
              if (isEditMode && !Ember.isEmpty(promiseTimeAsyncOperationInfo.body)) {
                const {
                  isJobSuccess,
                  state,
                  results
                } = await this.get('booking').callScheduleAsyncOperation(promiseTimeAsyncOperationInfo.body);

                if (!isJobSuccess) {
                  // Regarding NYAAR-17874 - If SES or SS fails with any other error, the booking status should be set to Failed.
                  await this.get('booking').asyncOperationStateSuccess(booking, state, results);
                }
              }
            } catch (err) {
              // eslint-disable-next-line no-console
              console.error(err);
              await this.get('booking').updateBookingStatus(booking.data.id, _mappedStatus.convertBookingStatus.failed);
            }
          }
        }

        await this.get('booking').setSelectedRiders(isEditMode);
        await this.loadBookingsForSelectedExternalRiders();
      } catch (err) {
        const bookingFailedMessage = isEditMode ? `Failed to update Booking ${err.message}` : `Failed to create Booking  ${err.message}`;
        const errorMsg = err.isLoadTripError ? err.message : bookingFailedMessage;
        this.get('notifications').warning(errorMsg);
        this.get('onClose')();
        this.editModal.close();
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      }
    },

    checkAndSetStatus(bookingStatus) {
      if (bookingStatus) {
        this.set('booking.activeBooking.status', bookingStatus);
      } else {
        this.set('booking.activeBooking.status', 'unscheduled');
      }
    },

    buildTripAndReturnTripDetails: (0, _emberConcurrency.task)(function* () {
      const TIME_FORMAT = _environment.default.dateTimeFormat.timeMoment;
      const DATE_TIME_FORMAT = _environment.default.dateTimeFormat.dateTimeMoment;
      const DATE_FORMAT = _environment.default.dateTimeFormat.dateMoment;
      const itpRequestResults = this.get('itpRequestResults') || [];
      const tripRequestDetails = this.get('tripLegs').map(tb => {
        const reqTime = (0, _moment.default)(tb.get('requestTime'));
        return {
          tripDate: reqTime.format(DATE_FORMAT),
          requestTime: tb.get('requestTime'),
          formattedRequestTime: reqTime.format(TIME_FORMAT),
          promiseTime: '',
          segments: tb.get('segments').map(segment => {
            return {
              pickAddress: segment.get('pick.place.address.placeNameAddress'),
              dropAddress: segment.get('drop.place.address.placeNameAddress'),
              tripRequestPickAddress: segment.get('pick.place.address.simpleAddress'),
              tripRequestDropAddress: segment.get('drop.place.address.simpleAddress'),
              promiseTime: (0, _moment.default)(segment.get('formattedPromiseTime')).format(DATE_TIME_FORMAT),
              startTime: (0, _moment.default)(segment.get('tripStartTime')).format(TIME_FORMAT),
              arriveTime: (0, _moment.default)(segment.get('tripEndTime')).format(TIME_FORMAT)
            };
          })
        };
      });
      const returnTripDetails = this.get('returnTripBooking').map(rtb => {
        const reqTime = (0, _moment.default)(rtb.get('requestTime'));
        return {
          tripDate: reqTime.format(DATE_FORMAT),
          requestTime: this.get('booking').getPromiseTime(this.get('returnTripIndex'), rtb),
          formattedRequestTime: reqTime.format(TIME_FORMAT),
          promiseTime: '',
          segments: rtb.get('segments').map(segment => {
            return {
              pickAddress: segment.get('pick.place.address.placeNameAddress'),
              dropAddress: segment.get('drop.place.address.placeNameAddress'),
              tripRequestPickAddress: segment.get('pick.place.address.simpleAddress'),
              tripRequestDropAddress: segment.get('drop.place.address.simpleAddress'),
              promiseTime: (0, _moment.default)(segment.get('formattedPromiseTime')).format(DATE_TIME_FORMAT),
              startTime: (0, _moment.default)(segment.get('tripStartTime')).format(TIME_FORMAT),
              arriveTime: (0, _moment.default)(segment.get('tripEndTime')).format(TIME_FORMAT)
            };
          })
        };
      });
      yield this.buildAddressMapper.perform(itpRequestResults);
      this.setItp([...tripRequestDetails, ...returnTripDetails], itpRequestResults);
      this.set('tripDetails', tripRequestDetails);
      this.set('returnTripDetails', returnTripDetails);
      this.set('returnTripIndex', tripRequestDetails.length);
      this.get('booking').createOverlapActivityLogs(this.get('booking.tempBookingInfo.tempBookingRecord.trips'));
    }),
    buildAddressMapper: (0, _emberConcurrency.task)(function* (itpRequestResults) {
      const addressMapper = {};
      const mappedLocationsPromises = itpRequestResults.map(async itpResult => {
        const locationPromises = itpResult.result.segments.map(async segment => {
          const locationQuery = `or(and(eq(lat,'${segment.start.coordinates.lat}'),eq(lng,'${segment.start.coordinates.lng}')),and(eq(lat,'${segment.end.coordinates.lat}'),eq(lng,'${segment.end.coordinates.lng}')))`;
          return await this.store.query('location', {
            filter: locationQuery
          });
        });
        return await Promise.all(locationPromises);
      });
      const mappedLocations = yield Promise.all(mappedLocationsPromises);
      const allLocations = (0, _lodash.flattenDeep)(mappedLocations).map(loc => loc.toArray());
      const flattenLocations = (0, _lodash.uniq)((0, _lodash.flattenDeep)(allLocations));
      const mappedPlacesPromises = flattenLocations.map(async loc => {
        const placeQuery = `in(locationId,('${loc.get('id')}'))&include=address,location`;
        return await this.store.query('place', {
          filter: placeQuery
        });
      });
      const allPlaces = yield Promise.all(mappedPlacesPromises);
      const flattenPlaces = (0, _lodash.uniq)((0, _lodash.flattenDeep)(allPlaces.map(place => place.toArray())));
      flattenPlaces.forEach(place => {
        const location = place.get('location');
        const locationKey = [location.get('lat'), location.get('lng')].join(',');
        addressMapper[locationKey] = (0, _unwrapProxy.unwrapProxy)(place.get('address'));
      });
      this.set('locationAddressMapper', addressMapper);
    }),

    /**
     * trips also contain return trip details
     * @param trips
     * @param itpRequestResults
     */
    setItp(trips, itpRequestResults) {
      const itpInfoMap = {};
      itpRequestResults.forEach(itpInfo => {
        itpInfoMap[itpInfo.legIndex] = itpInfo;
      });
      trips.forEach((trip, index) => {
        const {
          result: itpRequestResult
        } = itpInfoMap[index] || {};
        const itinerarySegments = itpRequestResult && itpRequestResult.segments || [];
        this.setAddressForSegments(itinerarySegments);
        trip.itinerarySegments = itinerarySegments;
      });
    },

    setAddressForSegments(itinerarySegments) {
      const locationAddressMapper = this.get('locationAddressMapper');

      if (!Ember.isEmpty(locationAddressMapper)) {
        itinerarySegments.forEach(segment => {
          const startLocationCoOrdinateKey = [segment.start.coordinates.lat, segment.start.coordinates.lng].join(',');
          const endLocationCoOrdinateKey = [segment.end.coordinates.lat, segment.end.coordinates.lng].join(',');
          segment.start.address = locationAddressMapper[startLocationCoOrdinateKey] ? locationAddressMapper[startLocationCoOrdinateKey].simpleAddress : '';
          segment.end.address = locationAddressMapper[endLocationCoOrdinateKey] ? locationAddressMapper[endLocationCoOrdinateKey].simpleAddress : '';
        });
      }
    },

    onSelectedTravelModeChange(legIndex, travelModeConfig) {
      const travelModeName = travelModeConfig.get('name').toLowerCase();
      this.get('booking').setSelectedTravelMode(legIndex, travelModeName);
      this.get('booking').setSelectedTravelModeInBooking(legIndex, travelModeName);
    },

    async loadBookingsForSelectedExternalRiders() {
      try {
        const [bookings, trips] = await this.get('apiContext').loadBookingsForSelectedExternalRiders();
        this.set('booking.currentBookings', bookings);
        this.set('booking.currentTrips', trips);
      } catch (e) {
        const message = 'Booking is saved. But failed to load bookings and trips'; // eslint-disable-next-line no-throw-literal

        throw {
          message: message,
          isLoadTripError: true
        };
      }
    },

    /**
     * phone Number is class
     * segment is Segment class
     * numberType is pick and drop "String"
     * @param phoneNumber
     * @param segment
     * @param numberType
     */
    createPhoneRecordAfterResetPhoneNumber(phoneNumber, segment, numberType) {
      const phoneNumberAttr = phoneNumber.toJSON();
      const activeLegPhoneNumber = [];
      const segmentStopPhoneAttrRecord = this.get('store').createRecord('segment-stop-phone-number', phoneNumberAttr);
      const segmentStopPhoneNumberId = segment.get(`${numberType}.phoneNumbers.firstObject.id`);
      const segmentStopPhoneRecord = this.get('store').peekRecord('segment-stop-phone-number', segmentStopPhoneNumberId);
      const filterSegmentStopPickPhoneId = this.get('booking.segmentStopPhoneNumberIds').filter(phone => {
        return phone.get('id') !== segmentStopPhoneRecord.get('id');
      });
      this.get('store').unloadRecord(segmentStopPhoneRecord);
      this.set('booking.segmentStopPhoneNumberIds', filterSegmentStopPickPhoneId);
      activeLegPhoneNumber.pushObject(segmentStopPhoneAttrRecord);
      segment.set(`${numberType}.phoneNumbers`, activeLegPhoneNumber);
    },

    resetModelAttribute() {
      this.get('booking.activeBooking.legs').forEach(leg => {
        if (!leg) {
          return;
        }

        const pickAddressId = (0, _unwrapProxy.unwrapProxy)(leg.get('segments.firstObject.pick.place.address.id'));
        const dropAddressId = (0, _unwrapProxy.unwrapProxy)(leg.get('segments.firstObject.drop.place.address.id'));

        if (pickAddressId) {
          const pickAddressRecord = this.get('store').peekRecord('address', pickAddressId);
          pickAddressRecord.rollbackAttributes();
        }

        if (dropAddressId) {
          const dropAddressRecord = this.get('store').peekRecord('address', dropAddressId);
          dropAddressRecord.rollbackAttributes();
        }
      });
    },

    //NYAAR-19188 this method used for fixing the segmentStop pick and drop Phone No field empty from sidePanel after while when we close the Booking details and then click on Tick icon.
    resetSegmentStopPhoneNumber() {
      this.get('booking.activeBooking.legs').forEach(leg => {
        const segments = leg.get('segments');
        segments.forEach(segment => {
          const segmentPickPhoneNumber = segment.get('pick.phoneNumbers.firstObject');
          const segmentDropPhoneNumber = segment.get('drop.phoneNumbers.firstObject');

          if (segmentPickPhoneNumber) {
            this.createPhoneRecordAfterResetPhoneNumber(segmentPickPhoneNumber, segment, 'pick');
          }

          if (segmentDropPhoneNumber) {
            this.createPhoneRecordAfterResetPhoneNumber(segmentDropPhoneNumber, segment, 'drop');
          }
        });
      });
    },

    /**
     * for checking every trip has scheduled status and schedule type is primary and every trip is assigned with same route
     * @param {*} bookingId
     * @returns boolean
     */
    async checkEveryTripAreAssignedWithSameRoute(bookingId) {
      const tripQuery = `in(booking,('${bookingId}'))&include=schedule,route`;
      const trips = await this.get('store').query('trip', {
        filter: tripQuery
      });
      const filteredTrips = trips.length ? trips.toArray().sortBy('id').reverse().uniqBy('segment.id') : [];

      if (filteredTrips.length) {
        const isEveryTripIsInPrimarySchedule = filteredTrips.every(trip => trip.get('schedule.type') === PRIMARY_SCHEDULE_TYPE);
        const isEveryTripsHasRoute = filteredTrips.every(trip => trip.get('route.id'));
        const isEveryTripIsScheduled = filteredTrips.every(trip => trip.get('status') === _trip.TRIP_SCHEDULED_STATUS.toLowerCase());

        if (isEveryTripIsInPrimarySchedule && isEveryTripsHasRoute && isEveryTripIsScheduled) {
          const routeId = filteredTrips.get('firstObject.route.id');
          const isEveryTripHasSameRoute = filteredTrips.every(trip => trip.get('route.id') === routeId);
          return isEveryTripHasSameRoute;
        }
      }

      return true;
    },

    showTripsAreAssingnedWithDiffRoutesWarning() {
      this.get('tooltip').pushConfirmation({
        title: 'Create Booking',
        tip: 'Not all trips in the group booking could be scheduled to the same route.',
        primaryActionText: 'OK',
        primaryAction: this.closeBookingAcceptScreen,
        hasOverlay: true,
        closeAction: this.closeBookingAcceptScreen
      });
    },

    //closing the booking accept screen
    closeBookingAcceptScreen() {
      const isEditMode = this.get('isEditMode');
      this.editModal.close();
      this.get('onClose')();

      if (isEditMode) {
        this.get('notifications').success('Booking updated Successfully');
      } else {
        const currentBooking = this.get('booking.currentBookings.firstObject');
        const currentBookingStatus = Ember.isPresent(currentBooking) ? currentBooking.get('status') : '';

        if (currentBookingStatus === 'failed') {
          this.get('notifications').warning('Booking saved, but some or all trips could not be scheduled. Please review and re-submit the booking');
        } else {
          this.get('notifications').success('New Booking successfully saved!');
        }
      }
    },

    actions: {
      onClickOverlay() {//this.get('onClose')();
      },

      async closeDialog() {
        const isEditMode = this.get('isEditMode');
        this.resetSegmentStopPhoneNumber();
        const bookingService = this.get('booking');
        const bookingId = bookingService.get('tempBookingInfo.tempBookingId');

        if (bookingService.get('tempBookingInfo.tempBookingId')) {
          try {
            const asynOperationInfoArray = await bookingService.deleteTemporaryBooking('x', isEditMode);
            this.get('workspace').set('isGlobalSpinnerVisible', true);

            for (const tripCancelInfo of asynOperationInfoArray) {
              if (tripCancelInfo) {
                await this.get('booking').callScheduleAsyncOperation(tripCancelInfo);
              }
            }

            await bookingService.deleteTrips(bookingId); // Reg - NYAAR-18800

            this.get('booking').createAndSetServiceNeedInLeg();
          } catch (err) {
            const errorMsg = err.isLoadTripError ? err.message : 'Failed to Delete Booking/Trips';
            this.get('notifications').warning(errorMsg);
          } finally {
            this.get('onClose')(true);
            this.get('workspace').set('isGlobalSpinnerVisible', false);
          }
        }
      },

      async onRefuseClick() {
        this.checkAndSetStatus(REFUSED_STATUS);
        this.saveBooking(REFUSED_STATUS); //roll back address record(NYAAR-18629)

        this.resetModelAttribute();
      },

      async onAcceptClick() {
        // *** keeping this for reference since the AIQ-3956 re-factor
        //roll back address record(NYAAR-18629)
        // this.resetModelAttribute();
        // this.get('workspace').set('isGlobalSpinnerVisible', true);
        // const bookingService = this.get('booking');
        // const isEditMode = this.get('isEditMode');
        // const bookingId = bookingService.get('tempBookingInfo.tempBookingId');
        // const booking = this.get('store').peekRecord('booking', bookingId);
        // const isGroupBooking = booking.get('isGroupBooking');
        // if taxi mode is selected, cancel trip in 'booking' schedule
        // if (bookingService.isTaxiModeSelected()) {
        //   try {
        //     const tripCancelJobs = await bookingService.deleteBooking(bookingId, 'taxi');
        //     const asynOperationInfoArray =  tripCancelJobs ? tripCancelJobs.tripCancelJobs : [];
        //     if (asynOperationInfoArray) {
        //       for (const tripCancelInfo of asynOperationInfoArray) {
        //         if (tripCancelInfo) {
        //           await this.get('booking').callScheduleAsyncOperation(tripCancelInfo);
        //         }
        //       }
        //     }
        //   } catch (err) {
        //     const errorMsg = err.isLoadTripError ? err.message : 'Failed to Cancel Trips';
        //     this.get('workspace').set('isGlobalSpinnerVisible', false);
        //     this.get('notifications').warning(errorMsg);
        //   }
        // }
        // call booking with promise time api if booking has promise time and hasParaTransitOrItpFeederModeSelected
        // In case of  Service Mode - Paratransit and  ITP  iternary type - 2 means This is a Feeder  itinerary, we should  call bookingWithPromiseTime .
        // if (bookingService.get('enableNewPromiseTimeApi')) {
        //   this.checkAndSetStatus('scheduled');
        //   if (!this.get('isEveryLegHasPromiseTime') && !bookingService.isTaxiModeSelected()) {
        //     this.checkAndSetStatus();
        //   }
        //   await this.acceptBooking();
        // }
        // if (isGroupBooking) {
        //   const currentBookingId = isEditMode ? this.get('booking.activeBooking.id') : bookingId;
        //   const isEveryTripAreAssignedWithSameRoute = await this.checkEveryTripAreAssignedWithSameRoute(currentBookingId);
        //   //when all trips are not assigned with same route it will show a popup
        //   if (!isEveryTripAreAssignedWithSameRoute) {
        //     this.showTripsAreAssingnedWithDiffRoutesWarning();
        //     return;
        //   }
        // }
        this.closeBookingAcceptScreen();
      },

      setIsEveryLegHasPromiseTimeProp(value) {
        this.set('isEveryLegHasPromiseTime', value);
      }

    }
  });

  _exports.default = _default;
});