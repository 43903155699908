define("adept-iq/adapters/route-exec-event", ["exports", "adept-iq/adapters/-avlm-avlmService", "adept-iq/errors/version-error"], function (_exports, _avlmAvlmService, _versionError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _avlmAvlmService.default.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    // TODO: remove this if possible
    routeExecEvent: Ember.inject.service('routeexecevent'),

    query(store, type, query) {
      const session = this.get('session');
      var url = this.buildURL(type.modelName, null, null, 'query', query);
      return this.get('ajax').post(url, {
        method: 'GET',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: query
      });
    },

    createStopArriveEvent(stopPoint, avlmVersion, activityLog) {
      const routeExecEvent = this.get('routeExecEvent');
      const data = routeExecEvent.stopArriveData();
      const preparedData = routeExecEvent.prepareStopArriveData(data, stopPoint, activityLog, avlmVersion);
      return this._postRouteExecEvent(preparedData);
    },

    createStopDepartEvent(stopPoint, avlmVersion) {
      const routeExecEvent = this.get('routeExecEvent');
      const data = routeExecEvent.stopDepartData();
      const preparedData = routeExecEvent.prepareStopDepartData(data, stopPoint, avlmVersion);
      return this._postRouteExecEvent(preparedData);
    },

    createAcceptEvent(stopPoint, avlmVersion, noShowTime, noShowNotes, activityLog) {
      const routeExecEvent = this.get('routeExecEvent');
      const data = routeExecEvent.stopAcceptData();
      const preparedData = routeExecEvent.prepareStopAcceptData(data, stopPoint, noShowTime, noShowNotes, activityLog, avlmVersion);
      return this._postRouteExecEvent(preparedData);
    },

    createStopNoShowEvent(stopPoint, avlmVersion) {
      const routeExecEvent = this.get('routeExecEvent');
      const data = routeExecEvent.noShowData();
      const preparedData = routeExecEvent.prepareStopNoShowData(data, stopPoint, avlmVersion);
      return this._postRouteExecEvent(preparedData);
    },

    createTripNoShowRequestEvent(trip, noShowTypeDecision, avlmVersion) {
      const routeExecEvent = this.get('routeExecEvent');
      const data = routeExecEvent.noShowData();
      const preparedData = routeExecEvent.prepareTripNoShowRequestData(data, trip, noShowTypeDecision, avlmVersion);
      return this._postRouteExecEvent(preparedData);
    },

    createUndoNoShowEvent(stopPoint, noShowTime, noShowNotes, avlmVersion) {
      const routeExecEvent = this.get('routeExecEvent');
      const undoNoShowData = routeExecEvent.undoNoShowData();
      const preparedUndoRouteStartData = routeExecEvent.prepareUndoNoShowData(undoNoShowData, stopPoint, noShowTime, noShowNotes, avlmVersion);
      return this._postRouteExecEvent(preparedUndoRouteStartData);
    },

    createStartRouteEvent(route, avlmVersion) {
      const routeExecEvent = this.get('routeExecEvent');
      const routeStartData = routeExecEvent.routeStartData();
      const preparedRouteStartData = routeExecEvent.prepareRouteStartData(routeStartData, route, avlmVersion);
      return this._postRouteExecEvent(preparedRouteStartData);
    },

    createUndoStartRouteEvent(route, avlmVersion) {
      const routeExecEvent = this.get('routeExecEvent');
      const undoRouteStartData = routeExecEvent.undoRouteStartData();
      const preparedUndoRouteStartData = routeExecEvent.prepareUndoRouteStartData(undoRouteStartData, route, avlmVersion);
      return this._postRouteExecEvent(preparedUndoRouteStartData);
    },

    createEndRouteEvent(route, avlmVersion) {
      const routeEndData = this.get('routeExecEvent').routeEndData();
      const preparedRouteEndData = this.get('routeExecEvent').prepareRouteEndData(routeEndData, route, avlmVersion);
      return this._postRouteExecEvent(preparedRouteEndData);
    },

    createUndoEndRouteEvent(route, avlmVersion) {
      const routeExecEvent = this.get('routeExecEvent');
      const undoRouteEndData = routeExecEvent.undoRouteEndData();
      const prepareUndoRouteEndData = routeExecEvent.prepareUndoRouteEndData(undoRouteEndData, route, avlmVersion);
      return this._postRouteExecEvent(prepareUndoRouteEndData);
    },

    createCompleteRouteEvent(route, avlmVersion) {
      const routeCompleteData = this.get('routeExecEvent').routeCompleteData();
      const preparedRouteCompleteData = this.get('routeExecEvent').prepareRouteCompleteData(routeCompleteData, route, avlmVersion);
      return this._postRouteExecEvent(preparedRouteCompleteData);
    },

    createStartBreakEvent(stopPoint, avlmVersion) {
      const routeExecEvent = this.get('routeExecEvent');
      const startBreakData = routeExecEvent.onBreakData();
      const preparedStartBreakData = routeExecEvent.prepareStartBreakData(startBreakData, stopPoint, avlmVersion);
      return this._postRouteExecEvent(preparedStartBreakData);
    },

    createEndBreakEvent(stopPoint, avlmVersion) {
      const routeExecEvent = this.get('routeExecEvent');
      const endBreakData = routeExecEvent.offBreakData();
      const preparedStartBreakData = routeExecEvent.prepareEndBreakData(endBreakData, stopPoint, avlmVersion);
      return this._postRouteExecEvent(preparedStartBreakData);
    },

    createAcceptBreakEvent(stopPoint, noShowTime, noShowNotes, avlmVersion) {
      const routeExecEvent = this.get('routeExecEvent');
      const data = routeExecEvent.breakAcceptData();
      const preparedData = routeExecEvent.prepareBreakAcceptData(data, stopPoint, noShowTime, noShowNotes, avlmVersion);
      return this._postRouteExecEvent(preparedData);
    },

    async _postRouteExecEvent(data) {
      const session = this.get('session');
      const uri = this.buildURL('route-exec-event');

      if (!data.data.attributes.routeVersion) {
        throw new _versionError.default('missing avlmVersion');
      }

      try {
        await this.get('ajax').post(uri, {
          method: 'POST',
          contentType: 'application/json',
          headers: {
            'Authorization': `Bearer ${session.data.authenticated.token}`
          },
          data
        }); // return avlmVersion magic word so next perform won't fail

        return '1-c';
      } catch (err) {
        throw err;
      }
    }

  });

  _exports.default = _default;
});