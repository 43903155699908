define("adept-iq/pods/components/iq-widgets/new-booking-form/leg-form/component", ["exports", "moment", "adept-iq/utils/unwrapProxy", "lodash", "adept-iq/utils/workspace-availability", "adept-iq/config/mapped-permIds", "adept-iq/models/rider-phone-number"], function (_exports, _moment, _unwrapProxy, _lodash, _workspaceAvailability, _mappedPermIds, _riderPhoneNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CERTIFICATION_LOCATION_PLACE_CATEGORY = 'certification_location';
  const CERT_FARE_CATEGORY = 'CERT';
  const GUEST_FARE_CATEGORY = 'Guest';
  const DISPLAY_NAME = 'Display Name';
  const PHONE_NUMBER_PLACEHOLDER = 'Phone Number';
  const SCHEDULE_TYPE = 'primary';

  var _default = Ember.Component.extend({
    classNames: ['leg-form'],
    anchorTypeOptions: null,
    serviceTypeOptions: null,
    store: Ember.inject.service(),
    geocode: Ember.inject.service(),
    booking: Ember.inject.service(),
    editModal: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    fareCalc: Ember.inject.service(),
    maxDate: null,
    minDate: null,
    isFirstLeg: false,
    markerType: null,
    disablePick: false,
    assesmentCenterList: null,
    rideshareTypeOptions: null,
    editRideshareBookingGranted: false,
    allowFundingAgency: false,
    legFareCategory: null,
    certFareCategory: null,
    phoneNumberPlaceHolder: PHONE_NUMBER_PLACEHOLDER,
    disableFareField: false,
    fareCategoryOptions: null,
    isFieldDisabled: Ember.computed('disableRequestDate', 'isEditingUnscheduledBooking', function () {
      return this.get('disableRequestDate') || this.get('booking.isEditingUnscheduledBooking');
    }),
    isNotFirstLeg: Ember.computed('isFirstLeg', function () {
      return !this.get('isFirstLeg');
    }),
    canShowRemoveButton: Ember.computed('isFirstLeg', 'leg.purpose', 'isReturnTrip', function () {
      return this.get('isReturnTrip') || this.get('isNotFirstLeg');
    }),
    legFare: Ember.computed('booking.{isAllLegs,activeBooking.legs.firstObject.segments.firstObject.fare}', 'leg.segments.firstObject.fare', function () {
      const isAllLegs = this.get('booking.isAllLegs');
      if (isAllLegs) return this.get('booking.activeBooking.legs.firstObject.segments.firstObject.fare');
      return this.get('leg.segments.firstObject.fare');
    }),

    // we should only show upto day  after tomorrow for booking
    setMinMaxDate() {
      const userBookingPermissionDetails = this.get('booking').getUserBookingPermissionDetail();
      const isSameDayBookingPermGranted = userBookingPermissionDetails.isSameDayBookingPermGranted;
      const isFutureDayBookingPermGranted = userBookingPermissionDetails.isFutureDayBookingPermGranted;
      const futureBookingTimeline = this.store.peekRecord('cs-config-item', 'config-Book_Trips/Future_Booking_Timeline');
      const futureBookingTimelineInt = parseInt(futureBookingTimeline.get('value'), 10);
      const allowSameDayBooking = this.store.peekRecord('cs-config-item', 'config-Book_Trips/Allow_Same_Day_booking'); // minimum date is current day or next day
      // maximum is current day plus Future_Booking_Timeline

      this.set('minDate', allowSameDayBooking.get('value') ? (0, _moment.default)().startOf('day').toDate() : (0, _moment.default)().startOf('day').add(1, 'days').toDate());
      this.set('maxDate', futureBookingTimelineInt ? (0, _moment.default)().endOf('day').add(futureBookingTimelineInt, 'days').toDate() : (0, _moment.default)().endOf('day').toDate()); // if user has same day booking permission then maxDate should be end with current day

      if (isSameDayBookingPermGranted && !isFutureDayBookingPermGranted) {
        this.set('maxDate', (0, _moment.default)().endOf('day').toDate());
      } // if user not has  same day booking permission then minDate should be start with next day


      if (!isSameDayBookingPermGranted && isFutureDayBookingPermGranted) {
        this.set('minDate', (0, _moment.default)().startOf('day').add(1, 'days').toDate());
      }

      this.set('booking.maxBookingDate', this.get('maxDate'));
    },

    anchorObserver: Ember.observer('leg.anchor', 'legIndex', function () {
      if (this.get('leg.anchor') === 'Appt' || this.get('legIndex') === 0) {
        this.set('isFirstLeg', true);
      } else {
        this.set('isFirstLeg', false);
      }
    }),
    fundingAgencyOptions: Ember.computed('leg.requestTime', async function () {
      const fundingAgencyOptions = [];
      const riderId = this.get('riderRecord.id');
      const requestLegTime = (0, _moment.default)(this.get('leg.requestTime'));
      const localRiderRecord = await this.store.findRecord('rider', riderId);
      const riderFundings = localRiderRecord.get('riderFundings');
      riderFundings.forEach(riderFunding => {
        const startDate = (0, _moment.default)(riderFunding.get('startDate')).startOf('day');
        const endDate = (0, _moment.default)(riderFunding.get('endDate')).endOf('day');

        if (requestLegTime.isBetween(startDate, endDate)) {
          fundingAgencyOptions.push(riderFunding.fundingAgency);
        }
      });
      return fundingAgencyOptions;
    }),

    async init() {
      this.set('disableFareField', !this.get('permissionLayer').permInUserHash(_mappedPermIds.default.editFareBooking, null));

      this._super(...arguments);

      const serviceTypeOptions = this.booking.getServiceTypeOptions();
      const fareCategoryOptions = this.booking.getFareCategoryOptions();
      const tripPurposeOptions = this.booking.getTripPurposeOptions();
      this.setProperties({
        anchorTypeOptions: [{
          id: 'pick',
          name: 'Pick'
        }, {
          id: 'drop',
          name: 'Appt'
        }],
        serviceTypeOptions,
        rideshareTypeOptions: [{
          id: 0,
          name: 'Allowed'
        }, {
          id: 2,
          name: 'Not Allowed'
        }],
        fareCategoryOptions,
        tripPurposeOptions
      });
      const configFareCategories = this.get('booking').getSystemConfigFareCategories();
      const certFareCategory = configFareCategories.find(configFareCategory => configFareCategory.get('displayName') === CERT_FARE_CATEGORY);
      const guestFareCategory = configFareCategories.find(configFareCategory => configFareCategory.get('displayName') === GUEST_FARE_CATEGORY);
      const fareCategoryDisplayName = this.get('booking.selectedRiders.firstObject.fareCategory');
      const fareCategory = configFareCategories.find(configFareCategory => configFareCategory.get('displayName') === fareCategoryDisplayName);
      this.set('certFareCategory', certFareCategory);
      this.set('guestFareCategory', guestFareCategory);
      this.set('previousFareCategory', Ember.isPresent(fareCategory) ? fareCategory.get('value')[DISPLAY_NAME] : null);
      this.set('allowFundingAgency', this.get('booking').getAllowFundingAgency());
      this.setAssesmentCenterList();
      this.setMinMaxDate();
      this.isFirstLegValidation();
      this.set('editRideshareBookingGranted', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.editRideshareBooking, null));
      this.updateLocationForAddressSelect = this.updateLocationForAddressSelect.bind(this);
      this.validateScheduleOverlap = this.validateScheduleOverlap.bind(this);
      this.scheduleOverLapped = this.scheduleOverLapped.bind(this);
    },

    setAssesmentCenterList() {
      const places = this.store.peekAll('place'); //filter by certification_location

      const placeCategories = places.filter(place => {
        return place.get('placeCategoryType.id') === 'certification_location';
      }); // mutate lat, lng freeformAddress

      const assesmentCenterList = placeCategories.map(element => {
        const address = (0, _unwrapProxy.unwrapProxy)(element.get('address'));
        address.set('lat', address.get('position.lat'));
        address.set('lng', address.get('position.lng'));
        address.set('freeformAddress', address.get('address.address.freeformAddress'));
        return address;
      });
      this.set('assesmentCenterList', assesmentCenterList);
    },

    selectedTime: Ember.computed('leg.requestTime', function () {
      return this.get('leg.requestTime');
    }),

    didInsertElement() {
      this._super(...arguments);

      Ember.run.next('afterRender', () => {
        const leg = this.get('leg');
        const place = this.get('booking.activeBooking.firstLeg.segment.pick.place');
        const location = this.get('booking.activeBooking.firstLeg.segment.pick.place.location');

        if (Ember.isPresent(leg) && Ember.isPresent(location)) {
          this.validateBlockoutAddress(location, leg, place);
        }

        if (!this.get('booking.isEditingUnscheduledBooking')) {
          this.get('validateScheduleOverlap')(leg.get('requestTime'), leg);
        }
      });
    },

    isFirstLegValidation() {
      if (this.get('legIndex') === 0 && this.get('leg.purpose') !== 'return') {
        this.set('isFirstLeg', true);
        this.set('disablePick', true);
      } else {
        this.set('isFirstLeg', false);
        this.set('disablePick', false);
      }
    },

    validateAddress(addressSearchResult, addressType) {
      const streetAddress = addressSearchResult.get('streetAddress');
      const postalCode = addressSearchResult.get('postalCode');
      const lat = addressSearchResult.get('lat');
      const lng = addressSearchResult.get('lng');

      if (lat === 0 || lng === 0) {
        this.get('booking').showErrorMessage(`Unknown Address. Please enter a valid ${addressType} address.`);
        return false;
      }

      if (Ember.isNone(streetAddress) || Ember.isNone(postalCode)) {
        this.get('booking').showErrorMessage(`Unknown Address. Please enter a valid ${addressType} address.`);
        return false;
      }

      if (Ember.isEmpty(streetAddress) || Ember.isEmpty(postalCode)) {
        this.get('booking').showErrorMessage(`Unknown Address. Please enter a valid ${addressType} address.`);
        return false;
      }

      return true;
    },

    async updateLocationForAddressSelect(addressRecord, close, selectType) {
      this.set('booking.canSave', true);
      const leg = this.get('leg');
      const segment = selectType === 'pick' ? leg.get('segments.firstObject') : leg.get('segments.lastObject');
      const addressType = selectType === 'pick' ? `${selectType}up` : `${selectType}off`;
      let address = addressRecord;
      const segmentStop = segment.get(`${selectType}`);
      const place = segment.get(`${selectType}.place`);

      if (!address) {
        address = segment.get(`${selectType}.place.address`);
        place.set('address', addressRecord);
        place.set('location', null);
        this.booking.setActiveAddress(address);
        return;
      }

      if (!this.validateAddress(address, addressType)) {
        return;
      }

      const lat = address.get('lat');
      const lng = address.get('lng');
      this.get('workspace').set('isGlobalSpinnerVisible', true);
      this.get('clearPickAndDropAddressText')();
      const beNomadAddress = address.get('beNomadAddress');
      const geoNode = await this.booking.getAddressGeoNode(lat, lng, beNomadAddress, addressType);

      if (!geoNode || Ember.isEmpty(geoNode.zone.zoneName) || geoNode && geoNode.zone.zoneName <= '0') {
        this.get('workspace').set('isGlobalSpinnerVisible', false);
        this.booking.showErrorMessage('Location must be within the agency service boundary.');
        return;
      }

      const zoneName = this.store.peekRecord('zone-type', geoNode.zone.zoneName);
      address.set('zName', zoneName.get('displayName'));
      address.set('zoneName', zoneName);

      if (!address.get('zoneName.name')) {
        this.get('workspace').set('isGlobalSpinnerVisible', false); //As per Tracy Comment on 19-May on NYAAR-7806:The message should always say *Location must be within the agency service boundary

        this.booking.showErrorMessage('Location must be within the agency service boundary.');
        return;
      }

      const locationRecord = this.store.createRecord('location', {
        lat,
        lng
      });
      place.set('location', locationRecord);
      place.set('address', address);
      segmentStop.set('notes', address.get('notes') || '');
      if (selectType === 'drop') this.get('syncPickAndDrop')();
      this.booking.setActiveAddress(address);
      if (close) close();
      this.get('workspace').set('isGlobalSpinnerVisible', false);

      if (Ember.isPresent(leg)) {
        this.validateBlockoutAddress(locationRecord, leg, place);
      }
    },

    getCurrentLeg() {
      const legIndex = this.get('legIndex');
      const currentLeg = this.get('booking.activeBooking.legs').objectAt(legIndex);
      return currentLeg;
    },

    async checkAssesmentAddressSelected() {
      const leg = this.getCurrentLeg();
      const pickLocation = leg.get('segments.firstObject.pick.place.location');
      const dropLocation = leg.get('segments.firstObject.drop.place.location');
      const pickAddressLat = pickLocation.get('lat');
      const pickAddressLng = pickLocation.get('lng');
      const dropAddressLat = dropLocation.get('lat');
      const dropAddressLng = dropLocation.get('lng');
      const pickAddressLatLng = `${pickAddressLat}/${pickAddressLng}`;
      const dropAddressLatLng = `${dropAddressLat}/${dropAddressLng}`;
      const placeQueryParams = {
        filter: `eq(placeCategoryTypeName,${CERTIFICATION_LOCATION_PLACE_CATEGORY})`
      };
      if (Ember.isNone(pickAddressLat) || Ember.isNone(pickAddressLng)) return 0;
      const places = await this.get('store').query('place', placeQueryParams);
      const filteredPlaces = places.filter(place => {
        const assesmentCenterAddressLatLng = place.get('location.latlng');
        return pickAddressLatLng === assesmentCenterAddressLatLng || dropAddressLatLng === assesmentCenterAddressLatLng;
      });
      return filteredPlaces.length;
    },

    checkTripDateIsCertApptDate() {
      const leg = this.getCurrentLeg();
      const certApptDate = this.get('booking.selectedRiders.firstObject.eligibility.certAppt');
      const oneDayAfterCertApptDate = (0, _moment.default)(certApptDate).add(1, 'days');
      const oneDayBeforeCertApptDate = (0, _moment.default)(certApptDate).subtract(1, 'days');
      const bookingDate = (0, _moment.default)(leg.get('requestTime'));
      return (0, _moment.default)(bookingDate).isBetween(oneDayBeforeCertApptDate, oneDayAfterCertApptDate, 'days', '[]');
    },

    async validateAssesmentCenterAddressAndCertApptDate() {
      return (await this.checkAssesmentAddressSelected()) && this.checkTripDateIsCertApptDate();
    },

    // AIQ-830 TODO: AS fare were calculated by service, we might not need this anymore.
    async checkFareCategoryForAssesmentLeg() {
      // when closing the booking form the legs are cleared
      if (this.get('booking.activeBooking.legs')) {
        const isValid = await this.validateAssesmentCenterAddressAndCertApptDate();
        const leg = this.getCurrentLeg();
        const companionFare = this.get('companionFare');
        const companionTravelNeed = leg.get('companionTravelNeed');
        const companionCount = companionTravelNeed && companionTravelNeed.get('count') ? companionTravelNeed.get('count') : 0;

        if (isValid) {
          const certFareCategory = this.get('certFareCategory');
          const certFareAmount = certFareCategory.get('value.Amount') || certFareCategory.get('value.amount');
          const fareAmount = Number(certFareAmount);
          const fare = companionCount !== 0 ? (companionCount - 1) * companionFare + fareAmount : fareAmount;
          leg.set('segments.firstObject.fare', fare);
          this.set('legFareCategory', CERT_FARE_CATEGORY);
          this.setRiderFareCategories(this.get('legFareCategory'));
          return;
        }

        const defaultFare = this.get('defaultFare');
        const totalFare = companionCount !== 0 ? companionCount * companionFare + defaultFare : defaultFare;
        const filteredLeg = this.allLegsCheckedFare(leg);
        const isAllLegs = this.get('booking.isAllLegs');
        const filteredTotalFare = filteredLeg.length && isAllLegs ? filteredLeg.get('firstObject.segments.firstObject.fare') : totalFare;
        this.set('legFareCategory', this.get('previousFareCategory'));
        this.setRiderFareCategories(this.get('legFareCategory'));
        leg.set('segments.firstObject.fare', filteredTotalFare);
      }
    },

    /**
     *  NYAAR-19049: On Creating Booking with Leg and Return trip with All Leg checked, Fare must be same for all Legs
     * @param {*Object} leg
     * @returns {*Array}
     */
    allLegsCheckedFare(leg) {
      const currentLegOrdinal = leg.get('legOrdinal');
      const allLegs = this.get('booking.allLegs');
      return allLegs ? allLegs.filter(ele => ele.get('legOrdinal') === currentLegOrdinal) : [];
    },

    //if the fare category is cert we have to set each rider fare category as cert else we have to set the previous fare category for each rider
    setRiderFareCategories(legFareCategory) {
      const selectedRiders = this.get('booking.selectedRiders');
      const riderFareCategories = this.get('riderFareCategories');

      if (legFareCategory === CERT_FARE_CATEGORY) {
        selectedRiders.forEach(rider => {
          rider.set('fareCategory', CERT_FARE_CATEGORY);
        });
        return;
      }

      selectedRiders.forEach((rider, index) => {
        const fareCategory = riderFareCategories[index];
        rider.set('fareCategory', fareCategory);
      });
    },

    getPrimarySchedulesBySorting(sortQuery) {
      const schedules = this.get('store').peekAll('schedule').sortBy(sortQuery);
      const primarySchedules = schedules.filter(availabelSchedule => availabelSchedule.get('type') === SCHEDULE_TYPE);
      return primarySchedules;
    },

    scheduleOverLapped(requestTime) {
      const formattedRequestTime = (0, _moment.default)(requestTime).format();
      const schedules = this.getPrimarySchedulesBySorting('start') || [];
      this.set('booking.availableSchedules', []);
      const sortedSchedules = schedules.filter(availabelSchedule => {
        const startSchedule = (0, _moment.default)(availabelSchedule.get('start'));
        const endSchedule = (0, _moment.default)(availabelSchedule.get('end'));
        return !(startSchedule.isAfter(formattedRequestTime) || endSchedule.isBefore(formattedRequestTime));
      }).sortBy('name');

      if (sortedSchedules.length) {
        this.set('booking.availableSchedules', sortedSchedules);
      }
    },

    validateScheduleOverlap(requestTime, leg) {
      this.scheduleOverLapped(requestTime);
      const availableSchedules = this.get('booking.availableSchedules');
      const referenceStartDate = (0, _moment.default)(requestTime).startOf('day').toDate();
      const referenceEndDate = (0, _moment.default)(requestTime).endOf('day').toDate();
      const schedules = this.getPrimarySchedulesBySorting('id');
      const schedule = (0, _lodash.findLast)(schedules, s => (0, _workspaceAvailability.processTimeConstraints)(referenceStartDate, referenceEndDate, s.get('start'), s.get('end')));
      this.set('booking.selectedSchedule', schedule);
      leg.set('scheduleName', schedule.name);

      if (availableSchedules.length > 1) {
        this.set('booking.legSchedule', leg);
        this.set('booking.selectedSchedule', availableSchedules.lastObject);
        leg.set('scheduleName', schedule.name);
        this.set('booking.isScheduleOverLap', true);
        return;
      }
    },

    actions: {
      async onDateChange(value) {
        const rTime = this.get('leg.requestTime');
        const currentDateAndTime = (0, _moment.default)(rTime);
        const selectedDate = (0, _moment.default)(value[0]);
        this.get('showSameDayNotificationByPermission')(selectedDate);
        selectedDate.hour(currentDateAndTime.hour());
        selectedDate.minutes(currentDateAndTime.minutes());
        selectedDate.seconds(currentDateAndTime.seconds());
        const selectedDateObjectWithTime = selectedDate.toDate();
        this.set('leg.requestTime', selectedDateObjectWithTime);
        const legIndexWithinTotalLegs = this.get('booking.activeBooking.legs').indexOf(this.get('leg'));
        this.get('validateRequestDateTime')(this.get('leg'));
        this.get('validateScheduleOverlap')(selectedDateObjectWithTime, this.leg);

        if (legIndexWithinTotalLegs === 0) {
          this.get('onFirstLegDateChange')();
        } // refresh funding agency selection


        const curFundingAgencyOptions = await this.get('fundingAgencyOptions');
        const curSelectedFunding = (0, _unwrapProxy.unwrapProxy)(this.get('leg.fundingAgency'));
        const validFundAgency = curSelectedFunding && curFundingAgencyOptions.find(faOption => (0, _unwrapProxy.unwrapProxy)(faOption.get('name')) === curSelectedFunding.get('name'));

        if (!validFundAgency) {
          this.set('leg.fundingAgency', null);
        }
      },

      onTimeChange(value) {
        const rTime = this.get('leg.requestTime');
        const currentDateAndTime = (0, _moment.default)(rTime);
        const selectedDate = (0, _moment.default)(value);
        const currentYear = currentDateAndTime.year();
        const currentMonth = currentDateAndTime.month();
        const currentDay = currentDateAndTime.date();
        selectedDate.year(currentYear);
        selectedDate.month(currentMonth);
        selectedDate.date(currentDay);
        const selectedDateObjectWithTime = selectedDate.toDate();
        this.set('leg.requestTime', selectedDateObjectWithTime);
        this.get('validateRequestDateTime')(this.get('leg'));
        this.get('validateScheduleOverlap')(selectedDateObjectWithTime, this.leg);
      },

      onChangeServiceType(selectedType) {
        this.set('leg.serviceWindow', selectedType);
      },

      onPickAddressSelect(address, close) {
        this.updateLocationForAddressSelect(address, close, 'pick');
      },

      onDropAddressSelect(address, close) {
        this.updateLocationForAddressSelect(address, close, 'drop');
      },

      onPickOnMap(place, selectType) {
        const activeDragPlace = this.booking.get('activeDragPlace');
        const tripType = this.get('isReturnTrip');
        const leg = this.get('leg');
        this.set('booking.skipSetView', true);

        if (!(0, _unwrapProxy.unwrapProxy)(place.get('address'))) {
          this.get('booking').setEmptyAddressLocation(place);
        }

        if (!activeDragPlace) {
          this.booking.set('activeDragPlace', place);
        }

        this.booking.set('activeLeg', leg);
        this.booking.set('addressSelectType', selectType);
        this.booking.set('isReturnTrip', tripType);
      },

      toggleCalendar() {
        this.calflatpickrRef.toggle();
      },

      toggleTime() {
        this.timeflatpickrRef.toggle();
      },

      onLegRemoveClick(leg) {
        this.get('onLegRemoveClick')(leg);
      },

      onNumberChange(segmentStop, maskObj) {
        const areaCode = maskObj.unmasked.substring(0, 3).trim();
        const phoneNumber = maskObj.unmasked.substring(3).trim(); // for copy Booking we already create the segment stop phone number record in booking service

        if (segmentStop.get('firstObject') && !segmentStop.get('firstObject.id')) {
          segmentStop.set('firstObject.areaCode', areaCode);
          segmentStop.set('firstObject.phoneNumber', phoneNumber);
        } //for edit booking we already have the segment stop phone number id if the previous booking segment stop have phone number


        if (segmentStop.get('firstObject.id')) {
          const segmentStopPhoneNumberId = segmentStop.get('firstObject.id');
          const segmentStopPhoneNumber = this.get('store').peekRecord('segment-stop-phone-number', segmentStopPhoneNumberId);
          segmentStopPhoneNumber.set('areaCode', areaCode);
          segmentStopPhoneNumber.set('phoneNumber', phoneNumber);
          return;
        } // for new booking or if the segment stop does'nt have a phone number


        if (!segmentStop.get('firstObject')) {
          const phoneNumberObj = {
            type: _riderPhoneNumber.HOME_PHONE_TYPE,
            areaCode: areaCode,
            phoneNumber: phoneNumber,
            extension: '',
            description: 'HOME CONTACT #',
            mobileCarrier: 'yes',
            useForSmsNotifications: false,
            useForVoiceNotifications: true
          };
          const segmentStopPhoneNumber = this.get('store').createRecord('segment-stop-phone-number', phoneNumberObj);
          segmentStop.pushObject(segmentStopPhoneNumber);
          return;
        }
      },

      onFareChange(segment, maskObj) {
        segment.set('fare', parseFloat(maskObj.unmasked).toFixed(2));
      },

      onFareCategoryChange(segment, fareCategory) {
        segment.set('fareCategory', fareCategory);
        this.get('updateFare')();
      }

    }
  });

  _exports.default = _default;
});